import { useSelector } from "react-redux";
import ViewComment from "../../../components/generic/ViewComment";
import { ScreeningSessionNotesResponse } from "../../../models";
import { StateParams } from "../../../store/reducers";

interface Props {
    screeningSessionId: string | undefined;
    patientId: string | undefined;
}

const ViewSessionNotes = ({ patientId, screeningSessionId }: Props) => {
    const { screenerSessionId, screeningSessionNotes, screenedDates } = useSelector((state: StateParams) => state.physicianPatients);
    if (!screeningSessionNotes) {
        return null
    }

    return (
        <div>
            <div className="flex justify-content-between">
                <span className="text-md">Consent Details</span>
            </div>
            <ViewComment
                comment={screeningSessionNotes.note}
                createdAt={screeningSessionNotes.createdAt}
                user={{ firstName: screeningSessionNotes.notesCreatedBy.firstName, lastName: screeningSessionNotes.notesCreatedBy.lastName, roles: ['Care Coordinator'] }}
                bgColor="bg-white"
            />
        </div>
    )
};

export default ViewSessionNotes;