import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import Select from "react-select";
import AdvancedDateRangePickerComponent
    , {TFilterOptions} from "../../../components/generic/advanced-date-range-picker/AdvancedDateRangePickerComponent";
import {DateTime} from "luxon";
import {fetchPhysicianAppointmentsOverAllMetrics} from "../../../store/actions/care-coordinator/patients.action";
import {IPhysicianAppointmentsOverAllMetrics, IPracticeLocation} from "../../../models";
import {BiSolidErrorCircle} from "react-icons/bi";
import RefreshComponent from "../../../components/generic/RefreshComponent";

const FilterOptions: TFilterOptions[] = [
    {value: "currentDay", label: "Current Day"},
    {value: "7", label: "Last 7 Days"},
    {value: "monthToDate", label: "Current Month"},
    {value: "custom", label: "Custom"}
];

const OverAllMetricsMapping: {
    accessorKey: keyof IPhysicianAppointmentsOverAllMetrics,
    title: string,
    formatter?: (value: number) => string
}[] = [
    {
        accessorKey: "totalAppointments",
        title: "Total Appointments"
    },
    {
        accessorKey: "calledAppointments",
        title: "Called Patients"
    },
    {
        accessorKey: "smsFailedAppointments",
        title: "SMS Failed"
    },
    {
        accessorKey: "smsDeliveredAppointments",
        title: "SMS Delivered"
    },
    {
        accessorKey: "patientRefusedToFillAppointments",
        title: "Patient Refused To Fill"
    },
    {
        accessorKey: "screeningDoneAppointments",
        title: "Screening Done"
    },
    {
        accessorKey: "screeningPercentage",
        title: "Avg Screening Percentage",
        formatter: (value: number) => `${value}%`
    }
]
const PhysicianAppointmentsOverAllMetricsComponent = () => {

    const initialStartDate = DateTime.local().minus({days: 6}).toFormat('yyyy-MM-dd');
    const initialEndDate = DateTime.local().toFormat('yyyy-MM-dd');
    const [dateFilter, setDateFilter] = useState<{
        startDate: string,
        endDate: string
    }>({startDate: initialStartDate, endDate: initialEndDate});
    const dispatch = useDispatch();
    const {practiceMaster} = useSelector((state: StateParams) => state.careCoordinatorMasterData);
    const [locations, setLocations] = useState<IPracticeLocation[]>([]);
    const {
        physicianAppointmentsOverAllMetrics,
        physicianAppointmentsOverAllMetricsInProgress,
        physicianAppointmentsOverAllMetricsError,
    } = useSelector((state: StateParams) => state.coordinatorPatients);
    const [selectedPracticeId, setSelectedPracticeId] = useState<string | null>(null);
    const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>([]);

    const getPhysicianAppointmentsOverAllMetrics = useCallback(() => {
        dispatch(fetchPhysicianAppointmentsOverAllMetrics({
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
            practiceIds: selectedPracticeId,
            locationIds: (selectedLocationIds && selectedLocationIds.length > 0) ? selectedLocationIds.join(',') : null
        }));
    }, [selectedLocationIds, selectedPracticeId, dateFilter, dispatch]);

    useEffect(() => {
        getPhysicianAppointmentsOverAllMetrics();
    }, [selectedPracticeId, selectedLocationIds, dateFilter, getPhysicianAppointmentsOverAllMetrics]);

    const handleDateFilterChange = useCallback((startDate: string, endDate: string) => {
        if (startDate && endDate) {
            setDateFilter({startDate, endDate});
        } else {
            setDateFilter({startDate: initialStartDate, endDate: initialEndDate});
        }
    }, [initialStartDate, initialEndDate]);

    const renderFilters = useCallback(() => {
        return <div className="flex gap-2 flex-wrap align-items-flex-start">
            <Select
                placeholder={"Practice"}
                className="min-w-[250px] max-w-[250px]"
                classNamePrefix={"sj-react-select"}
                options={practiceMaster}
                getOptionLabel={option => `${option?.name}`}
                getOptionValue={option => option?.id}
                value={practiceMaster.find(x => x.id === selectedPracticeId) || null}
                isClearable={true}
                onChange={x => {
                    if (x) {
                        if (!x?.id || x?.id === selectedPracticeId) return;
                        setSelectedPracticeId(x?.id);
                        setSelectedLocationIds([]);
                        setLocations(x?.locations || []);
                    } else {
                        setSelectedPracticeId(null);
                        setSelectedLocationIds([]);
                        setLocations([]);
                    }
                }}
                defaultValue={undefined}
                isSearchable={practiceMaster.length > 5}
            />
            <Select
                placeholder={"Location"}
                isDisabled={!selectedPracticeId}
                className="min-w-[250px] max-w-[250px]"
                classNamePrefix={"sj-react-select"}
                getOptionLabel={option => `${option?.name}`}
                getOptionValue={option => option?.id}
                value={locations.filter(x => selectedLocationIds.includes(x.id))}
                options={locations}
                isClearable={true}
                isMulti={true}
                onChange={x => {
                    if (x?.length) {
                        setSelectedLocationIds(x.map((i) => i.id));
                    } else {
                        setSelectedLocationIds([]);
                    }
                }}
            />
            <AdvancedDateRangePickerComponent
                filterOptions={FilterOptions}
                startDate={dateFilter.startDate}
                endDate={dateFilter.endDate}
                onFilterChange={handleDateFilterChange}
                defaultStartDate={initialStartDate}
                defaultEndDate={initialEndDate}
                shouldSetDefaultDatesOnClearAndApply={true}
            />
        </div>
    }, [locations, selectedLocationIds, practiceMaster, selectedPracticeId, dateFilter, handleDateFilterChange]);

    const renderMetric = useCallback((title: string, value?: string | number, loading?: boolean) => {
        return <>
            <div className="flex flex-col gap-2" key={title}>
                <div className="text-sm text-sjGray">{title}</div>
                <div className="text-xl min-h-[30px] relative">
                    {loading && <div className="card-loading"/>}
                    {value}
                </div>
            </div>
            <div className="lg:border-r-1 last:border-0"></div>
        </>
    }, []);

    const renderMetrics = useCallback(() => {
        return <div className="flex justify-between flex-wrap gap-5 lg:gap-0">
            {OverAllMetricsMapping.map((metric) => {
                let metricValue = physicianAppointmentsOverAllMetrics?.[metric.accessorKey];
                if (metric.formatter && metricValue !== undefined) {
                    return renderMetric(metric.title, metric.formatter(metricValue), physicianAppointmentsOverAllMetricsInProgress);
                } else {
                    return renderMetric(metric.title, metricValue, physicianAppointmentsOverAllMetricsInProgress);
                }
            })}
        </div>
    }, [physicianAppointmentsOverAllMetrics, physicianAppointmentsOverAllMetricsInProgress, renderMetric]);

    return (
        <div className="rounded-md bg-white p-5 border-[1px]">
            <div className="flex justify-between align-items-center flex-wrap">
                <div>
                    <h1 className="text-lg"> Overall Metrics </h1>
                </div>
                <div className="flex gap-1 flex-wrap align-items-flex-start">
                    {renderFilters()}
                    <RefreshComponent onRefresh={getPhysicianAppointmentsOverAllMetrics}/>
                </div>
            </div>
            <div className="mt-10">
                {renderMetrics()}
            </div>
            {
                physicianAppointmentsOverAllMetricsError &&
                <div className="flex gap-2 align-items-center text-sjDarkRed mt-2">
                    <BiSolidErrorCircle/> {physicianAppointmentsOverAllMetricsError}
                </div>
            }
        </div>
    )
}

export default PhysicianAppointmentsOverAllMetricsComponent;
