import { useEffect, useState } from "react";
import { Button } from "../../../components/generic/Buttons";
import AddSessionNotes from "./AddSessionNotes";
import { useDispatch, useSelector } from "react-redux";
import { StateParams } from "../../../store/reducers";
import { useParams } from "react-router-dom";
import ViewSessionNotes from "./ViewSessionNotes";
import { resetAddScreeningSessionNotes } from "../../../store/actions/care-coordinator/patients.action";
import { useFlags } from "flagsmith/react";
import { isCareCoordinator } from "../../../components/clinical-notes/accessControl";

const SessionNotes = () => {
    const dispatch = useDispatch();
    const [showAddNote, setShowAddNote] = useState<boolean>(false);
    const { screeningSessionId, screeningSessionNotes, addScreeningSessionNotesSuccess } = useSelector((state: StateParams) => state.coordinatorPatients);
    const params = useParams()
    const isCCNoteEnabled = useFlags(['is_cc_note_enabled']).is_cc_note_enabled.enabled;
    const patientId = params.patientId || params.prospectId
    const role = useSelector((state: StateParams) => state.account.role)

    useEffect(() => {
        if (addScreeningSessionNotesSuccess) {
            setShowAddNote(false);
            dispatch(resetAddScreeningSessionNotes());
        }
    }, [addScreeningSessionNotesSuccess]);

    if (!isCareCoordinator(role)) {
        return null;
    }

    if (!isCCNoteEnabled) {
        return null;
    }

    return (
        <div className="mt-4">
            {!showAddNote && !screeningSessionNotes && <div className="flex justify-end">
                <Button className="text-sjOrange border-sjOrange ml-2" onClick={() => {
                    setShowAddNote(!showAddNote);
                }}>Add Note + </Button>
            </div>}
            {showAddNote && !screeningSessionNotes && screeningSessionId && patientId &&
                <AddSessionNotes screeningSessionId={screeningSessionId} patientId={patientId} setShowAddNote={setShowAddNote}
                    screeningSessionNotes={screeningSessionNotes} />}
            {screeningSessionNotes && <ViewSessionNotes screeningSessionId={screeningSessionId} patientId={patientId}
                screeningSessionNotes={screeningSessionNotes} />}
        </div>
    )
};

export default SessionNotes;