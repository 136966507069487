import { AnyAction } from "redux";
import {
    IInsuranceStatusResponse,
    IPatientMaster,
    IUserScreenerSession,
    IPatientWithSummary,
    IPhysicianAppointmentListItem,
    IPotentialReferralPatient,
    IPotentialReferralPatientBasicDetails,
    IReferredPatientsDetails,
    IScreenerUserForScreeningLink,
    CommentsResponse,
    IPhysicianAppointmentsOverAllMetricsResponse,
    IPhysicianAppointmentsOverAllMetrics,
    IPhysicianAppointmentsDayWiseMetrics,
    ScreeningSessionNotesResponse

} from "../../../models";
import {
    FETCH_POTENTIAL_REFERRAL_PATIENTS_SUCCESS,
    FETCH_PATIENT_LATEST_SCREENING_SUMMARY_SUCCESS,
    FETCH_PATIENT_LATEST_SCREENING_SUMMARY_FAILED,
    FETCH_PATIENT_LATEST_SCREENING_SUMMARY,
    FETCH_POTENTIAL_REFERRAL_PATIENTS,
    FETCH_POTENTIAL_REFERRAL_PATIENTS_IN_PROGRESS,
    FETCH_POTENTIAL_REFERRAL_PATIENTS_FAILED
} from "../../actions/care-coordinator";
import {
    CHECK_INSURANCE_ELIGIBILITY,
    CHECK_INSURANCE_ELIGIBILITY_FAILED,
    CHECK_INSURANCE_ELIGIBILITY_IN_PROGRESS,
    CHECK_INSURANCE_ELIGIBILITY_SUCCESS,
    ADD_PHYSICIAN_APPOINTMENT,
    ADD_PHYSICIAN_APPOINTMENT_FAILED,
    ADD_PHYSICIAN_APPOINTMENT_SUCCESS,
    FETCH_ALL_PATIENTS_FOR_SCREENER_LINK,
    FETCH_ALL_PATIENTS_FOR_SCREENER_LINK_FAILED,
    FETCH_ALL_PATIENTS_FOR_SCREENER_LINK_SUCCESS,
    FETCH_INSURANCE_LIST,
    FETCH_INSURANCE_LIST_FAILED,
    FETCH_INSURANCE_LIST_IN_PROGRESS,
    FETCH_INSURANCE_LIST_SUCCESS,
    FETCH_PATIENT_BASIC_INFORMATION,
    FETCH_PATIENT_BASIC_INFORMATION_FAILED,
    FETCH_PATIENT_BASIC_INFORMATION_SUCCESS,
    FETCH_PATIENT_LATEST_SCREENING_SUMMARY_SUCCESS_BY_SESSION_ID,
    FETCH_PATIENT_MASTER_LIST,
    FETCH_PATIENT_MASTER_LIST_FAILED,
    FETCH_PATIENT_MASTER_LIST_SUCCESS,
    FETCH_PHYSICIAN_APPOINTMENTS,
    FETCH_PHYSICIAN_APPOINTMENTS_FAILED,
    FETCH_PHYSICIAN_APPOINTMENTS_SUCCESS,
    RESET_ADD_PHYSICIAN_APPOINTMENT,
    RESET_SCREENER_LINK_TO_PATIENT,
    RESET_UPDATE_PHYSICIAN_APPOINTMENT,
    SEND_SCREENER_LINK_TO_PATIENT,
    SEND_SCREENER_LINK_TO_PATIENT_FAILED,
    SEND_SCREENER_LINK_TO_PATIENT_SUCCESS,
    TRANSFER_INTAKE,
    TRANSFER_INTAKE_FAILED,
    TRANSFER_INTAKE_INPROGRESS,
    TRANSFER_INTAKE_SUCCESS,
    UPDATE_ELIGIBILITY_INSURANCE_STATUS,
    RESET_ELIGIBILITY_INSURANCE_STATUS_SUCCESS,
    UPDATE_ELIGIBILITY_INSURANCE_STATUS_FAILED,
    UPDATE_ELIGIBILITY_INSURANCE_STATUS_IN_PROGRESS,
    UPDATE_ELIGIBILITY_INSURANCE_STATUS_SUCCESS,
    UPDATE_PATIENT_BASIC_INFO,
    UPDATE_PHYSICIAN_APPOINTMENT,
    UPDATE_PHYSICIAN_APPOINTMENT_FAILED,
    UPDATE_PHYSICIAN_APPOINTMENT_SUCCESS,
    UPDATE_POTENTIAL_REFERRAL_PATIENT,
    RESET_CHECKED_INSURANCE_ELIGIBILITY_SUCCESS,
    FETCH_REFERRED_PATIENTS,
    FETCH_REFERRED_PATIENTS_FAILED,
    FETCH_REFERRED_PATIENTS_SUCCESS,
    FETCH_REFERRED_IN_PROGRESS,
    SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT,
    SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT_SUCCESS,
    SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT_FAILED,
    RESET_SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT,
    FETCH_PATIENT_ALL_SCREENING_SESSIONS,
    FETCH_PATIENT_ALL_SCREENING_SESSIONS_SUCCESS,
    FETCH_PATIENT_ALL_SCREENING_SESSIONS_FAILED,
    UPDATE_INSURANCE_STATUS,
    UPDATE_INSURANCE_STATUS_FAILED,
    UPDATE_INSURANCE_STATUS_SUCCESS,
    RESET_INSURANCE_STATUS,
    UPDATE_INSURANCE_STATUS_IN_PROGRESS,
    RESET_FETCH_ALL_PATIENTS_FOR_SCREENER_LINK,
    DELETE_PHYSICIAN_APPOINTMENT,
    DELETE_PHYSICIAN_APPOINTMENT_SUCCESS,
    DELETE_PHYSICIAN_APPOINTMENT_FAILED,
    RESET_DELETE_PHYSICIAN_APPOINTMENT,
    ADD_COMMENT_TO_PATIENT,
    ADD_COMMENT_TO_PATIENT_SUCCESS,
    ADD_COMMENT_TO_PATIENT_FAILED,
    RESET_COMMENT_TO_PATIENT,
    FETCH_PATIENT_COMMENTS,
    FETCH_PATIENT_COMMENTS_SUCCESS,
    FETCH_PATIENT_COMMENTS_FAILED,
    PATIENT_COMMENTS_SIDEBAR_ACTIVE,
    PATIENT_COMMENTS_SIDEBAR_IN_ACTIVE,
    FETCH_PHYSICIAN_APPOINTMENTS_OVER_All_METRICS,
    FETCH_PHYSICIAN_APPOINTMENTS_OVER_All_METRICS_FAILED,
    FETCH_PHYSICIAN_APPOINTMENTS_OVER_All_METRICS_SUCCESS,
    FETCH_PHYSICIAN_APPOINTMENTS_DAY_WISE_METRICS,
    FETCH_PHYSICIAN_APPOINTMENTS_DAY_WISE_METRICS_FAILED,
    FETCH_PHYSICIAN_APPOINTMENTS_DAY_WISE_METRICS_SUCCESS,
    ADD_SCREENING_SESSION_NOTES,
    ADD_SCREENING_SESSION_NOTES_SUCCESS,
    FETCH_SCREENING_SESSION_NOTES,
    FETCH_SCREENING_SESSION_NOTES_SUCCESS,
    FETCH_SCREENING_SESSION_NOTES_FAILED,
    ADD_SCREENING_SESSION_NOTES_FAILED,
    ADD_SCREENING_SESSION_NOTES_IN_PROGRESS,
    RESET_ADD_SCREENING_SESSION_NOTES,
    SET_SCREENING_SESSION_ID,
    FETCH_PHYSICIAN_DND_APPOINTMENTS,
    FETCH_PHYSICIAN_DND_APPOINTMENTS_FAILED,
    FETCH_PHYSICIAN_DND_APPOINTMENTS_SUCCESS,
    FETCH_PHYSICIAN_DND_APPOINTMENTS_IN_PROGRESS,
    RESET_FETCH_PHYSICIAN_DND_APPOINTMENTS,
} from "../../actions/care-coordinator/patients.action";

export interface PatientsParams {
    potentialReferralPatients: IPotentialReferralPatient[]
    potentialReferralPatientCount: number,
    potentialReferralStatusCount: Record<string, number>
    fetchingPatientLatestScreeningSummaryInProgress: boolean,
    errorFetchingPatientLatestScreeningSummary: boolean,
    patientLatestScreeningSummary?: IPatientWithSummary
    patientLatestScreeningSummaryError?: string,
    fetchPotentialReferralPatientsInProgress?: boolean,
    fetchPatientBasicInformationInProgress: boolean,
    errorFetchingPatientBasicInformation: boolean,
    patientBasicInformation?: IPotentialReferralPatientBasicDetails
    patientBasicInformationError?: string
    fetchPatientMasterListInProgress: boolean,
    errorFetchingPatientMasterList: boolean,
    patientMasterList: IPatientMaster[]
    patientMasterCount: number,
    patientMasterListError?: string,
    intakeTransferInProgress?: boolean
    intakeTransferError?: string,
    transferedPatient?: undefined,
    allPatientsForScreenerLinkList: IScreenerUserForScreeningLink[],
    allPatientsForScreenerLinkInProgress: boolean,
    allPatientsForScreenerLinkError?: string,
    patientToSendScreenerLink?: {
        patientId: string,
        practiceId: string
    },
    sendScreenerLinkToPatientInProgress: boolean,
    sendScreenerLinkToPatientError?: string,
    sendScreeningLinkSuccess: boolean,
    insuranceListInProgress?: boolean,
    insuranceListError?: string,
    insuranceList?: any,
    checkInsuranceEligibilityInProgress?: boolean,
    checkInsuranceEligibilityError?: string,
    checkInsuranceEligibilitySuccess?: boolean,
    updateEligibilityInsuranceStatusInProgress?: boolean,
    updateEligibilityInsuranceStatusError?: string,
    updateEligibilityInsuranceStatusSuccess?: boolean,

    updateInsuranceStatusInProgress?: boolean,
    updateInsuranceStatusError?: string,
    updateInsuranceStatusSuccess?: boolean,
    updatedInsuranceStatus?: IInsuranceStatusResponse,

    prospects?: IReferredPatientsDetails[],
    fetchProspectsInProgress?: boolean,
    referralPatientsError?: string,
    prospectsCount?: number,
    tabCount: Record<string, number>,
    physicianAppointmentList: IPhysicianAppointmentListItem[];
    physicianAppointmentListCount: number;
    physicianAppointmentListError?: string;
    physicianAppointmentListInProgress?: boolean;
    physicianAppointmentListSuccess?: boolean;
    addPhysicianAppointmentInProgress?: boolean;
    addPhysicianAppointmentError?: string;
    addPhysicianAppointmentSuccess?: boolean;
    addedPhysicianAppointment?: IPhysicianAppointmentListItem;
    updatePhysicianAppointmentInProgress?: boolean;
    updatePhysicianAppointmentError?: string;
    updatePhysicianAppointmentSuccess?: boolean;
    updatedPhysicianAppointment?: IPhysicianAppointmentListItem;
    physicianAppointmentIdToSendSMS?: string;
    physicianAppointmentSendSMSInProgress?: boolean;
    physicianAppointmentSendSMSError?: string;
    physicianAppointmentSendSMSSuccess?: boolean;
    patientAllScreeningSessions?: IUserScreenerSession[];
    patientAllScreeningSessionsError?: string;
    patientAllScreeningSessionsInProgress?: boolean;
    patientAllScreeningSessionsSuccess?: boolean;
    appointmentIdToBeDeleted?: string;
    deleteAppointmentInProgress?: boolean;
    deleteAppointmentError?: string;
    deleteAppointmentSuccess?: boolean;
    addCommentInProgress?: boolean;
    addCommentError?: string;
    addedCommentSuccess?: boolean;
    patientComments?: CommentsResponse[];
    patientCommentsCount?: number;
    PatientCommentsInProgress?: boolean;
    PatientCommentsError?: string;
    PatientCommentsSideBarActive: boolean;

    physicianAppointmentsOverAllMetrics?: IPhysicianAppointmentsOverAllMetrics;
    physicianAppointmentsOverAllMetricsError?: string;
    physicianAppointmentsOverAllMetricsInProgress?: boolean;
    physicianAppointmentsOverAllMetricsSuccess?: boolean;

    physicianAppointmentsDayWiseMetrics?: IPhysicianAppointmentsDayWiseMetrics;
    physicianAppointmentsDayWiseMetricsError?: string;
    physicianAppointmentsDayWiseMetricsInProgress?: boolean;
    physicianAppointmentsDayWiseMetricsSuccess?: boolean;

    addScreeningSessionNotesInProgress?: boolean;
    addScreeningSessionNotesError?: string;
    addScreeningSessionNotesSuccess?: boolean;

    fetchScreeningSessionNotesInProgress?: boolean;
    fetchScreeningSessionNotesError?: string;
    fetchScreeningSessionNotesSuccess?: boolean;
    screeningSessionNotes?: ScreeningSessionNotesResponse;

    screeningSessionId?: string;

    fetchPhysicianDNDAppointmentsInProgress?: boolean;
    fetchPhysicianDNDAppointmentsError?: string;
    fetchPhysicianDNDAppointmentsSuccess?: boolean;
    physicianDNDAppointment?: IPhysicianAppointmentListItem;
}

const initialState: PatientsParams = {
    potentialReferralPatients: [],
    potentialReferralPatientCount: 0,
    potentialReferralStatusCount: {},
    fetchingPatientLatestScreeningSummaryInProgress: false,
    errorFetchingPatientLatestScreeningSummary: false,
    patientLatestScreeningSummary: undefined,
    patientLatestScreeningSummaryError: undefined,
    fetchPatientBasicInformationInProgress: false,
    errorFetchingPatientBasicInformation: false,
    patientBasicInformation: undefined,
    patientBasicInformationError: undefined,
    fetchPatientMasterListInProgress: false,
    errorFetchingPatientMasterList: false,
    patientMasterList: [],
    patientMasterCount: 0,
    patientMasterListError: undefined,
    intakeTransferInProgress: false,
    intakeTransferError: undefined,
    transferedPatient: undefined,
    allPatientsForScreenerLinkList: [],
    allPatientsForScreenerLinkInProgress: false,
    allPatientsForScreenerLinkError: undefined,
    patientToSendScreenerLink: undefined,
    sendScreenerLinkToPatientInProgress: false,
    sendScreenerLinkToPatientError: undefined,
    sendScreeningLinkSuccess: false,
    insuranceListInProgress: false,
    insuranceListError: undefined,
    insuranceList: undefined,
    checkInsuranceEligibilityInProgress: false,
    checkInsuranceEligibilityError: undefined,
    checkInsuranceEligibilitySuccess: false,
    updateEligibilityInsuranceStatusInProgress: false,
    updateEligibilityInsuranceStatusError: undefined,
    updateEligibilityInsuranceStatusSuccess: false,
    updateInsuranceStatusInProgress: false,
    updateInsuranceStatusError: undefined,
    updateInsuranceStatusSuccess: false,
    updatedInsuranceStatus: undefined,
    prospects: [],
    prospectsCount: undefined,
    tabCount: {},
    fetchProspectsInProgress: undefined,
    physicianAppointmentList: [],
    physicianAppointmentListCount: 0,
    physicianAppointmentListError: undefined,
    physicianAppointmentListInProgress: false,
    physicianAppointmentListSuccess: false,
    addPhysicianAppointmentInProgress: false,
    addPhysicianAppointmentError: undefined,
    addPhysicianAppointmentSuccess: false,
    addedPhysicianAppointment: undefined,
    updatePhysicianAppointmentInProgress: false,
    updatePhysicianAppointmentError: undefined,
    updatePhysicianAppointmentSuccess: false,
    updatedPhysicianAppointment: undefined,
    physicianAppointmentIdToSendSMS: undefined,
    physicianAppointmentSendSMSInProgress: false,
    physicianAppointmentSendSMSError: undefined,
    physicianAppointmentSendSMSSuccess: false,
    patientAllScreeningSessions: [],
    patientAllScreeningSessionsError: undefined,
    patientAllScreeningSessionsInProgress: false,
    patientAllScreeningSessionsSuccess: false,
    appointmentIdToBeDeleted: undefined,
    deleteAppointmentInProgress: false,
    deleteAppointmentError: undefined,
    deleteAppointmentSuccess: false,
    addCommentInProgress: false,
    addCommentError: undefined,
    addedCommentSuccess: false,
    patientComments: [],
    patientCommentsCount: 0,
    PatientCommentsInProgress: false,
    PatientCommentsError: undefined,
    PatientCommentsSideBarActive: false,

    physicianAppointmentsOverAllMetrics: undefined,
    physicianAppointmentsOverAllMetricsError: undefined,
    physicianAppointmentsOverAllMetricsInProgress: false,
    physicianAppointmentsOverAllMetricsSuccess: false,

    physicianAppointmentsDayWiseMetrics: undefined,
    physicianAppointmentsDayWiseMetricsError: undefined,
    physicianAppointmentsDayWiseMetricsInProgress: false,
    physicianAppointmentsDayWiseMetricsSuccess: false,

    addScreeningSessionNotesInProgress: false,
    addScreeningSessionNotesError: undefined,
    addScreeningSessionNotesSuccess: false,

    fetchScreeningSessionNotesInProgress: false,
    fetchScreeningSessionNotesError: undefined,
    fetchScreeningSessionNotesSuccess: false,
    screeningSessionNotes: undefined,

    screeningSessionId: undefined,

    fetchPhysicianDNDAppointmentsInProgress: false,
    fetchPhysicianDNDAppointmentsError: undefined,
    fetchPhysicianDNDAppointmentsSuccess: false,
    physicianDNDAppointment: undefined,
}

const PatientsReducer = (state: PatientsParams = initialState, action: AnyAction): PatientsParams => {
    switch (action.type) {
        case FETCH_POTENTIAL_REFERRAL_PATIENTS:
            state = {
                ...state,
                potentialReferralPatients: [],
            }
            return state
        case FETCH_POTENTIAL_REFERRAL_PATIENTS_IN_PROGRESS:
            state = {
                ...state,
                fetchPotentialReferralPatientsInProgress: true
            }
            return state
        case FETCH_POTENTIAL_REFERRAL_PATIENTS_FAILED:
            state = {
                ...state,
                fetchPotentialReferralPatientsInProgress: false
            }
            return state
        case FETCH_POTENTIAL_REFERRAL_PATIENTS_SUCCESS:
            state = {
                ...state,
                potentialReferralPatients: action.payload.patients,
                potentialReferralPatientCount: action.payload.count,
                potentialReferralStatusCount: action.payload.statusCount,
                fetchPotentialReferralPatientsInProgress: false
            }
            return state
        case FETCH_PATIENT_LATEST_SCREENING_SUMMARY:
            state = {
                ...state,
                // patientLatestScreeningSummary: undefined,
                patientLatestScreeningSummaryError: undefined,
                fetchingPatientLatestScreeningSummaryInProgress: true,
                errorFetchingPatientLatestScreeningSummary: false
            };
            return state;
        case FETCH_PATIENT_LATEST_SCREENING_SUMMARY_SUCCESS:
            state = {
                ...state,
                fetchingPatientLatestScreeningSummaryInProgress: false,
                errorFetchingPatientLatestScreeningSummary: false,
                patientLatestScreeningSummary: action.payload.screeningSummary,
                patientLatestScreeningSummaryError: undefined
            };
            return state;
        case FETCH_PATIENT_LATEST_SCREENING_SUMMARY_SUCCESS_BY_SESSION_ID:
            state = {
                ...state,
                fetchingPatientLatestScreeningSummaryInProgress: false,
                errorFetchingPatientLatestScreeningSummary: false,
                patientLatestScreeningSummaryError: undefined,
                patientLatestScreeningSummary: state.patientLatestScreeningSummary ? {
                    ...state.patientLatestScreeningSummary,
                    patient: state.patientLatestScreeningSummary.patient ? {
                        ...state.patientLatestScreeningSummary.patient,
                        screeners: action.payload.screeningSummary.patient.screeners,
                        sessionId: action.payload.screeningSummary.patient.sessionId
                    } : action.payload.screeningSummary.patient,
                    summary: action.payload.screeningSummary.summary,
                    sessions: action.payload.screeningSummary.sessions
                } : action.payload.screeningSummary,
            };
            return state;
        case FETCH_PATIENT_LATEST_SCREENING_SUMMARY_FAILED:
            state = {
                ...state,
                fetchingPatientLatestScreeningSummaryInProgress: false,
                errorFetchingPatientLatestScreeningSummary: true,
                patientLatestScreeningSummaryError: action.payload.error
            };
            return state;
        // PATIENT_LATEST_SCREENING_SUMMARY ENDS

        // PATIENT_BASIC_INFORMATION STARTS
        case FETCH_PATIENT_BASIC_INFORMATION:
            state = {
                ...state,
                patientBasicInformation: undefined,
                patientBasicInformationError: undefined,
                fetchPatientBasicInformationInProgress: true,
                errorFetchingPatientBasicInformation: false
            };
            return state;
        case FETCH_PATIENT_BASIC_INFORMATION_SUCCESS:
            state = {
                ...state,
                fetchPatientBasicInformationInProgress: false,
                errorFetchingPatientBasicInformation: false,
                patientBasicInformation: action.payload.patient,
                patientBasicInformationError: undefined
            };
            return state;
        case FETCH_PATIENT_BASIC_INFORMATION_FAILED:
            state = {
                ...state,
                fetchPatientBasicInformationInProgress: false,
                errorFetchingPatientBasicInformation: true,
                patientBasicInformationError: action.payload.error
            };
            return state;
        // PATIENT_BASIC_INFORMATION ENDS


        // PATIENT_MASTER_LIST STARTS
        case FETCH_PATIENT_MASTER_LIST:
            state = {
                ...state,
                patientMasterList: [],
                patientMasterListError: undefined,
                patientMasterCount: 0,
                fetchPatientMasterListInProgress: true,
                errorFetchingPatientMasterList: false
            };
            return state;
        case FETCH_PATIENT_MASTER_LIST_SUCCESS:
            console.log(action);
            state = {
                ...state,
                fetchPatientMasterListInProgress: false,
                errorFetchingPatientMasterList: false,
                patientMasterList: action.payload.patients,
                patientMasterCount: action.payload.count,
                patientMasterListError: undefined
            };
            return state;
        case FETCH_PATIENT_MASTER_LIST_FAILED:
            state = {
                ...state,
                fetchPatientMasterListInProgress: false,
                errorFetchingPatientMasterList: true,
                patientMasterListError: action.payload.error
            };
            return state;
        // PATIENT_MASTER_LIST ENDS

        case UPDATE_POTENTIAL_REFERRAL_PATIENT:
            state = {
                ...state, potentialReferralPatients: state.potentialReferralPatients.map((patient) => {
                    if (patient.id === action.payload.patient.id) {
                        return { ...patient, ...action.payload.patient }
                    }
                    return patient
                })
            }
            return state

        case UPDATE_PATIENT_BASIC_INFO:
            state = {
                ...state, fetchPatientBasicInformationInProgress: false,
                patientBasicInformation: state.patientBasicInformation ? {
                    ...state.patientBasicInformation, contactPhoneNumber: action.payload.contactPhoneNumber
                } : undefined
            }
            return state

        case TRANSFER_INTAKE:
            state = { ...state, transferedPatient: undefined, intakeTransferError: undefined }
            return state
        case TRANSFER_INTAKE_FAILED:
            state = { ...state, intakeTransferInProgress: false, intakeTransferError: action.payload.error }
            return state
        case TRANSFER_INTAKE_INPROGRESS:
            state = { ...state, intakeTransferInProgress: true, intakeTransferError: undefined }
            return state
        case TRANSFER_INTAKE_SUCCESS:
            state = {
                ...state,
                transferedPatient: action.payload.noteId,
                intakeTransferInProgress: false,
                intakeTransferError: undefined
            }
            return state
        case FETCH_ALL_PATIENTS_FOR_SCREENER_LINK:
            state = {
                ...state,
                allPatientsForScreenerLinkList: [],
                allPatientsForScreenerLinkInProgress: true,
                allPatientsForScreenerLinkError: undefined
            };
            return state;
        case FETCH_ALL_PATIENTS_FOR_SCREENER_LINK_SUCCESS:
            state = {
                ...state,
                allPatientsForScreenerLinkList: action.payload.patients,
                allPatientsForScreenerLinkInProgress: false,
                allPatientsForScreenerLinkError: undefined
            };
            return state;
        case FETCH_ALL_PATIENTS_FOR_SCREENER_LINK_FAILED:
            state = {
                ...state,
                allPatientsForScreenerLinkInProgress: false,
                allPatientsForScreenerLinkError: action.payload.error
            };
            return state;
        case RESET_FETCH_ALL_PATIENTS_FOR_SCREENER_LINK:
            state = {
                ...state,
                allPatientsForScreenerLinkList: [],
                allPatientsForScreenerLinkInProgress: false,
                allPatientsForScreenerLinkError: undefined
            }
            return state;
        case SEND_SCREENER_LINK_TO_PATIENT:
            state = {
                ...state,
                patientToSendScreenerLink: action.payload,
                sendScreenerLinkToPatientInProgress: true,
                sendScreeningLinkSuccess: false,
                sendScreenerLinkToPatientError: undefined
            };
            return state;
        case SEND_SCREENER_LINK_TO_PATIENT_FAILED:
            state = {
                ...state,
                sendScreenerLinkToPatientInProgress: false,
                sendScreeningLinkSuccess: false,
                sendScreenerLinkToPatientError: action.payload.error
            };
            return state;
        case SEND_SCREENER_LINK_TO_PATIENT_SUCCESS:
            state = {
                ...state,
                sendScreenerLinkToPatientInProgress: false,
                sendScreeningLinkSuccess: true,
                sendScreenerLinkToPatientError: undefined,
            }
            return state;
        case RESET_SCREENER_LINK_TO_PATIENT:
            state = {
                ...state,
                patientToSendScreenerLink: undefined,
                sendScreenerLinkToPatientInProgress: false,
                sendScreeningLinkSuccess: false,
                sendScreenerLinkToPatientError: undefined
            }
            return state;

        //     PHYSICIAN APPOINTMENTS STARTS
        case FETCH_INSURANCE_LIST:
            state = {
                ...state,
                insuranceList: [],
                insuranceListError: undefined,
                insuranceListInProgress: false,
            }
            return state

        case FETCH_INSURANCE_LIST_IN_PROGRESS:
            state = {
                ...state,
                insuranceListInProgress: true,
                insuranceListError: undefined
            }
            return state

        case FETCH_INSURANCE_LIST_SUCCESS:
            state = {
                ...state,
                insuranceList: action.payload,
                insuranceListInProgress: false,
                insuranceListError: undefined
            }
            return state

        case FETCH_INSURANCE_LIST_FAILED:
            state = {
                ...state,
                insuranceListInProgress: false,
                insuranceListError: action.payload.error
            }
            return state

        case CHECK_INSURANCE_ELIGIBILITY_IN_PROGRESS:
            state = {
                ...state,
                checkInsuranceEligibilityInProgress: true,
                checkInsuranceEligibilityError: undefined,
                checkInsuranceEligibilitySuccess: false
            }
            return state

        case CHECK_INSURANCE_ELIGIBILITY:
            state = {
                ...state,
                checkInsuranceEligibilityInProgress: false,
                checkInsuranceEligibilityError: undefined,
                checkInsuranceEligibilitySuccess: false
            }
            return state

        case CHECK_INSURANCE_ELIGIBILITY_SUCCESS:
            state = {
                ...state,
                checkInsuranceEligibilityInProgress: false,
                checkInsuranceEligibilityError: undefined,
                checkInsuranceEligibilitySuccess: true
            }
            return state

        case CHECK_INSURANCE_ELIGIBILITY_FAILED:
            state = {
                ...state,
                checkInsuranceEligibilityInProgress: false,
                checkInsuranceEligibilityError: action.payload.error,
                checkInsuranceEligibilitySuccess: false
            }
            return state

        case RESET_CHECKED_INSURANCE_ELIGIBILITY_SUCCESS:
            state = {
                ...state,
                checkInsuranceEligibilitySuccess: false,
            }
            return state;

        case UPDATE_ELIGIBILITY_INSURANCE_STATUS_IN_PROGRESS:
            state = {
                ...state,
                updateEligibilityInsuranceStatusInProgress: true,
                updateEligibilityInsuranceStatusError: undefined,
                updateEligibilityInsuranceStatusSuccess: false
            }
            return state

        case UPDATE_ELIGIBILITY_INSURANCE_STATUS:
            state = {
                ...state,
                updateEligibilityInsuranceStatusInProgress: false,
                updateEligibilityInsuranceStatusError: undefined,
                updateEligibilityInsuranceStatusSuccess: false
            }
            return state

        case UPDATE_ELIGIBILITY_INSURANCE_STATUS_SUCCESS:
            state = {
                ...state,
                updateEligibilityInsuranceStatusInProgress: false,
                updateEligibilityInsuranceStatusError: undefined,
                updateEligibilityInsuranceStatusSuccess: true
            }
            return state

        case UPDATE_ELIGIBILITY_INSURANCE_STATUS_FAILED:
            state = {
                ...state,
                updateEligibilityInsuranceStatusInProgress: false,
                updateEligibilityInsuranceStatusError: action.payload.error,
                updateEligibilityInsuranceStatusSuccess: false
            }
            return state

        case RESET_ELIGIBILITY_INSURANCE_STATUS_SUCCESS:
            state = {
                ...state,
                updateEligibilityInsuranceStatusSuccess: false,
            }
            return state;

        case UPDATE_INSURANCE_STATUS:
            state = {
                ...state,
                updateInsuranceStatusInProgress: true,
                updateInsuranceStatusError: undefined,
                updateInsuranceStatusSuccess: false,
                updatedInsuranceStatus: undefined
            }
            return state

        case UPDATE_INSURANCE_STATUS_FAILED:
            state = {
                ...state,
                updateInsuranceStatusInProgress: false,
                updateInsuranceStatusError: action.payload.error,
                updateInsuranceStatusSuccess: false,
                updatedInsuranceStatus: undefined
            }
            return state

        case UPDATE_INSURANCE_STATUS_SUCCESS:
            state = {
                ...state,
                updateInsuranceStatusInProgress: false,
                updateInsuranceStatusError: undefined,
                updateInsuranceStatusSuccess: true,
                updatedInsuranceStatus: action.payload
            }
            return state

        case RESET_INSURANCE_STATUS:
            state = {
                ...state,
                updateInsuranceStatusSuccess: false
            }
            return state;

        case FETCH_REFERRED_PATIENTS:
            state = { ...state, prospects: [], fetchProspectsInProgress: undefined }
            return state
        case FETCH_REFERRED_PATIENTS_FAILED:
            state = { ...state, fetchProspectsInProgress: false }
            return state
        case FETCH_REFERRED_IN_PROGRESS:
            state = { ...state, fetchProspectsInProgress: true }
            return state
        case FETCH_REFERRED_PATIENTS_SUCCESS:
            state = {
                ...state,
                prospects: action.payload.prospects,
                prospectsCount: action.payload.prospectsCount,
                tabCount: action.payload.tabCount,
                fetchProspectsInProgress: false,
            }
            return state

        case FETCH_PHYSICIAN_APPOINTMENTS:
            state = {
                ...state,
                physicianAppointmentList: [],
                physicianAppointmentListCount: 0,
                physicianAppointmentListError: undefined,
                physicianAppointmentListSuccess: false,
                physicianAppointmentListInProgress: true
            };
            return state;
        case FETCH_PHYSICIAN_APPOINTMENTS_SUCCESS:
            state = {
                ...state,
                physicianAppointmentList: action?.payload?.data || [],
                physicianAppointmentListCount: action?.payload?.total || 0,
                physicianAppointmentListError: undefined,
                physicianAppointmentListInProgress: false,
                physicianAppointmentListSuccess: true
            };
            return state;
        case FETCH_PHYSICIAN_APPOINTMENTS_FAILED:
            state = {
                ...state,
                physicianAppointmentList: [],
                physicianAppointmentListCount: 0,
                physicianAppointmentListError: action.payload?.error,
                physicianAppointmentListInProgress: false,
                physicianAppointmentListSuccess: false
            };
            return state;
        case ADD_PHYSICIAN_APPOINTMENT:
            state = {
                ...state,
                addPhysicianAppointmentInProgress: true,
                addPhysicianAppointmentError: undefined,
                addPhysicianAppointmentSuccess: false,
                addedPhysicianAppointment: undefined
            };
            return state;
        case ADD_PHYSICIAN_APPOINTMENT_SUCCESS:
            state = {
                ...state,
                addPhysicianAppointmentInProgress: false,
                addPhysicianAppointmentError: undefined,
                addPhysicianAppointmentSuccess: true,
                addedPhysicianAppointment: action?.payload?.data
            };
            return state;
        case ADD_PHYSICIAN_APPOINTMENT_FAILED:
            state = {
                ...state,
                addPhysicianAppointmentInProgress: false,
                addPhysicianAppointmentError: action.payload?.error,
                addPhysicianAppointmentSuccess: false,
                addedPhysicianAppointment: undefined
            };
            return state;
        case RESET_ADD_PHYSICIAN_APPOINTMENT:
            state = {
                ...state,
                addPhysicianAppointmentInProgress: false,
                addPhysicianAppointmentError: undefined,
                addPhysicianAppointmentSuccess: false,
                addedPhysicianAppointment: undefined
            }
            return state;
        case UPDATE_PHYSICIAN_APPOINTMENT:
            state = {
                ...state,
                updatePhysicianAppointmentInProgress: true,
                updatePhysicianAppointmentError: undefined,
                updatePhysicianAppointmentSuccess: false,
                updatedPhysicianAppointment: undefined
            };
            return state;
        case UPDATE_PHYSICIAN_APPOINTMENT_SUCCESS:
            state = {
                ...state,
                updatePhysicianAppointmentInProgress: false,
                updatePhysicianAppointmentError: undefined,
                updatePhysicianAppointmentSuccess: true,
                updatedPhysicianAppointment: action?.payload?.data
            };
            return state;
        case UPDATE_PHYSICIAN_APPOINTMENT_FAILED:
            state = {
                ...state,
                updatePhysicianAppointmentInProgress: false,
                updatePhysicianAppointmentError: action.payload?.error,
                updatePhysicianAppointmentSuccess: false,
                updatedPhysicianAppointment: undefined
            };
            return state;
        case RESET_UPDATE_PHYSICIAN_APPOINTMENT:
            state = {
                ...state,
                updatePhysicianAppointmentInProgress: false,
                updatePhysicianAppointmentError: undefined,
                updatePhysicianAppointmentSuccess: false,
                updatedPhysicianAppointment: undefined
            }
            return state;
        case SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT:
            state = {
                ...state,
                physicianAppointmentIdToSendSMS: action.payload.appointmentId,
                physicianAppointmentSendSMSInProgress: true,
                physicianAppointmentSendSMSError: undefined,
                physicianAppointmentSendSMSSuccess: false
            };
            return state;
        case SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT_SUCCESS:
            state = {
                ...state,
                physicianAppointmentSendSMSInProgress: false,
                physicianAppointmentSendSMSError: undefined,
                physicianAppointmentSendSMSSuccess: true
            };
            return state;
        case SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT_FAILED:
            state = {
                ...state,
                physicianAppointmentSendSMSInProgress: false,
                physicianAppointmentSendSMSError: action.payload.error,
                physicianAppointmentSendSMSSuccess: false
            };
            return state;
        case RESET_SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT:
            state = {
                ...state,
                physicianAppointmentIdToSendSMS: undefined,
                physicianAppointmentSendSMSInProgress: false,
                physicianAppointmentSendSMSError: undefined,
                physicianAppointmentSendSMSSuccess: false,
                physicianDNDAppointment: undefined
            };
            return state;
        //     PHYSICIAN APPOINTMENTS ENDS
        case FETCH_PATIENT_ALL_SCREENING_SESSIONS:
            state = {
                ...state,
                patientAllScreeningSessions: [],
                patientAllScreeningSessionsInProgress: true,
                patientAllScreeningSessionsSuccess: false,
                patientAllScreeningSessionsError: undefined
            };
            return state;
        case FETCH_PATIENT_ALL_SCREENING_SESSIONS_SUCCESS:
            state = {
                ...state,
                patientAllScreeningSessions: action.payload.sessions,
                patientAllScreeningSessionsInProgress: false,
                patientAllScreeningSessionsSuccess: true,
                patientAllScreeningSessionsError: undefined
            };
            return state;
        case FETCH_PATIENT_ALL_SCREENING_SESSIONS_FAILED:
            state = {
                ...state,
                patientAllScreeningSessions: [],
                patientAllScreeningSessionsInProgress: false,
                patientAllScreeningSessionsSuccess: false,
                patientAllScreeningSessionsError: action.payload.error
            };
            return state;

        // export const DELETE_PHYSICIAN_APPOINTMENT = "DELETE_PHYSICIAN_APPOINTMENT";
        // export const DELETE_PHYSICIAN_APPOINTMENT_SUCCESS = "DELETE_PHYSICIAN_APPOINTMENT_SUCCESS";
        // export const DELETE_PHYSICIAN_APPOINTMENT_FAILED = "DELETE_PHYSICIAN_APPOINTMENT_FAILED";
        // export const RESET_DELETE_PHYSICIAN_APPOINTMENT = "RESET_DELETE_PHYSICIAN_APPOINTMENT";

        case DELETE_PHYSICIAN_APPOINTMENT:
            state = {
                ...state,
                appointmentIdToBeDeleted: action.payload.appointmentId,
                deleteAppointmentInProgress: true,
                deleteAppointmentError: undefined,
                deleteAppointmentSuccess: false
            };
            return state;
        case DELETE_PHYSICIAN_APPOINTMENT_SUCCESS:
            state = {
                ...state,
                deleteAppointmentInProgress: false,
                deleteAppointmentError: undefined,
                deleteAppointmentSuccess: true
            };
            return state;
        case DELETE_PHYSICIAN_APPOINTMENT_FAILED:
            state = {
                ...state,
                deleteAppointmentInProgress: false,
                deleteAppointmentError: action.payload.error,
                deleteAppointmentSuccess: false
            };
            return state;
        case RESET_DELETE_PHYSICIAN_APPOINTMENT:
            state = {
                ...state,
                appointmentIdToBeDeleted: undefined,
                deleteAppointmentInProgress: false,
                deleteAppointmentError: undefined,
                deleteAppointmentSuccess: false
            };
            return state;
        case ADD_COMMENT_TO_PATIENT:
            state = {
                ...state,
                addCommentInProgress: true,
                addCommentError: undefined,
                addedCommentSuccess: false
            };
            return state;
        case ADD_COMMENT_TO_PATIENT_SUCCESS:
            state = {
                ...state,
                addCommentInProgress: false,
                addCommentError: undefined,
                addedCommentSuccess: true
            };
            return state;
        case ADD_COMMENT_TO_PATIENT_FAILED:
            state = {
                ...state,
                addCommentInProgress: false,
                addCommentError: action.payload.error,
                addedCommentSuccess: false
            };
            return state;
        case RESET_COMMENT_TO_PATIENT:
            state = {
                ...state,
                addCommentInProgress: false,
                addCommentError: undefined,
                addedCommentSuccess: false
            };
            return state;

        case FETCH_PATIENT_COMMENTS:
            state = {
                ...state,
                patientComments: [],
                patientCommentsCount: 0,
                PatientCommentsInProgress: true,
                PatientCommentsError: undefined
            };
            return state;
        case FETCH_PATIENT_COMMENTS_SUCCESS:
            state = {
                ...state,
                patientComments: action.payload.comments.data,
                patientCommentsCount: action.payload.comments.recordsCount,
                PatientCommentsInProgress: false,
                PatientCommentsError: undefined
            };
            return state;

        case FETCH_PATIENT_COMMENTS_FAILED:
            state = {
                ...state,
                patientComments: [],
                PatientCommentsInProgress: false,
                PatientCommentsError: action.payload.error
            };
            return state;

        case PATIENT_COMMENTS_SIDEBAR_ACTIVE:
            state = {
                ...state,
                PatientCommentsSideBarActive: true
            }
            return state;

        case PATIENT_COMMENTS_SIDEBAR_IN_ACTIVE:
            state = {
                ...state,
                PatientCommentsSideBarActive: false
            }
            return state;

        // PHYSICIAN_APPOINTMENTS_OVER_All_METRICS STARTS
        case FETCH_PHYSICIAN_APPOINTMENTS_OVER_All_METRICS:
            state = {
                ...state,
                physicianAppointmentsOverAllMetrics: undefined,
                physicianAppointmentsOverAllMetricsError: undefined,
                physicianAppointmentsOverAllMetricsInProgress: true,
                physicianAppointmentsOverAllMetricsSuccess: false
            };
            return state;
        case FETCH_PHYSICIAN_APPOINTMENTS_OVER_All_METRICS_FAILED:
            state = {
                ...state,
                physicianAppointmentsOverAllMetrics: undefined,
                physicianAppointmentsOverAllMetricsError: action?.payload?.error,
                physicianAppointmentsOverAllMetricsInProgress: false,
                physicianAppointmentsOverAllMetricsSuccess: false
            };
            return state;
        case FETCH_PHYSICIAN_APPOINTMENTS_OVER_All_METRICS_SUCCESS:
            state = {
                ...state,
                physicianAppointmentsOverAllMetrics: action?.payload?.response,
                physicianAppointmentsOverAllMetricsError: undefined,
                physicianAppointmentsOverAllMetricsInProgress: false,
                physicianAppointmentsOverAllMetricsSuccess: true
            };
            return state;
        // PHYSICIAN_APPOINTMENTS_OVER_All_METRICS ENDS

        //PHYSICIAN_APPOINTMENTS_DAY_WISE_METRICS STARTS
        case FETCH_PHYSICIAN_APPOINTMENTS_DAY_WISE_METRICS:
            state = {
                ...state,
                physicianAppointmentsDayWiseMetrics: undefined,
                physicianAppointmentsDayWiseMetricsError: undefined,
                physicianAppointmentsDayWiseMetricsInProgress: true,
                physicianAppointmentsDayWiseMetricsSuccess: false
            };
            return state;
        case FETCH_PHYSICIAN_APPOINTMENTS_DAY_WISE_METRICS_FAILED:
            state = {
                ...state,
                physicianAppointmentsDayWiseMetrics: undefined,
                physicianAppointmentsDayWiseMetricsError: action?.payload?.error,
                physicianAppointmentsDayWiseMetricsInProgress: false,
                physicianAppointmentsDayWiseMetricsSuccess: false
            };
            return state;
        case FETCH_PHYSICIAN_APPOINTMENTS_DAY_WISE_METRICS_SUCCESS:
            state = {
                ...state,
                physicianAppointmentsDayWiseMetrics: action?.payload?.response,
                physicianAppointmentsDayWiseMetricsError: undefined,
                physicianAppointmentsDayWiseMetricsInProgress: false,
                physicianAppointmentsDayWiseMetricsSuccess: true
            };
            return state;

        case ADD_SCREENING_SESSION_NOTES:
            state = {
                ...state,
                addScreeningSessionNotesInProgress: true,
                addScreeningSessionNotesError: undefined,
                addScreeningSessionNotesSuccess: false
            };
            return state;
        case ADD_SCREENING_SESSION_NOTES_SUCCESS:
            state = {
                ...state,
                addScreeningSessionNotesInProgress: false,
                addScreeningSessionNotesError: undefined,
                addScreeningSessionNotesSuccess: true,
                screeningSessionNotes: action.payload.notes
            }
            return state;
        case ADD_SCREENING_SESSION_NOTES_FAILED:
            state = {
                ...state,
                addScreeningSessionNotesInProgress: false,
                addScreeningSessionNotesError: action.payload.error,
                addScreeningSessionNotesSuccess: false
            }
            return state;
        case RESET_ADD_SCREENING_SESSION_NOTES:
            state = {
                ...state,
                addScreeningSessionNotesInProgress: false,
                addScreeningSessionNotesError: undefined,
                addScreeningSessionNotesSuccess: false
            }
            return state;
        case ADD_SCREENING_SESSION_NOTES_IN_PROGRESS:
            state = {
                ...state,
                addScreeningSessionNotesInProgress: true,
                addScreeningSessionNotesError: undefined,
                addScreeningSessionNotesSuccess: false
            }
            return state;

        case FETCH_SCREENING_SESSION_NOTES:
            state = {
                ...state,
                fetchScreeningSessionNotesInProgress: true,
                fetchScreeningSessionNotesError: undefined,
                fetchScreeningSessionNotesSuccess: false
            }
            return state;
        case FETCH_SCREENING_SESSION_NOTES_SUCCESS:
            state = {
                ...state,
                fetchScreeningSessionNotesInProgress: false,
                fetchScreeningSessionNotesError: undefined,
                fetchScreeningSessionNotesSuccess: true,
                screeningSessionNotes: action.payload.notes
            }
            return state;
        case FETCH_SCREENING_SESSION_NOTES_FAILED:
            state = {
                ...state,
                fetchScreeningSessionNotesInProgress: false,
                fetchScreeningSessionNotesError: action.payload.error,
                fetchScreeningSessionNotesSuccess: false
            }
            return state;

        case SET_SCREENING_SESSION_ID:
            state = {
                ...state,
                screeningSessionId: action.payload.sessionId
            }
            return state;

        case FETCH_PHYSICIAN_DND_APPOINTMENTS:
            state = {
                ...state,
                fetchPhysicianDNDAppointmentsInProgress: true,
                fetchPhysicianDNDAppointmentsError: undefined,
                fetchPhysicianDNDAppointmentsSuccess: false,
                physicianDNDAppointment: undefined
            }
            return state;
        case FETCH_PHYSICIAN_DND_APPOINTMENTS_SUCCESS:
            state = {
                ...state,
                fetchPhysicianDNDAppointmentsInProgress: false,
                fetchPhysicianDNDAppointmentsError: undefined,
                fetchPhysicianDNDAppointmentsSuccess: true,
                physicianDNDAppointment: action.payload.appointment
            }
            return state;
        case FETCH_PHYSICIAN_DND_APPOINTMENTS_FAILED:
            state = {
                ...state,
                fetchPhysicianDNDAppointmentsInProgress: false,
                fetchPhysicianDNDAppointmentsError: action.payload.error,
                fetchPhysicianDNDAppointmentsSuccess: false
            }
            return state;
        case FETCH_PHYSICIAN_DND_APPOINTMENTS_IN_PROGRESS:
            state = {
                ...state,
                fetchPhysicianDNDAppointmentsInProgress: true,
                fetchPhysicianDNDAppointmentsError: undefined,
                fetchPhysicianDNDAppointmentsSuccess: false
            }
            return state;
        case RESET_FETCH_PHYSICIAN_DND_APPOINTMENTS:
            state = {
                ...state,
                fetchPhysicianDNDAppointmentsInProgress: false,
                fetchPhysicianDNDAppointmentsError: undefined,
                fetchPhysicianDNDAppointmentsSuccess: false,
            }
            return state;

        default:
            return state;
    }
}

export default PatientsReducer
