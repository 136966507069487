import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { saveCatchupNotes, saveTreatmentNotes, setTreamentPlanScreeners } from "../../../store/actions/counsellor/patients.action";
import { StateParams } from "../../../store/reducers";
import _ from "lodash";
import { DateTime } from "luxon";
import { NotesStatus, SessionTypes } from "../../../models";
import { useStatusChange } from "../../../hooks/useStatusChange";
import { toast } from "react-toastify";
import { canAddPsychiatristNotes, isCounsellorNotesLocked, isNotesLocked, isPsychiatrist } from "../accessControl";
import Addendum from "../Addendum";
import { Textarea } from "@material-tailwind/react";
import { updateSessionStatus } from "../../../store/actions/counsellor/appointments.action";
import TextAreaComponent from "../../generic/TextAreaComponent";

interface Props {
    patientId: string
    canEdit: boolean
    notesId: string | null
    isAddendumAdded: boolean
    setIsAddendumAdded: (value: boolean) => void
    hasSaved: boolean
    setHasSaved: (value: boolean) => void
    hasSubmitted: boolean
    setHasSubmitted: (value: boolean) => void
}

const keyMappings: { [K in keyof Partial<Inputs>]: string } = {
    newAddendum: 'newAddendum',
}
const determineKey = (name: string): keyof typeof keyMappings => {
    return keyMappings[name as keyof typeof keyMappings] as keyof typeof keyMappings || name as keyof typeof keyMappings;
}

export type Inputs = {
    clinicalSessionType: string
    documentationType: string
    purposeOfSession: string
    participants: string
    notes: string
    addendums: { addendum: string, by: string, on: string | null }[]
    newAddendum: { addendum: string, by: string, on: string | null }[]
}

const CatchupNotes: React.FC<Props> = ({ patientId, canEdit, notesId, isAddendumAdded, setIsAddendumAdded, hasSaved, hasSubmitted }) => {

    const dispatch = useDispatch()
    const { catchupNotes, notesStatus, sessionNotesId } = useSelector((state: StateParams) => state.patients)
    const { firstName, lastName } = useSelector((state: StateParams) => state.account)
    const { sessionId } = useSelector((state: StateParams) => state.appointments)
    const role = useSelector((state: StateParams) => state.account.role);
    const { register, control, watch, getValues, reset } = useForm<Inputs>()
    const dispatchNotesStatusChange = useStatusChange(SessionTypes.Catchup)

    const { fields: newAddendums, append, remove } = useFieldArray({
        control,
        name: "newAddendum"
    })

    const saveNotes = useCallback(
        _.debounce((key, answer) => dispatch(saveCatchupNotes(patientId, { catchupNotes: { [key]: answer } }, notesId)), 500), []
    )

    useEffect(() => {
        if (isAddendumAdded) {
            setIsAddendumAdded(false)
            const createdBy = `${firstName} ${lastName}`
            if (newAddendums.length === 0) append({ addendum: '', by: createdBy, on: DateTime.now().toISO() })
        }
    }, [isAddendumAdded])

    useEffect(() => {
        if(hasSaved) {
            toast("Notes has been saved successfully.")
        }
    }, [hasSaved])

    useEffect(() => {
        if (hasSubmitted) {
            batch(() => {
                handleStatusChange(NotesStatus.LOCKED)
                saveNotes('clinicalSessionType', getValues('clinicalSessionType'))
                if(sessionId) dispatch(updateSessionStatus({ sessionId, status: 'finished' }))
            })
        }
    }, [hasSubmitted])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name && type === 'change') {
                const extendedName = name.split('.').length > 0 ? name.split('.')[0] : name
                const key = determineKey(extendedName)
                const answer = value[key]
                saveNotes(key, answer)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    useEffect(() => {
        if (catchupNotes?.catchupNotes) {
            reset(catchupNotes?.catchupNotes)
        }
    }, [catchupNotes])

    function handleStatusChange(status: NotesStatus) {
        const id = patientId
        const documentId = notesId || sessionNotesId
        if (id && documentId) {
            batch(() => {
                dispatchNotesStatusChange(status, documentId, id)
            })
        }
    }

    return (
        <>
            <section style={{ backgroundColor: '#FFFFFF' }}
                className={"w-full intake-section mt-4 p-10  min-h-[25vh] rounded-lg shadow-lg"}>
                <fieldset disabled={!canEdit}>
                    <div className="grid gap-4 grid-cols-2">
                        <div className="row-span-3">
                            <label className="block text-lg mt-6 uppercase">Purpose of the call:</label>
                            <input {...register("purposeOfSession")} className="mt-3 outline-none rounded-md w-full" type={'text'} data-testid="purposeOfSession"/>
                        </div>
                        <div id="documentationType" className="row-span-3">
                            <label className="block text-lg mt-6 uppercase">Enter Participants:</label>
                            <input {...register("participants")} className="mt-3 outline-none rounded-md w-full" type={'text'} data-testid="participants"/>
                        </div>
                    </div>
                    <div>
                        <label className="block text-lg mt-6 uppercase ">Notes:</label>
                        <Controller
                        name="notes"
                        control={control}
                        defaultValue=""
                        render={({field}) => (
                            <TextAreaComponent
                            value={field.value}
                            onChange={field.onChange}
                            placeholder=""
                            />
                        )}
                        />
                    </div>
                    <div className="grid grid-rows-3 grid-flow-col gap-6">
                        <div className="row-span-3">
                            <label className="block text-lg mt-6 uppercase">Clinical session time:</label>
                            <select {...register("clinicalSessionType")} defaultValue="15" className="mt-3 outline-none rounded-md w-full" >
                                <option value="15">15 minutes</option>
                            </select>
                        </div>
                        <div id="documentationType" className="row-span-3">
                            <label className="block text-lg mt-6 uppercase">documentation time:</label>
                            <select {...register("documentationType")} defaultValue="10" className="mt-3 outline-none rounded-md w-full" >
                                <option value="10">10 minutes</option>
                            </select>
                        </div>
                    </div>
                </fieldset>
            </section>
            {isCounsellorNotesLocked(notesStatus, role)
                && <section style={{ backgroundColor: '#FFFFFF' }}
                    className={`w-full intake-section mt-6 p-10  min-h-[25vh] rounded-lg shadow-lg`} >
                    <Addendum {...{ register, control, addendums: newAddendums, remove, getValues }} saveNotes={saveNotes} />
                </section>}
            {canAddPsychiatristNotes(notesStatus, role) && isPsychiatrist(role)
                && <section style={{ backgroundColor: '#FFFFFF' }}
                    className={`w-full intake-section mt-6 p-10  min-h-[25vh] rounded-lg shadow-lg`} >
                    <Addendum {...{ register, control, addendums: newAddendums, remove, getValues }} saveNotes={saveNotes} />
                </section>}
        </>
    )
}

export default CatchupNotes