import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {useEffect} from "react";
import {
    clearScreenedDates,
    fetchPatientWithSummary,
    physicianFetchScreeningSessionNotes,
    setScreenerSessionId
} from "../../../store/actions/physician/patients.action";
import {StateParams} from "../../../store/reducers";
import Prospect from "../../../components/patients/physician/Prospect";
import {formatISODate} from "../../../shared/DateUtils";
import {useFlags} from "flagsmith/react";
import {VisitTypeVerbiage} from "../../../models";
import {DropdownWithGroupHeading} from "../../../components/generic/DropdownWithGroupHeading";

const ProspectScreen = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const { patient, sessions, summary, screenedDates, fetchPatientInProgress } = useSelector((state: StateParams) => state.physicianPatients);
    const canView = useFlags(['can_view_historical_screeners'])?.can_view_historical_screeners?.enabled
    const canViewVisitType = useFlags(['cognitive_screening'])?.cognitive_screening?.enabled

    const _screenedDates = canView && screenedDates?.map(x => ({
        value: x.sessionId,
        label: formatISODate(x.screenedOn),
        description: VisitTypeVerbiage[x.visitType]
    })) || []

    useEffect(() => {
        if (params.patientId) {
            dispatch(clearScreenedDates())
            dispatch(fetchPatientWithSummary(params.patientId))
            dispatch(setScreenerSessionId(''))
        }
    }, [params.patientId]);

    const fetchPatientSummary = (event: any) => {
        const sessionId = event.value
        if (params.patientId && sessionId) {
            dispatch(setScreenerSessionId(sessionId))
            dispatch(fetchPatientWithSummary(params.patientId, sessionId))
            dispatch(physicianFetchScreeningSessionNotes(params.patientId, sessionId))
        }
    }

    return (
        <div>
            <div className="mt-4">
                {_screenedDates && _screenedDates.length > 1 ? <div className={`max-w-xs w-1/5 mt-2`}>
                    <DropdownWithGroupHeading options={_screenedDates} key={'dateOfVisit'} title={"Date of Visit"} onChange={fetchPatientSummary} />
                </div> : <></>}
                {fetchPatientInProgress ? null : (patient && summary ? <Prospect patient={patient} summary={summary} sessions={sessions} />
                    :
                    <div className="mt-2">
                        <div className="text-sjOrange p-2">Summary</div>
                        <hr />
                        <div className="mt-6 p-4 text-center">The patient has started a screener by answering a few questions but has not yet completed at least one screener</div>
                    </div>
                )}
            </div>
        </div>
   )
}

export default ProspectScreen
