import { useEffect, useState } from "react";
import { Button } from "../../../components/generic/Buttons";
import ViewComment from "../../../components/generic/ViewComment";
import { ScreeningSessionNotesResponse } from "../../../models";
import { roleTextFormatter } from "../../../shared/Utils";
import AddSessionNotes from "./AddSessionNotes";
import { useDispatch, useSelector } from "react-redux";
import { StateParams } from "../../../store/reducers";
import { resetAddScreeningSessionNotes } from "../../../store/actions/care-coordinator/patients.action";

interface Props {
    screeningSessionId: string | undefined;
    patientId: string | undefined;
    screeningSessionNotes: ScreeningSessionNotesResponse
}

const ViewSessionNotes = ({ screeningSessionNotes, patientId, screeningSessionId }: Props) => {
    const [showAddNote, setShowAddNote] = useState<boolean>(false);
    const { addScreeningSessionNotesSuccess } = useSelector((state: StateParams) => state.coordinatorPatients);
    const dispatch = useDispatch();

    useEffect(() => {
        if (addScreeningSessionNotesSuccess) {
            setShowAddNote(false);
            dispatch(resetAddScreeningSessionNotes());
        }
    }, [addScreeningSessionNotesSuccess]);

    return (
        <div>
            {showAddNote && screeningSessionNotes && screeningSessionId && patientId &&
                <AddSessionNotes screeningSessionId={screeningSessionId} patientId={patientId} setShowAddNote={setShowAddNote}
                    screeningSessionNotes={screeningSessionNotes} />}
            {!showAddNote && <div>
                <div className="flex justify-content-between">
                    <span className="text-md">Consent Details</span>
                </div>
                <ViewComment
                    comment={screeningSessionNotes.note}
                    createdAt={screeningSessionNotes.createdAt}
                    user={{ firstName: screeningSessionNotes.notesCreatedBy.firstName, lastName: screeningSessionNotes.notesCreatedBy.lastName, roles: ['Care Coordinator'] }}
                />
            </div>}
            {!showAddNote && <div className="flex justify-end">
                <Button className="text-sjOrange border-sjOrange ml-2" onClick={() => {
                    setShowAddNote(true);
                }}>Update Note + </Button>
            </div>}
        </div>
    )
};

export default ViewSessionNotes;