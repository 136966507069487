import React, {PropsWithChildren} from "react";
import {useNavigate} from "react-router";
import {SideBarButtonProps, withActiveComponentTreatment, withIconStyling} from "./WithActiveComponentTreatment";

const CalendarAppointmentsButton: React.FC<PropsWithChildren<SideBarButtonProps>> = ({style, navigateTo, iconRenderer}) => {
    const navigate = useNavigate()

    const navigateToCalendarAppointments = () => {
        navigate(navigateTo)
    }

    function render() {
        return <div className="flex">
            <button className={style} onClick={navigateToCalendarAppointments}>
                {iconRenderer("Calendar Appointments")}
            </button>
        </div>
    }


    return render()
}

export default withActiveComponentTreatment(withIconStyling(CalendarAppointmentsButton))
