import React from "react";
import { DateTime } from "luxon";
import ProfileIcon from "../../assets/images/common/profile.svg";
import _ from "lodash";
import { getSystemTimeZone } from "../../shared/DateUtils";

interface User {
    firstName: string;
    lastName: string;
    roles?: string[];
}

interface CommentProps {
    user: User;
    comment: string;
    createdAt: string;
    formatRole?: (roles: string[]) => string;
    getTimeZone?: () => string;
    bgColor?: string;
}

const ViewComment: React.FC<CommentProps> = ({
    user,
    comment,
    createdAt,
    formatRole = (roles) => roles.join(", "),
    bgColor
}) => {
    return (
        <div className={`border border-gray-200 rounded-md p-4 mb-6 ${bgColor}`}>
            <div className="flex justify-between mb-4">
                <div className="flex items-start gap-4">
                    <img src={ProfileIcon} alt="Profile Icon" className="w-10 h-10" />
                    <div>
                        <div className="font-medium text-sm text-black">
                            {_.capitalize(user.firstName)} {_.capitalize(user.lastName)}
                        </div>
                        {user.roles && user.roles.length > 0 && (
                            <div className="text-sjGray text-xs">{formatRole(user.roles)}</div>
                        )}
                    </div>
                </div>
                <div className="text-sjGray italic text-xs">
                    {createdAt &&
                        DateTime.fromISO(createdAt)
                            .setZone("local")
                            .toFormat("MM/dd/yyyy, h:mm a") +
                        " " +
                        getSystemTimeZone()}
                </div>
            </div>
            <div className="break-all overflow-hidden overflow-ellipsis whitespace-pre-wrap">
                {comment}
            </div>
        </div>
    );
};

export default ViewComment;
