import "./DateRangePickerComponent.scss";
import React, {useCallback, useEffect, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import {DateTime} from "luxon";
import {HiCalendarDays} from "react-icons/hi2";
import {XCircleIcon} from "@heroicons/react/20/solid";
import ClickAwayListenerComponent from "../click-away-listener/ClickAwayListenerComponent";
import {toast} from "react-toastify";

interface IDateRangePickerComponentProps {
    defaultStartDate?: string;
    defaultEndDate?: string;
    isClearable?: boolean;
    startDate?: string;
    endDate?: string;
    maxDate?: Date;
    onFilterChange: (startDate: string, endDate: string) => void;
    shouldSelectDateRange?: boolean;
    shouldSetDefaultDatesOnClearAndApply?: boolean;
    placeholder?: string;
}

const DateRangePickerComponent = (props: IDateRangePickerComponentProps) => {

    const {
        defaultStartDate,
        defaultEndDate,
        shouldSelectDateRange,
        shouldSetDefaultDatesOnClearAndApply,
        startDate,
        endDate,
        maxDate,
        isClearable,
        onFilterChange,
        placeholder = "Select Date Range",
    } = props;
    
    const [showCustomDateFilter, setShowCustomDateFilter] = useState<boolean>(false);
    const [filterStartDate, setFilterStartDate] = useState<Date | undefined>(undefined);
    const [filterEndDate, setFilterEndDate] = useState<Date | undefined>(undefined);
    const [isDatesSelected, setIsDatesSelected] = useState<boolean>(false);

    useEffect(() => {
        if (props.startDate) {
            setFilterStartDate(new Date(DateTime.fromISO(props.startDate).toJSDate()));
        }
        if (props.endDate) {
            setFilterEndDate(new Date(DateTime.fromISO(props.endDate).toJSDate()));
        }
    }, [props.startDate, props.endDate]);

    const handleConfirm = useCallback(() => {
        if (filterStartDate && filterEndDate) {
            onFilterChange?.(
                DateTime.fromJSDate(filterStartDate).startOf('day').toFormat('yyyy-MM-dd'),
                DateTime.fromJSDate(filterEndDate).startOf('day').toFormat('yyyy-MM-dd')
            );
            setShowCustomDateFilter(false);
            return;
        }

        if (shouldSelectDateRange && !shouldSetDefaultDatesOnClearAndApply) {
            toast.dismiss();
            toast("Please select a date range");
            return;
        }

        if (shouldSetDefaultDatesOnClearAndApply && defaultStartDate && defaultEndDate) {
            setFilterStartDate(DateTime.fromISO(defaultStartDate).toJSDate());
            setFilterEndDate(DateTime.fromISO(defaultEndDate).toJSDate());
            onFilterChange?.(defaultStartDate, defaultEndDate);
        } else {
            onFilterChange?.('', '');
        }

        setShowCustomDateFilter(false);
    }, [shouldSelectDateRange, shouldSetDefaultDatesOnClearAndApply, onFilterChange, filterStartDate, filterEndDate, defaultStartDate, defaultEndDate]);

    const handleCancel = useCallback(() => {
        if (startDate) {
            setFilterStartDate(new Date(DateTime.fromISO(startDate).toJSDate()));
        } else {
            setFilterStartDate(undefined);
        }
        if (endDate) {
            setFilterEndDate(new Date(DateTime.fromISO(endDate).toJSDate()));
        } else {
            setFilterEndDate(undefined);
        }

        setShowCustomDateFilter(false);
    }, [startDate, endDate]);

    const handleChange = useCallback((dates: [Date, Date]) => {
        const [start, end] = dates;
        setFilterStartDate(start);
        setFilterEndDate(end);
    }, []);

    const handleClearAndApply = useCallback(() => {
        setFilterStartDate(undefined);
        setFilterEndDate(undefined);
        onFilterChange && onFilterChange('', '');
    }, [onFilterChange]);

    const handleClear = useCallback(() => {
        setFilterStartDate(undefined);
        setFilterEndDate(undefined);
    }, []);

    const getDateString = useCallback((date: Date | undefined) => {
        return date ? DateTime.fromJSDate(date).toFormat('MM-dd-yyyy') : undefined;
    }, []);

    useEffect(() => {
        setIsDatesSelected(!!filterStartDate && !!filterEndDate);
    }, [filterStartDate, filterEndDate]);

    const handleDateInputClick = useCallback(() => {
        setShowCustomDateFilter(true);
    }, []);

    return (
        <div className="relative">
            <input
                value={isDatesSelected ? `${getDateString(filterStartDate)} to ${getDateString(filterEndDate)}` : ''}
                placeholder={placeholder}
                className={"p-2 w-full min-w-[245px] border-[1.3px] rounded-md h-10 border-gray-300 focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"}
                readOnly
                onClick={handleDateInputClick}
            />
            {
                (!isDatesSelected || !isClearable) && <span className="absolute top-2.5 right-0 flex items-center pr-2">
                                <HiCalendarDays className="w-5 h-5 text-sjLightGrey"/>
                    </span>
            }
            {
                (isDatesSelected && isClearable) && <span onClick={handleClearAndApply}
                                                          className="cursor-pointer absolute top-2.5 right-0 flex items-center pr-2">
                                <XCircleIcon height={20} className="w-5 h-5 text-sjLightGrey"/>
                    </span>
            }
            {showCustomDateFilter &&
                <div className="absolute mt-2 ml-0 text-center">
                    <ClickAwayListenerComponent onClickOutside={() => {
                        handleCancel();
                    }}>
                        <DatePicker
                            onChange={handleChange}
                            startDate={filterStartDate}
                            endDate={filterEndDate}
                            selectsRange
                            maxDate={maxDate}
                            inline
                        >
                            <div className="custom-date-filter-actions mb-2">
                                <button
                                    onClick={handleCancel}
                                    className="rounded-l-md text-sjDarkGray py-2 px-2"
                                >Cancel
                                </button>
                                <button
                                    onClick={handleClear}
                                    className="rounded-l-md border-sjOrange text-sjOrange py-2 px-4"
                                >Clear
                                </button>
                                &nbsp;
                                <button
                                    onClick={handleConfirm}
                                    className="bg-sjOrange text-white py-2 px-4 rounded"
                                >Apply
                                </button>
                            </div>
                        </DatePicker>
                    </ClickAwayListenerComponent>
                </div>
            }
        </div>
    );
};

export default DateRangePickerComponent;
