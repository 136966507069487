import React, { useCallback, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import DatePicker from 'react-datepicker'
import { Checkbox, Radio } from "@material-tailwind/react";
import { Button } from "../../../components/generic/Buttons";
import ConfirmationModal from "../../../components/clinical-notes/ConfirmationModal";
import { checkInsuranceEligibility } from "../../../store/actions/care-coordinator/patients.action";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { StateParams } from "../../../store/reducers";
import TextAreaComponent from "../../../components/generic/TextAreaComponent";

const cptCodeOptions = [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
];

const cptCodes = [
    { label: "CPT: 99492", value: "99492" },
    { label: "CPT: 99493", value: "99493" },
    { label: "CPT: 99494", value: "99494" },
];

const eligibilityOptions = [
    { label: "Eligible", value: "true" },
    { label: "Not Eligible", value: "false" },
];

const insuranceCallStatusOptions = [
    { label: "Pending Verification", value: "pending" },
    { label: "Spoke to Payor", value: "spoke_to_payor" },
    { label: "Unable to reach payor", value: "unable_to_reach" },
    { label: "IVR only", value: "IVR" },
    { label: "No need to call payor", value: "not_required" },
];


interface EligibilityCheckObject {
    terminationDate?: Date | string | null;
    deductableAmount?: number;
    eligibilityCheckDate: Date | null;
    remainingAmount?: number;
    copay?: number;
    priorAuth: { [key: string]: string | null };
    isPatientEligible: string | null;
    insuranceCallStatus: string;
    verificationNotes?: string;
    referenceNumber?: string;
    additionalInfo?: string;
    insuranceId?: string;
    isLifeTimeInsurance?: boolean;
}

interface InsuranceEligibilityCheckProps {
    onEligibilityCheck: (isPatientEligible: boolean) => void;
    eligibilityCheckObject: EligibilityCheckObject;
}

const InsuranceEligibilityCheck: React.FC<InsuranceEligibilityCheckProps> = ({ eligibilityCheckObject, onEligibilityCheck }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();
    const {
        checkInsuranceEligibilityInProgress
    } = useSelector((state: StateParams) => state.coordinatorPatients);

    const {
        register,
        control,
        handleSubmit,
        getValues,
        formState: { errors },
        watch,
        setValue
    } = useForm<EligibilityCheckObject>({
        defaultValues: {
            eligibilityCheckDate: new Date(),
            terminationDate: eligibilityCheckObject.terminationDate ? DateTime.fromISO(eligibilityCheckObject.terminationDate as string).toJSDate() : null,
            deductableAmount: eligibilityCheckObject.deductableAmount,
            remainingAmount: eligibilityCheckObject.remainingAmount,
            copay: eligibilityCheckObject.copay,
            priorAuth: {
                '99492': eligibilityCheckObject.priorAuth !== null ? eligibilityCheckObject.priorAuth?.['99492'] ? 'true' : 'false' : null,
                '99493': eligibilityCheckObject.priorAuth !== null ? eligibilityCheckObject.priorAuth?.['99493'] ? 'true' : 'false' : null,
                '99494': eligibilityCheckObject.priorAuth !== null ? eligibilityCheckObject.priorAuth?.['99494'] ? 'true' : 'false' : null,
            },
            isPatientEligible: eligibilityCheckObject.isPatientEligible !== null ? eligibilityCheckObject.isPatientEligible ? 'true' : 'false' : null,
            insuranceCallStatus: eligibilityCheckObject.insuranceCallStatus || '',
            verificationNotes: eligibilityCheckObject.verificationNotes || '',
            referenceNumber: eligibilityCheckObject.referenceNumber || '',
            additionalInfo: eligibilityCheckObject.additionalInfo || '',
            isLifeTimeInsurance: eligibilityCheckObject.isLifeTimeInsurance || false,
        }
    });

    const handleInsuranceStatusChange = (value: string) => {
        setValue("insuranceCallStatus", value);
        setValue("additionalInfo", "");
        setValue("referenceNumber", "");
        if (value === "spoke_to_payor") {
            setIsModalOpen(true);
        }
    };

    const onSubmit = handleSubmit((data) => {
        try {
            const eligibilityCheckDate = data.eligibilityCheckDate
                ? DateTime.fromJSDate(data.eligibilityCheckDate).toFormat('yyyy-MM-dd')
                : null;

            const terminationDate = data.isLifeTimeInsurance
                ? null
                : data.terminationDate
                    ? DateTime.fromJSDate(data.terminationDate as Date).setZone('utc').toFormat('yyyy-MM-dd')
                    : null;

            const eligibility = {
                ...data,
                deductableAmount: Number(data.deductableAmount) || 0,
                remainingAmount: Number(data.remainingAmount) || 0,
                copay: Number(data.copay) || 0,
                eligibilityCheckDate,
                terminationDate,
                isPatientEligible: data.isPatientEligible === "true",
                priorAuth: {
                    '99492': data.priorAuth?.['99492'] === "true",
                    '99493': data.priorAuth?.['99493'] === "true",
                    '99494': data.priorAuth?.['99494'] === "true",
                },
                isLifeTimeInsurance: data.isLifeTimeInsurance || false,
            };
            if (eligibilityCheckObject.insuranceId) {
                dispatch(checkInsuranceEligibility(eligibilityCheckObject.insuranceId, eligibility));
                onEligibilityCheck(eligibility.isPatientEligible ? true : false);
            }
        } catch (error) {
            console.error("Error processing dates:", error);
        }
    });

    const amountValidation = (value: string): string => {
        if (/^(0(\.\d{0,2})?|[1-9]\d*(\.\d{0,2})?)$/.test(value)) {
            return value;
        }
        return value.slice(0, -1);
    };

    const calculateEquivalentUTCDate = (date: Date) => {
        if (!date) return null;
        const offset = date.getTimezoneOffset() * 60000;
        if (DateTime.fromJSDate(new Date(date)).get('hour') || DateTime.fromJSDate(new Date(date)).get('minute')) { // Check if the date has time to determine if change has occurred due to manual selection
            return DateTime.fromJSDate(new Date(date.getTime() + offset)).setZone('utc').toJSDate();
        } else {
            return DateTime.fromJSDate(new Date(date.getTime())).setZone('utc').toJSDate();
        }
    }

    return (
        <>
            {
                checkInsuranceEligibilityInProgress && (
                    <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-90 z-50 flex items-center justify-center">
                        <div className="flex flex-col items-center space-y-4">
                            <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-24 w-24"></div>
                            <p className="text-[#5D7285] text-lg font-nunito font-normal">Checking Insurance Eligibility...</p>
                        </div>
                    </div>
                )
            }
            <form onSubmit={onSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">Eligibility Check Date</label>
                        <DatePicker
                            disabled={true}
                            className="border border-gray-300 w-full rounded h-10 bg-gray-300 bg-opacity-30"
                            selected={getValues("eligibilityCheckDate")}
                            onChange={(date: Date) => setValue("eligibilityCheckDate", date)}
                        />
                    </div>

                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">Plan Termination Date</label>
                        <Controller
                            {...register("terminationDate")}
                            control={control}
                            name='terminationDate'
                            render={({ field }) => (
                                <DatePicker
                                    className={`border border-gray-300 rounded w-full h-10 ${watch('isLifeTimeInsurance') ? 'bg-gray-300 bg-opacity-30 cursor-not-allowed' : ''}`}
                                    selected={field.value ? calculateEquivalentUTCDate(field.value as Date) : null}
                                    onChange={(date: Date) => field.onChange(date)}
                                    disabled={watch('isLifeTimeInsurance') === true}
                                    minDate={new Date()}
                                    placeholderText="Select Date"
                                />
                            )}
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">Lifetime Insurance</label>
                        <Controller control={control} name='isLifeTimeInsurance' render={({ field }) => (
                            <Checkbox
                                checked={field.value}
                                onChange={(e) => {
                                    field.onChange(e.target.checked)
                                    if (e.target.checked) {
                                        setValue('terminationDate', null)
                                    }
                                }}
                            />

                        )}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">Deductible Amount</label>
                        <Controller
                            {...register("deductableAmount")}
                            control={control}
                            name='deductableAmount'
                            render={({ field }) => (
                                <input
                                    type="text"
                                    className="border border-gray-300 rounded h-10 w-full"
                                    value={field.value}
                                    min={0}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const validValue = amountValidation(value);
                                        field.onChange(validValue);
                                    }}
                                />
                            )}
                        />
                    </div>

                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">Remaining Amount</label>
                        <Controller
                            {...register("remainingAmount")}
                            control={control}
                            name='remainingAmount'
                            render={({ field }) => (
                                <input
                                    type="text"
                                    className="border border-gray-300 rounded h-10 w-full"
                                    value={field.value}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const validValue = amountValidation(value);
                                        field.onChange(validValue);
                                    }}
                                />
                            )}
                        />
                    </div>

                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">Copay</label>
                        <Controller
                            {...register("copay")}
                            control={control}
                            name='copay'
                            render={({ field }) => (
                                <input
                                    type="text"
                                    className="border border-gray-300 rounded h-10 w-full"
                                    value={field.value}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const validValue = amountValidation(value);
                                        field.onChange(validValue);
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>

                <div>
                    <label className="block text-md font-medium text-gray-700">Prior Auth</label>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 pt-3">
                        {cptCodes.map((code) => (
                            <div key={code.value}>
                                <label className="block text-sm font-medium text-gray-700">{code.label}</label>
                                <Controller
                                    control={control}
                                    name={`priorAuth.${code.value}`}
                                    render={({ field }) => (
                                        <div className="flex space-x-4">
                                            {cptCodeOptions.map((option) => (
                                                <label key={option.value} className="flex items-center space-x-2">
                                                    <Radio
                                                        id={`${code.value}-${option.value}`}
                                                        name={field.name}
                                                        checked={field.value === option.value}
                                                        value={field.value || ''}
                                                        onChange={(e) =>
                                                            field.onChange(e.target.checked ? option.value : '')
                                                        }
                                                    />
                                                    <span>{option.label}</span>
                                                </label>
                                            ))}
                                        </div>
                                    )}
                                />
                            </div>
                        ))}
                    </div>
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">Insurance Eligibility</label>
                    <Controller
                        control={control}
                        name={'isPatientEligible'}
                        render={({ field }) => (
                            <div className="flex space-x-4">
                                {eligibilityOptions.map((option) => (
                                    <label key={option.value} className="flex items-center space-x-2">
                                        <Radio
                                            id={`isPatientEligible-${option.value}`}
                                            name={field.name}
                                            checked={getValues("isPatientEligible") === option.value}
                                            value={option.value}
                                            onChange={(e) => setValue("isPatientEligible", e.target.value)}
                                        />
                                        <span>{option.label}</span>
                                    </label>
                                ))}
                            </div>
                        )}
                    />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">Insurance Call Status</label>
                        <Controller
                            control={control}
                            name='insuranceCallStatus'
                            render={({ field }) => (
                                <select
                                    className="border border-gray-300 rounded h-10 w-full"
                                    value={field.value}
                                    onChange={(e) => {
                                        field.onChange(e.target.value)
                                        handleInsuranceStatusChange(e.target.value)
                                    }}
                                >
                                    <option value="">Select Status</option>
                                    {insuranceCallStatusOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            )}
                        />
                    </div>
                    {getValues("referenceNumber") && (
                        <p className="text-[#5D7285] text-xs italic font-normal leading-[18px] mt-7">
                            Ref No: {getValues("referenceNumber")}
                        </p>
                    )}
                </div>

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Verification Notes</label>
                    <Controller
                        {...register("verificationNotes")}
                        control={control}
                        name='verificationNotes'
                        render={({ field }) => (
                            <TextAreaComponent
                                placeholder="Enter any additional information if applicable"
                                value={field.value ?? ""}
                                onChange={(value) => field.onChange(value)}
                            />
                        )}
                    />
                </div>

                <div className="flex justify-end space-x-2">
                    <Button

                        className={`!bg-sjOrange !text-white ${watch("insuranceCallStatus") === "" || watch("isPatientEligible") === null || checkInsuranceEligibilityInProgress ? 'cursor-not-allowed opacity-50' : ''}`}
                        disabled={watch("insuranceCallStatus") === "" || watch("isPatientEligible") === null || checkInsuranceEligibilityInProgress}
                        type="submit">
                        Confirm
                    </Button>
                </div>

                <ConfirmationModal
                    isOpen={isModalOpen}
                    modalClassNames={'!w-1/2'}
                    onClose={() => setIsModalOpen(false)}
                    alignContentInCenter={false}
                    title={
                        <div className="text-[#1D1B20] font-nunito text-[16px] font-normal">
                            Add Call Reference Number <span className="text-[#5D7285] text-[12px] italic">(Optional)</span>
                        </div>
                    }
                    description={
                        <div className="text-[#5D7285] font-nunito text-[14px] font-normal leading-[22px]">
                            If you have a call reference number from the insurance provider, please enter it below.
                        </div>
                    }
                    actions={
                        <div className="flex justify-end space-x-2">
                            <Button
                                className="border-sjOrange text-sjOrange"
                                onClick={
                                    () => {
                                        setIsModalOpen(false);
                                    }
                                }>
                                Confirm
                            </Button>
                        </div>
                    }
                >
                    <div className="space-y-4">
                        <input
                            type="text"
                            className="border border-gray-300 rounded h-10 w-full"
                            value={watch('referenceNumber')}
                            placeholder="Enter Number"
                            onChange={(e) => setValue('referenceNumber', e.target.value)}
                        />

                        <TextAreaComponent
                            value={watch('additionalInfo') ?? ""}
                            placeholder="Enter any additional information if applicable"
                            onChange={(value) => setValue('additionalInfo',value)}
                        />
                    </div>
                </ConfirmationModal>

            </form>
        </>
    );
};

export default InsuranceEligibilityCheck;
