import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { Practice } from "../../../models";
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-modal';
import { Button } from "../../../components/generic/Buttons";
import {PreferredLanguageFilterOptions} from "../../../services/common.service";
import DateRangePickerComponent from "../../../components/generic/date-range-picker/DateRangePickerComponent";
interface Filter {
    isOpen: boolean
    setOpenFilterModal: (openFilterModal: boolean) => void
    practices?: Practice[]
    practiceIds: string[]
    setPracticeIds: (practiceId: string[]) => void
    searchText: string
    setSearchText: (text: string) => void
    recordsCount?: number
    setCurrentPage: (page: number) => void
    onFilterChange: (startDate: string | undefined, endDate: string | undefined) => void;
    startDate?: string
    endDate?: string
    isPendingNotes: boolean
    onFiltersApply: () => void
    preferredLanguage?: string
    setPreferredLanguage?: (preferredLanguage: string) => void
    isSWList?: boolean
}

//Creating SWComplianceListFilterV2 to show filters in advanced filters section.
//SWComplianceListFilter shows all filters in the main UI.
const SWComplianceListFilterV2 = (props: Filter) => {
    const { isOpen, setOpenFilterModal, practices, practiceIds, setPracticeIds, setCurrentPage, onFilterChange, startDate, endDate, isPendingNotes, onFiltersApply, preferredLanguage, setPreferredLanguage, isSWList } = props
    const [dateFilter, setDateFilter] = useState<{ startDate?: string, endDate?: string}>({startDate, endDate});
    const [selectedPractices, setSelectedPractices] = useState<Practice[]>([]);

    useEffect(() => {
        if (!isPendingNotes) {
            setDateFilter({startDate: undefined, endDate: undefined})
        }
    }, [isPendingNotes])

    useEffect(() => {
        const selectedOptions = practices?.filter(practice => practiceIds.includes(practice.id)) || [];
        setSelectedPractices(selectedOptions);
    }, [practices, practiceIds]);

    const handleDateFilterChange = useCallback((startDate: string, endDate: string) => {
        setDateFilter({startDate, endDate});
        onFilterChange(startDate, endDate);
    }, [onFilterChange])

    const handleCancelFilter = () => {
        setOpenFilterModal(false)
    }

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isOpen}
            onRequestClose={() => setOpenFilterModal(false)}
            className="fixed top-0 right-0 mx-auto p-5 border w-[480px] shadow-lg bg-white min-h-screen"
        >
            <span className="flex justify-center font-bold text-lg">Filters</span>
            <div className="mt-2 px-4 py-3">
                {isPendingNotes &&
                    <div className="physician-dashboard-date-filter">
                        <span className="content-start">Notes Date</span>
                        <DateRangePickerComponent
                            startDate={dateFilter.startDate}
                            endDate={dateFilter.endDate}
                            onFilterChange={handleDateFilterChange}
                            isClearable={true}
                            placeholder="Notes Date"
                        />
                    </div>
                }
                {!isPendingNotes && !isSWList &&
                <div className="mt-4">
                    <span>Preferred Language</span>
                    <Select
                        className={"w-full"}
                        options={PreferredLanguageFilterOptions}
                        placeholder={"Language"}
                        isClearable={true}
                        isSearchable={false}
                        onChange={(selectedOption: any) => {
                            setCurrentPage(1);
                            setPreferredLanguage?.(selectedOption?.value);
                        }}
                        value={PreferredLanguageFilterOptions.find(option => option.value === preferredLanguage) || null}
                    />
                    </div>
                }
                <div className="mt-4">
                    <span>Practice</span>
                    <Select
                        className="w-full"
                        options={practices}
                        onChange={(selectedOptions) => {
                            const mutableOptions = selectedOptions ? selectedOptions as Practice[] : [];
                            const selectedIds = mutableOptions.map(option => option.id);
                            setPracticeIds(selectedIds);
                            setSelectedPractices(mutableOptions);
                        }}
                        placeholder={"Practice"}
                        isClearable
                        isSearchable={false}
                        isMulti
                        getOptionLabel={option => `${option?.name}`}
                        getOptionValue={option => option?.id}
                        value={selectedPractices}
                    />
                </div>
                {/* <div className="mt-4">
                    <span>Name Search</span>
                    <SearchFilter
                        searchClassNames='w-full'
                        searchText={searchText}
                        setSearchFilter={setSearchText}
                        currentPageHandler={setCurrentPage}
                    />
                </div> */}
            </div>
            <div className="mt-8 flex justify-center px-4 py-3">
                <Button className='!bg-sjOrange !text-sjWhite mr-4' onClick={onFiltersApply}>
                    Apply Filter
                </Button>
                <Button className='!text-sjOrange !border-sjOrange !border-2' onClick={handleCancelFilter}>
                    Discard
                </Button>
            </div>
        </Modal>
    )
}

export default SWComplianceListFilterV2