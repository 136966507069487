import React, {useState} from "react";
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import {AppointmentStatus, IAppointmentWithTherapist, SessionTypes} from "../../models";
import {updateSessionStatus} from "../../store/actions/counsellor/appointments.action";
import ConfirmAppointmentStatusModal from "../clinical-notes/intake/ConfirmAppointmentStatusModal";

export const AppointmentStatusButtonV2: React.FC<{row : any, getValue: any}> = ({getValue}) => {
    const value = getValue()
    const appointmentStatus = value.status
    const prospectId = value.patientProspect.id
    const [canShowAppointmentConfirmationModal, setCanShowAppointmentConfirmationModal] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    function navigateToNote(path: string) {
        dispatch(updateSessionStatus({sessionId: value.id, status: 'in_progress'}))
        navigate(path)
    }

    const onCreateSession = () => {
        if(value.sessionType === SessionTypes.Intake &&  prospectId ) {
            navigate(`/prospects/${prospectId}/summary?practiceId=${value.patientProspect.referredByPracticeId}&sourcePatientId=${value.patientProspect.sourcePatientId}`)
        } else {
            setCanShowAppointmentConfirmationModal(true)
        }
    }

    const onViewDetailsClick = () => {
        if (value.sessionType === SessionTypes.Intake && prospectId) {
            navigate(`/prospects/${prospectId}/summary?practiceId=${value.patientProspect.referredByPracticeId}&sourcePatientId=${value.patientProspect.sourcePatientId}`)
        } else {
            navigate(`/patients/${prospectId}/journey`)
        }
    }

    function renderCallButton() {
        return (
            <button className="rounded-l-md border-sjOrange text-sjLink w-full text-start" onClick={onCreateSession}>
                {value.sessionType === SessionTypes.Intake ? "View Details" : 'Start the Call'}
            </button>
        );
    }

    function renderJoinCallButton() {
        return (
            <button className="rounded-l-md border-sjOrange text-sjLink w-full text-start" onClick={onCreateSession}>
                {value.sessionType === SessionTypes.Intake ? "View Details" : 'Rejoin the Call'}
            </button>
        );
    }

    function renderViewDetailsButton() {
        return (
            <button className="rounded-l-md border-sjOrange text-sjLink w-full text-start" onClick={onViewDetailsClick}>
                View Details
            </button>
        );
    }

    function renderStatusText() {
        return (<span> { appointmentStatus?.replace("_", " ").toUpperCase() } </span>)
    }

    function renderStatusAction(appointmentStatus: AppointmentStatus) {
        switch (appointmentStatus) {
            case AppointmentStatus.ready:
                return renderCallButton()
            case AppointmentStatus.rejoin:
                return renderViewDetailsButton()
            default:
                return renderStatusText()
        }
    }

    return <>
        {renderStatusAction(appointmentStatus)}
        <ConfirmAppointmentStatusModal prospect={value.patientProspect} setCanShowAppointmentConfirmationModal={setCanShowAppointmentConfirmationModal} canShowAppointmentConfirmationModal={canShowAppointmentConfirmationModal} session={value as IAppointmentWithTherapist}/>
    </>;

}
