import {
    Control,
    FieldArrayWithId,
    useFieldArray,
    UseFieldArrayRemove,
    UseFormGetValues,
    UseFormRegister,
    Controller                } from "react-hook-form";
import { Button } from "../generic/Buttons";
import { DateTime } from "luxon";
import TextAreaComponent from "../generic/TextAreaComponent";

type Addendum = {
    addendum: string
    by: string
    on?: Date
}
type AddendumProps = {
    control: Control<any>;
    register: UseFormRegister<any>;
    addendums: FieldArrayWithId<any, "newAddendum", "id">[]
    remove: UseFieldArrayRemove
    saveNotes: (key: keyof any, answer: any) => void
    getValues: UseFormGetValues<any>
}

const Addendum = ({ register, control, addendums, remove, saveNotes, getValues }: AddendumProps) => {
    const { fields: historicalAddendums, append: historicalAppend, remove: historicalRemove } = useFieldArray({
        control,
        name: "addendums" 
    })

    function handleSave() {
        const newAddendum = getValues().newAddendum[0]
        const historicalAddendums = getValues().addendums || []
        const answer = [...historicalAddendums, newAddendum]
        historicalAppend(newAddendum)
        saveNotes('addendums', answer)
        handleCancel()
    }

    function handleCancel() {
        if(addendums.length > 0) {
            remove()
            setTimeout(() => saveNotes('newAddendum', []), 1000)
        }
    }

    return (
        <div>
            {historicalAddendums.map((item: any, index) => {
                return (
                    <div key={item.id}>
                        <div className="flex justify-between">
                            <label className="text-lg mt-6 uppercase">addendum note by {(item as any).by}</label>
                            <label className="text-md mt-6">Added on: {item && item.on ? DateTime.fromISO(item.on, {zone: 'utc'}).toFormat('MM/dd/yyyy') : ''}</label>
                        </div>
                            <Controller
                            name={`addendums.${index}.addendum`}
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <TextAreaComponent
                                value={field.value}
                                isDisabled={true}
                                onChange={field.onChange}
                                placeholder=""
                                />
                            )}
                            />
                    </div>
                )
            })}
            {addendums.map((item, index) => {
                return (
                    <div key={item.id}>
                        <label className="block text-lg mt-6 uppercase">addendum note</label>
                                <Controller
                                name={`newAddendum.${index}.addendum`}
                                control={control}
                                defaultValue=""
                                render={({field}) => (
                                    <TextAreaComponent
                                    value={field.value}
                                    onChange={field.onChange}
                                    placeholder=""
                                    />
                                )}
                                />
                    </div>
                )
            })}
            {addendums.length > 0 && <div className="flex justify-end mt-1">
                <Button className={"!bg-[#E5E8EB] !text-black !ml-2"} onClick={handleCancel} data-testid="cancelAddendumBtn">Cancel</Button>
                <Button className={"!bg-sjOrange !text-white !ml-2"} onClick={handleSave} data-testid="submitAddendumBtn">Submit</Button>
            </div>}
        </div>
    )
}

export default Addendum