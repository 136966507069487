import React, {useMemo} from "react";
import "../PhysicianDashboardScreen.scss";
import TablePaginationV2 from "../../../../components/generic/TablePaginationV2";
import { AggregatePatientSummaryByLocation } from "../../../../models";
import { TableSkeletonLoader } from "../../../../components/generic/TableSkeletonLoader";

interface PhysicianDashboardLocationBasedScreenersComponentProps {
    patientSummary: AggregatePatientSummaryByLocation[]
    searchInProgress: boolean | undefined
}

const PhysicianDashboardLocationBasedScreenersComponent : React.FC<PhysicianDashboardLocationBasedScreenersComponentProps> = ({patientSummary, searchInProgress}) => {
    const data = patientSummary.map((patient) => {
        return {
            location: patient.location,
            patientsScreened: patient.patientsScreened,
            patientsReferred:  patient.patientsReferred,
            patientsInCoCM: patient.patientsEnrolled
        }
    });

    const columns = useMemo( () => [
        { header: "Location", accessorKey: "location" },
        { header: "Screened Visits", accessorKey: "patientsScreened" },
        { header: "Patients Referred ", accessorKey: "patientsReferred" },
        { header: "Patients in CoCM", accessorKey: "patientsInCoCM" }
    ], [] );

    return (
        <div className="physician-dashboard-location-based-screeners physician-dashboard-list-card">
            <div className="physician-dashboard-list-card-header">
                <div className="physician-dashboard-list-card-header-title">Location based Screeners</div>
                <div className="physician-dashboard-list-card-header-subtitle">Screener Statics in each location</div>
            </div>
            <div className="physician-dashboard-list-card-content">
                {searchInProgress === undefined ? <TableSkeletonLoader /> :
                    <TablePaginationV2 columns={columns} data={data} size={"small"} searchInProgress={searchInProgress} />
                }
            </div>
        </div>
    )
}

export default PhysicianDashboardLocationBasedScreenersComponent;