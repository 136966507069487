import React from "react";
import PatientStartIntakeComponent from "./PatientStartIntakeComponent";

const PatientIntakeToggleOnComponent: React.FC = () => {
    return <PatientStartIntakeComponent />
}

const PatientIntakeToggleComponent: React.FC = () => {
    return <PatientIntakeToggleOnComponent />
}

export default PatientIntakeToggleComponent
