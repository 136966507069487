import React, { useState, useEffect, useMemo } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { FaPhone, FaVideo, FaCalendarAlt } from 'react-icons/fa';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const localizer = momentLocalizer(moment);

export interface CalendarEvent {
  title: string;
  start: Date;
  end: Date;
  type: 'intake' | 'treatment' | 'progress' | 'catchUp';
  modality: 'phone' | 'video';
}

interface CalendarWrapperProps {
  events: CalendarEvent[];
  date: Date;
  onSelectSlot: (slotInfo: { start: Date; end: Date; slots: Date[]; action: string }) => void;
  onSelectEvent?: (event: CalendarEvent) => void;
  eventPropGetter?: (event: CalendarEvent) => { style?: React.CSSProperties };
  tooltipAccessor?: (event: CalendarEvent) => string;
  style?: React.CSSProperties;
}

export const CalendarWrapper: React.FC<CalendarWrapperProps> = ({
  events,
  date: initialDate,
  onSelectSlot,
  onSelectEvent,
  eventPropGetter,
  tooltipAccessor,
  style,
}) => {
  const [date, setDate] = useState(moment(initialDate));

  // const defaultSettings = useMemo(() => ({
  //   defaultDate: new Date(),
  //   scrollToTime: new Date(), // Scroll to 6:00 AM
  // }), []);

  const eventStyles = {
    intake: { backgroundColor: '#0369A1', color: '#FFFFFF' },
    treatment: { backgroundColor: '#F8E6A0', color: '#816103' },
    progress: { backgroundColor: '#C6EDFB', color: '#206A83' },
    catchUp: { backgroundColor: '#E6E1FF', color: '#6E5DC6' },
    other: { backgroundColor: '#5D7285', color: '#FFFFFF' },
  };

  const getEventStyle = (event: CalendarEvent) => ({
    style: {
      ...eventStyles[event.type],
      fontWeight: 'bold',
      padding: '5px',
      borderRadius: '4px',
      border: 'none',
      width: '100%',
    },
  });

  const EventComponent = ({ event }: { event: CalendarEvent }) => (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '5px', width: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <span className='text-xs'>{moment(event.start).format('h:mm A')}</span>
        {event.modality === 'phone' ? <FaPhone /> : <FaVideo />}
      </div>
      <div className='text-xs mt-1'>{event.title}</div>
    </div>
  );

  const CustomToolbar = ({ date }: { date: Date }) => {
    const currentDate = moment(date);
    return (
      <div className='flex items-center gap-4 pb-4'>
        <div className='flex gap-1'>
          <button className='bg-gray-100 px-2 py-1 rounded' onClick={() => setDate(moment())}>Today</button>
          <button className='bg-gray-100 px-2 py-1 rounded' onClick={() => setDate(currentDate.clone().subtract(1, 'week'))}>
            <IoIosArrowBack />
          </button>
          <button className='bg-gray-100 px-2 py-1 rounded' onClick={() => setDate(currentDate.clone().add(1, 'week'))}>
            <IoIosArrowForward />
          </button>
        </div>
        <div className='text-lg'>
          {currentDate.startOf('week').format('MMM D')} - {currentDate.endOf('week').format('MMM D')}
        </div>
      </div>
    );
  };

  const formats = {
    eventTimeRangeFormat: () => {
      return '';
    },
  };

  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(2015, 3, 12),
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    []
  )

  return (
    <div style={style} className='h-full'>
      <BigCalendar
        localizer={localizer}
        dayLayoutAlgorithm={'no-overlap'}
        events={events}
        defaultDate={defaultDate}
        defaultView='week'
        formats={formats}
        views={['week']}
        selectable
        scrollToTime={scrollToTime}
        date={date.toDate()}
        eventPropGetter={getEventStyle}
        tooltipAccessor={tooltipAccessor}
        onSelectEvent={onSelectEvent}
        onSelectSlot={onSelectSlot}
        components={{
          event: EventComponent,
          toolbar: CustomToolbar,
          timeGutterHeader: () => <span className='text-sm font-bold flex justify-center'>EST</span>,
        }}
      />
    </div>
  );
};
