import React, {useCallback, useEffect, useState} from 'react';
import { FiltersTypes, ISOAPNotePatient } from '../../models';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StateParams } from '../../store/reducers';
import { DateTime } from 'luxon';
import noPatients from "../../assets/images/noPatients.png";
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchSoapNotePatients } from '../../store/actions/physician/admin-operations.action';
import LoadingComponent from '../../components/generic/LoadingComponent';
import SoapNoteListView from './SOAPNotesListView';
import { CaptilizeFirstLetter } from '../../services/common.service';
import SOAPNoteFilters from './SOAPNoteFilters';

const SOAPNotesScreen = () => {

    const [filters, setFilters] = useState<FiltersTypes>();
    const [searchText, setSearchText] = useState('');
    const [searchParams] = useSearchParams();
    const [pageNumber, setPageNumber] = useState(Number(searchParams.get("page")) || 1);
    const [recordsPerPage] = useState(10);
    const [allSOAPPatients, setAllSOAPPatients] = useState<ISOAPNotePatient[]>([]);
    const dispatch = useDispatch();
    const [selectedPatient, setSelectedPatient] = useState<ISOAPNotePatient | null>(null);
    const { soapNotePatients: patients, fetchSoapNotePatientsInProgress, fetchSoapNotePatientsError, totalSoapNotePatientsCount } = useSelector((state: StateParams) => state.physicianAdminOperations);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [initialFetch, setInitialFetch] = useState<boolean>(false);

    useEffect(() => {
        if (patients) {
            if (pageNumber === 1) {
                constructInitialData();
                setAllSOAPPatients(patients);
            } else {
                setAllSOAPPatients((prev: ISOAPNotePatient[]) => {
                    const existingPatientIds = new Set(prev.map((patient: ISOAPNotePatient) => patient.id));
                    const newPatients = patients.filter((patient: ISOAPNotePatient) => !existingPatientIds.has(patient.id));
                    return [...prev, ...newPatients];
                });
            }
        }
        if (patients && allSOAPPatients.length === totalSoapNotePatientsCount) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    }, [patients, pageNumber, totalSoapNotePatientsCount]);

    const handlePatientClick = useCallback((patient: ISOAPNotePatient) => {
        setSelectedPatient(patient);
    }, []);

    useEffect(() => {
        dispatch(fetchSoapNotePatients({ pageNumber, recordsPerPage, searchText, ...filters }));
    }, [dispatch, pageNumber, recordsPerPage, filters, searchText]);

    const constructInitialData = useCallback(() => {
        if (fetchSoapNotePatientsInProgress && !fetchSoapNotePatientsError) {
            setInitialFetch(true);
        } else {
            setInitialFetch(false);
        }
    }, [fetchSoapNotePatientsInProgress, fetchSoapNotePatientsError]);

    const handleFiltersSubmit = useCallback((data: any) => {
        setSelectedPatient(null);
        setFilters(data);
        constructInitialData();
        setAllSOAPPatients([]);
        setPageNumber(1);
    }, [constructInitialData]);

    const fetchMoreData = useCallback(() => {
        if (!fetchSoapNotePatientsInProgress) {
            setPageNumber((prev) => prev + 1);
        }
    }, [fetchSoapNotePatientsInProgress]);

    const handleSearch = useCallback((searchText: string) => {
        setSelectedPatient(null);
        setSearchText(searchText);
        constructInitialData();
        setAllSOAPPatients([]);
        setPageNumber(1);
    }, [constructInitialData]);

    return (
        <div className="w-full p-4 bg-[#F7F8FA] soap-physician-wrapper h-full flex flex-col">
            <div className="flex flex-row justify-between items-center">
                <h1 className="text-xl"> S.O.A.P </h1>
                <SOAPNoteFilters onSubmit={handleFiltersSubmit} setCurrentPage={setPageNumber} setSearchText={handleSearch} />
            </div>
            <LoadingComponent  isLoading={initialFetch} />
            {allSOAPPatients.length > 0 ? <div className='soap-physician-content flex-1'>
                <div className="grid grid-cols-12 gap-4 pt-5 h-full">
                    <div className="col-span-3 bg-white mb-25 overflow-y-auto max-h-[90vh]" id="scrollableDiv">
                        <InfiniteScroll
                            dataLength={allSOAPPatients.length}
                            next={fetchMoreData}
                            hasMore={hasMore}
                            loader={hasMore &&
                                <div className="flex justify-center items-center h-20">
                                    <div className="infinite-loading"></div>
                                </div>
                            }
                            scrollThreshold={0.9}
                            scrollableTarget="scrollableDiv"
                        >
                            {allSOAPPatients.map((soapPatient, index) => {
                                if (!soapPatient.patient) return null;
                                const fullName = CaptilizeFirstLetter(soapPatient.patient.firstName) + " " + CaptilizeFirstLetter(soapPatient.patient.lastName);
                                return (
                                    <React.Fragment key={index}>
                                        <div
                                            className={`${selectedPatient?.patient?.id === soapPatient.patient.id ? 'border-l-amber-500' : 'border-l-transparent hover:bg-gray-100'
                                                } border-l-4 cursor-pointer pl-4`}
                                            onClick={() => handlePatientClick(soapPatient)}
                                            data-tooltip-id={`patient-name-tooltip-${index}`}
                                        >
                                            <div className="items-center pt-4">
                                                <div className="patient-name">
                                                    {fullName}
                                                </div>

                                                <div className="patient-dob">
                                                    {DateTime.fromISO(soapPatient?.patient.dob.toString(), { zone: 'utc' }).toFormat('MM/dd/yyyy')}
                                                </div>
                                            </div>
                                            <div className="text-sm my-2 text-gray-700">
                                                Latest Visit: {DateTime.fromISO(soapPatient.createdAt.toString(), { zone: 'America/New_York' }).toFormat('MM/dd/yyyy, hh:mm a')}
                                            </div>
                                            <div className="flex flex-row items-center patient-location pb-2">
                                               {soapPatient?.location?.name}
                                            </div>
                                        </div>
                                        <hr />
                                    </React.Fragment>
                                );
                            })}
                        </InfiniteScroll>
                    </div>
                    <div className="col-span-9">
                        {selectedPatient ?
                            <SoapNoteListView selectedPatient={selectedPatient.patient} />
                            :
                            <div className="flex flex-col align-items-center bg-white h-full pt-10">
                                <img src={noPatients} alt="No Patient Selected" />
                                Click on any Patient record to view the generated SOAP note for a patient.
                            </div>}

                    </div>
                </div>
            </div> : <div className="flex justify-center items-center h-96">
                {!fetchSoapNotePatientsInProgress && <h1>No Patients Found</h1>}
            </div>}
        </div>
    );
};

export default SOAPNotesScreen;
