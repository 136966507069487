import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    editSoapNoteContent,
    editSoapNoteReason,
    fetchSoapNoteContent,
    fetchSoapNotes,
    markSoapNoteAsRead
} from '../../store/actions/physician/admin-operations.action';
import {DateTime} from 'luxon';
import EditIcon from "../../assets/images/common/editRoundIcon.svg";
import ErrorWarningIcon from "../../assets/images/common/errorWarning.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {StateParams} from '../../store/reducers';
import {ISOAPNote} from '../../models';
import LoadingComponent from '../../components/generic/LoadingComponent';
import AccordianCard from '../../components/generic/Accordian';
import CopyButton from '../../components/generic/CopyButton';
import PendingIcon from '../../assets/images/common/pending.svg';
import WarnIcon from '../../assets/images/common/warn.svg';
import {Button} from "../../components/generic/Buttons";
import ConfirmationModal from '../../components/clinical-notes/ConfirmationModal';
import {useFlags} from "flagsmith/react";
import {CaptilizeFirstLetter} from '../../services/common.service';
import {SOAPFeedbackComponent} from "./components/SOAPFeedbackComponent";
import {RiErrorWarningFill} from "react-icons/ri";
import {IoIosWarning} from "react-icons/io";

interface SoapNoteListViewProps {
    selectedPatient: any;
}

const responsive = {
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 4,
        partialVisibilityGutter: 40
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 2,
        partialVisibilityGutter: 30
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1,
        partialVisibilityGutter: 30
    }
};

const SOAP_NOTE_GENERATED = 'soap_note_generated';
const PENDING_FOR_UPLOAD = 'pending_for_upload';
const UPLOAD_IN_PROGRESS = 'upload_in_progress';
const UPLOAD_FAILED = 'upload_failed';
const UPLOAD_SUCCESSFUL = 'upload_successful';
const PENDING_FOR_TRANSCRIPTION = 'pending_for_transcription';
const TRANSCRIPTION_UNDER_PROGRESS = 'transcription_under_progress';
const TRANSCRIPTION_FAILED = 'transcription_failed';
const TRANSCRIPTION_GENERATED = 'transcription_generated';
const PENDING_FOR_SOAP_NOTE_GENERATION = 'pending_for_soap_note_generation';
const SOAP_NOTE_GENERATION_FAILED = 'soap_note_generation_failed';
const SOAP_NOTE_GENERATION_UNDER_PROGRESS = 'soap_note_generation_under_progress';


const SoapNoteListView: React.FC<SoapNoteListViewProps> = ({selectedPatient}) => {

    const isPhysicianSoapNoteEditEnabled = useFlags(['edit_soap_note_section'])?.edit_soap_note_section?.enabled;
    const isSOAPFeedBackEnabled = useFlags(['soap_feedback'])?.soap_feedback?.enabled;
    const dispatch = useDispatch();
    const {
        soapNoteList,
        fetchSoapNotesInProgress,
        markSoapNoteAsReadSuccess,
        soapNoteContent,
        fetchSoapNoteContentInProgress,
        editSoapNoteReasonSuccess,
        editedSoapNote,
        editSoapNoteReasonInProgress
    } = useSelector((state: StateParams) => state.physicianAdminOperations);
    const [selectedNoteId, setSelectedNoteId] = useState<string>("");
    const [soapNoteJson, setsoapNoteJson] = useState<any>(null);
    const [soapNotes, setSOAPNotes] = useState<ISOAPNote[]>([]);
    const [isEditing, setIsEditing] = useState<{ [key: string]: boolean }>({});
    const [editedContent, setEditedContent] = useState<{ [key: string]: string }>({});
    const [openReasonForVistModal, setOpenReasonForVistModal] = useState<boolean>(false);
    const [reasonForVisit, setReasonForVisit] = useState('');
    const [editedReasonSOAPId, setEditedReasonSOAPId] = useState('');
    const [SOAPStatus, setSOAPStatus] = useState<string>('');
    const [isEmptySOAPNote, setIsEmptySOAPNote] = useState<boolean>(false);

    const fetchPatientSOAPNotes = useCallback(() => {
        dispatch(fetchSoapNotes(selectedPatient.id));
    }, [dispatch, selectedPatient]);

    useEffect(() => {
        setSelectedNoteId('');
        fetchPatientSOAPNotes();
    }, [dispatch, fetchPatientSOAPNotes]);

    useEffect(() => {
        if (soapNoteList) {
            setSOAPNotes(soapNoteList);
        }
    }, [soapNoteList]);

    useEffect(() => {
        if (soapNoteContent) {
            const content = soapNoteContent as {
                overview: string;
                subjective: string;
                objective: string;
                assessment: string;
                plan: string;
            };
            if (content.overview === '' && content.subjective === '' && content.objective === '' && content.assessment === '' && content.plan === '') {
                setIsEmptySOAPNote(true);
            } else {
                setIsEmptySOAPNote(false);
            }
            setsoapNoteJson(soapNoteContent);
        } else {
            setsoapNoteJson({});
            setIsEmptySOAPNote(false);
        }
    }, [soapNoteContent]);

    const handleSOAPNoteStatus = (status: string, noteId: string) => {
        switch (status) {
            case SOAP_NOTE_GENERATED:
                dispatch(fetchSoapNoteContent(noteId));
                return 'success';
            case PENDING_FOR_UPLOAD:
            case UPLOAD_IN_PROGRESS:
            case PENDING_FOR_TRANSCRIPTION:
            case PENDING_FOR_SOAP_NOTE_GENERATION:
            case TRANSCRIPTION_UNDER_PROGRESS:
            case UPLOAD_SUCCESSFUL:
            case SOAP_NOTE_GENERATION_UNDER_PROGRESS:
            case TRANSCRIPTION_GENERATED:
                return 'inprogress';
            case SOAP_NOTE_GENERATION_FAILED:
            case UPLOAD_FAILED:
            case TRANSCRIPTION_FAILED:
                return 'failed';
            default:
                return 'draft';
        }
    }

    useEffect(() => {
        if (soapNotes.length > 0) {
            const latestSOAPNote = soapNotes.reduce((latest, current) => {
                const latestDate = DateTime.fromISO(latest.createdAt.toString(), {zone: 'America/New_York'});
                const currentDate = DateTime.fromISO(current.createdAt.toString(), {zone: 'America/New_York'});
                return currentDate > latestDate ? current : latest;
            }, soapNotes[0]);
            setSelectedNoteId(latestSOAPNote.id);
            setSOAPStatus(handleSOAPNoteStatus(latestSOAPNote.status, latestSOAPNote.id));
        }
    }, [soapNotes, dispatch]);

    const handleNoteClick = useCallback(async (soapNote: ISOAPNote) => {
        const noteId = soapNote?.id;
        const status = soapNote?.status;
        const isSOAPNoteRead = soapNote?.isSOAPNoteRead;
        setIsEmptySOAPNote(false);
        setSelectedNoteId(noteId);
        setSOAPStatus(handleSOAPNoteStatus(status, noteId));
        if (!isSOAPNoteRead) {
            dispatch(markSoapNoteAsRead(noteId));
        }
        try {
            await navigator.clipboard.writeText(JSON.stringify(soapNote));
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    }, [dispatch, handleSOAPNoteStatus]);

    const convertNewLinesToHtml = (text: string) => {
        return text.replace(/\n/g, '<br /> <br />');
    };

    const handleEditClick = useCallback((section: string) => {
        setIsEditing({...isEditing, [section]: true});
        setEditedContent({...editedContent, [section]: soapNoteJson[section] || ''});
    }, [soapNoteJson]);

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>, section: string) => {
        setEditedContent({...editedContent, [section]: e.target.value});
    }, [editedContent]);

    const handleSaveClick = useCallback((section: string) => {
        dispatch(editSoapNoteContent(selectedNoteId, {...soapNoteJson, [section]: editedContent[section]}));
        setsoapNoteJson({...soapNoteJson, [section]: editedContent[section]});
        setIsEditing({...isEditing, [section]: false});
    }, [dispatch, selectedNoteId, soapNoteJson, editedContent, isEditing]);

    const handleReasonForVisitClick = useCallback((event: React.MouseEvent, reason: string, soapId: string) => {
        event.stopPropagation();
        setOpenReasonForVistModal(true);
        setEditedReasonSOAPId(soapId);
        setReasonForVisit(reason);
    }, []);

    const handleReasonForVisitSave = useCallback(() => {
        const reason = {
            reasonForVisit: reasonForVisit
        }
        dispatch(editSoapNoteReason(editedReasonSOAPId, reason));
        // setOpenReasonForVistModal(false);
    }, [dispatch, reasonForVisit, editedReasonSOAPId]);

    useEffect(() => {
        if (editedSoapNote && editSoapNoteReasonSuccess) {
            setOpenReasonForVistModal(false);
            setSOAPNotes(prevNotes =>
                prevNotes.map(note =>
                    note.id === editedSoapNote.id ? {...note, reasonForVisit: editedSoapNote.reasonForVisit} : note
                )
            );
        }
    }, [editSoapNoteReasonSuccess, reasonForVisit, editedSoapNote]);

    return (
        <div className="soap-notes-list-view">
            <div className='soap-notes-list-view-header-section ml-4 flex justify-between'>
                <div className="">
                    <div className="soap-notes-list-view-header mb-2">
                        {CaptilizeFirstLetter(selectedPatient?.firstName) || ''} {CaptilizeFirstLetter(selectedPatient?.lastName) || ''}
                    </div>
                    <div className='soap-notes-list-view-description'>Click the arrow icons to navigate through the
                        different SOAP notes for selected patient visit.
                    </div>
                </div>
                <div className='soap-notes-list-view-header'>
                    <Button onClick={fetchPatientSOAPNotes}>
                        Refresh
                    </Button>
                </div>
            </div>
            <LoadingComponent isLoading={fetchSoapNotesInProgress}/>
            {!fetchSoapNotesInProgress && <div className='py-5'>
                <Carousel
                    swipeable={true}
                    draggable={true}
                    responsive={responsive}
                    ssr={true}
                    keyBoardControl={true}
                    autoPlaySpeed={3000}
                    transitionDuration={500}
                    containerClass="carousel-container"
                    itemClass="carousel-item-padding-40-px px-4" // Add padding to each item
                    arrows={true}
                    sliderClass=""
                    slidesToSlide={1}
                >
                    {
                        soapNotes && soapNotes.map((soapNote: ISOAPNote) => {
                            return (
                                <div
                                    key={soapNote.id}
                                    className={`rounded-lg h-full cursor-pointer bg-white border-b-4 ${selectedNoteId === soapNote.id ? 'border-b-amber-500' : 'border-b-transparent'}`}
                                    onClick={() => handleNoteClick(soapNote)}
                                >
                                    <div className="p-4">
                                        <div className='flex justify-between'>
                                            <div className="text-sm">
                                                {DateTime.fromISO(soapNote.createdAt.toString(), {zone: 'America/New_York'}).toFormat('MM/dd/yyyy, hh:mm a')}
                                            </div>
                                            {isPhysicianSoapNoteEditEnabled && <button
                                                onClick={(event) => handleReasonForVisitClick(event, soapNote.reasonForVisit, soapNote.id)}
                                            >
                                                <img src={EditIcon} width={20} height={20}/>
                                            </button>}
                                        </div>
                                        <div className='flex pt-2 justify-between'>
                                            <div className="text-xs text-gray-500">{soapNote.reasonForVisit || '-'}</div>
                                            {/* <div>
                                                {
                                                    soapNote.isSOAPNoteRead && <div>
                                                        <img src={ReadIcon} width={25} height={25} />
                                                    </div>
                                                }
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Carousel>
            </div>
            }
            {!fetchSoapNotesInProgress && <LoadingComponent isLoading={fetchSoapNoteContentInProgress}/>}
            {!(fetchSoapNoteContentInProgress || fetchSoapNotesInProgress) &&
                <div className='overflow-y-auto max-h-[68vh]'>
                    {SOAPStatus === 'success' ?
                        <div>
                            {
                                (soapNoteJson && !isEmptySOAPNote && Object.keys(soapNoteJson).length > 0) ?
                                    <div className='soap-note-content px-4'>

                                        <AccordianCard title='Overview' canToggle={false}>
                                            {isEditing.overview ? (
                                                <>
                                                    <textarea
                                                        className="text-xs text-gray-500 border-orange-200 rounded p-2 w-full"
                                                        value={editedContent.overview}
                                                        onChange={(e) => handleInputChange(e, 'overview')}/>
                                                    <Button className="rounded-l-md border-sjOrange text-sjOrange"
                                                            onClick={() => handleSaveClick('overview')}>Save
                                                        Changes</Button>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="text-sm text-gray-500 border rounded p-4 space-y-2"
                                                         dangerouslySetInnerHTML={{__html: convertNewLinesToHtml(soapNoteJson.overview || '-')}}/>
                                                    <div className='flex justify-end gap-2 pt-3'>
                                                        {isPhysicianSoapNoteEditEnabled && <button className="text-xs"
                                                                                                   onClick={() => handleEditClick('overview')}>
                                                            <img src={EditIcon} width={20} height={20}/>
                                                        </button>}
                                                        <CopyButton content={soapNoteJson.overview}/>
                                                    </div>
                                                </>
                                            )
                                            }
                                        </AccordianCard>

                                        <AccordianCard title='Subjective'>
                                            {isEditing.subjective ? (
                                                <>
                                                <textarea
                                                    className="text-xs text-gray-500 border-orange-200 rounded p-2 w-full"
                                                    value={editedContent.subjective}
                                                    onChange={(e) => handleInputChange(e, 'subjective')}/>
                                                    <Button className="rounded-l-md border-sjOrange text-sjOrange"
                                                            disabled={!editedContent.subjective}
                                                            onClick={() => handleSaveClick('subjective')}>
                                                        Save Changes
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="text-sm text-gray-500 border rounded p-4 space-y-2"
                                                         dangerouslySetInnerHTML={{__html: convertNewLinesToHtml(soapNoteJson.subjective || '-')}}/>
                                                    <div className='flex justify-end gap-2 pt-3'>
                                                        {isPhysicianSoapNoteEditEnabled && <button className="text-xs"
                                                                                                   onClick={() => handleEditClick('subjective')}>
                                                            <img src={EditIcon} width={20} height={20}/>
                                                        </button>}
                                                        <CopyButton content={soapNoteJson.subjective}/>
                                                    </div>
                                                </>
                                            )}
                                        </AccordianCard>

                                        <AccordianCard title='Objective'>
                                            {isEditing.objective ? (
                                                <>
                                                    <textarea
                                                        className="text-xs text-gray-500 border-orange-200 rounded p-2 w-full"
                                                        value={editedContent.objective}
                                                        onChange={(e) => handleInputChange(e, 'objective')}/>
                                                    <Button className="rounded-l-md border-sjOrange text-sjOrange"
                                                            onClick={() => handleSaveClick('objective')}>Save
                                                        Changes</Button>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="text-sm text-gray-500 border rounded p-4 space-y-2"
                                                         dangerouslySetInnerHTML={{__html: convertNewLinesToHtml(soapNoteJson.objective || '-')}}/>
                                                    <div className='flex justify-end gap-2 pt-3'>
                                                        {isPhysicianSoapNoteEditEnabled && <button className="text-xs"
                                                                                                   onClick={() => handleEditClick('objective')}>
                                                            <img src={EditIcon} width={20} height={20}/>
                                                        </button>}
                                                        <CopyButton content={soapNoteJson.objective}/>
                                                    </div>
                                                </>
                                            )}
                                        </AccordianCard>

                                        <AccordianCard title='Assessment'>
                                            {isEditing.assessment ? (
                                                <>
                                                    <textarea
                                                        className="text-xs text-gray-500 border-orange-200 rounded p-2 w-full"
                                                        value={editedContent.assessment}
                                                        onChange={(e) => handleInputChange(e, 'assessment')}/>
                                                    <Button className="rounded-l-md border-sjOrange text-sjOrange"
                                                            onClick={() => handleSaveClick('assessment')}>Save
                                                        Changes</Button>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="text-sm text-gray-500 border rounded p-4 space-y-2"
                                                         dangerouslySetInnerHTML={{__html: convertNewLinesToHtml(soapNoteJson.assessment || '-')}}/>
                                                    <div
                                                        className="bg-[#FFF8EC] flex gap-1 align-items-center p-3 rounded mt-2 text-[#7C2D12]">
                                                        <IoIosWarning size={18}/>The generated ICD codes is a
                                                        suggestion. The final diagnosis should be based on your clinical
                                                        assessment.
                                                    </div>
                                                    <div className='flex justify-end gap-2 pt-3'>
                                                        {isPhysicianSoapNoteEditEnabled && <button className="text-xs"
                                                                                                   onClick={() => handleEditClick('assessment')}>
                                                            <img src={EditIcon} width={20} height={20}/>
                                                        </button>}
                                                        <CopyButton content={soapNoteJson.assessment}/>
                                                    </div>
                                                </>
                                            )}
                                        </AccordianCard>

                                        <AccordianCard title='Plan'>
                                            {isEditing.plan ? (
                                                <>
                                                    <textarea
                                                        className="text-xs text-gray-500 border-orange-200 rounded p-2 w-full"
                                                        value={editedContent.plan}
                                                        onChange={(e) => handleInputChange(e, 'plan')}/>
                                                    <Button className="rounded-l-md border-sjOrange text-sjOrange"
                                                            onClick={() => handleSaveClick('plan')}>Save
                                                        Changes</Button>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="text-sm text-gray-500 border rounded p-4 space-y-2"
                                                         dangerouslySetInnerHTML={{__html: convertNewLinesToHtml(soapNoteJson.plan || '-')}}/>
                                                    <div className='flex justify-end gap-2 pt-3'>
                                                        {isPhysicianSoapNoteEditEnabled && <button className="text-xs"
                                                                                                   onClick={() => handleEditClick('plan')}>
                                                            <img src={EditIcon} width={20} height={20}/>
                                                        </button>}
                                                        <CopyButton content={soapNoteJson.plan}/>
                                                    </div>
                                                </>
                                            )}
                                        </AccordianCard>
                                        <div className="h-[80px]"></div>
                                    </div> : <>
                                        <div className='bg-white p-4 ml-4 rounded'>
                                            <div className='bg-orange-50 p-4 rounded flex'>
                                                <div className=''><img src={WarnIcon} width={30} height={30}/></div>
                                                <div className='pl-2'>
                                                    <div className='text-sm'>SOAP was not captured</div>
                                                    <div className='text-xs pt-2 p text-gray-500'>
                                                        A conversation with the patient was recorded, but we couldn't
                                                        identify relevant content to generate a SOAP note for display.
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </>
                            }
                        </div>
                        :
                        <div>
                            {
                                SOAPStatus === 'inprogress' ?
                                    <div className='bg-white p-4 ml-4 rounded'>
                                        <div className='bg-blue-100 p-4 rounded flex'>
                                            <div className=''><img src={PendingIcon} width={30} height={30}/></div>
                                            <div className='pl-2'>
                                                <div className='text-sm'>Your SOAP is being generated</div>
                                                <div className='text-xs pt-2 p text-gray-500'>
                                                    Your SOAP note for this patient is being generated and will
                                                    automatically load on this page once complete. Thank you for your
                                                    patience.
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    <div className='bg-white p-4 ml-4 rounded'>
                                        <div className='bg-red-50 p-4 rounded flex'>
                                            <div className=''><img src={ErrorWarningIcon} width={30} height={30}/></div>
                                            <div className='pl-2'>
                                                <div className='text-sm'>Error - SOAP couldn’t be generated</div>
                                                <div className='text-xs pt-2 p text-gray-500'>We encountered a technical
                                                    issue and couldn't generate the SOAP note. We apologise for any
                                                    inconvenience caused.
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                            }
                        </div>
                    }
                    {isSOAPFeedBackEnabled && selectedNoteId && <SOAPFeedbackComponent soapNoteId={selectedNoteId}/>}
                </div>}
            <ConfirmationModal
                isOpen={openReasonForVistModal}
                onClose={() => setOpenReasonForVistModal(false)}
                continueButtonName={editSoapNoteReasonInProgress ? 'Saving...' : 'Confirm Changes'}
                disableConfirmButton={editSoapNoteReasonInProgress}
                onConfirm={handleReasonForVisitSave}
                modalClassNames="w-[600px]"
                shouldCloseOnOverlayClick={false}
            >
                <div className="flex flex-col gap-4">
                    <div className='flex gap-1 align-items-center'>
                        <div className='text-md'>{selectedPatient.firstName} {selectedPatient.lastName}</div>
                        <div className='text-xs text-gray-500'>
                            ({DateTime.fromISO(selectedPatient?.dob.toString(), {zone: 'utc'}).toFormat('MM/dd/yyyy')})
                        </div>
                    </div>
                    <div className='text-start'>
                        <div className='text-sm'>Purpose of Visit</div>
                        <div className='text-xs text-gray-500 pt-2'>To differentiate between various SOAP notes for the
                            patient, you can edit the current purpose of visit in few words to accurately reflect this
                            session.
                        </div>
                    </div>
                    <input className="border rounded p-2"
                           value={reasonForVisit} onChange={(e) => setReasonForVisit(e.target.value)}/>

                </div>
            </ConfirmationModal>
        </div>
    )
}

export default SoapNoteListView;
