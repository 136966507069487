import { useDispatch, useSelector } from "react-redux";
import { StateParams } from "../../../store/reducers";
import { useParams } from "react-router-dom";
import ViewSessionNotes from "./ViewSessionNotes";
import { use } from "chai";
import { useEffect, useState } from "react";
import { physicianFetchScreeningSessionNotes } from "../../../store/actions/physician/patients.action";
import _ from "lodash";
import { useFlags } from "flagsmith/react";

const SessionNotes = () => {
    const { screenerSessionId, screeningSessionNotes, screenedDates } = useSelector((state: StateParams) => state.physicianPatients);
    const [sessionId, setSessionId] = useState<string | undefined>(screenerSessionId || _.first(screenedDates)?.sessionId);
    const params = useParams()
    const dispatch = useDispatch();
    const isCCNoteEnabled = useFlags(['is_cc_note_enabled']).is_cc_note_enabled.enabled;

    useEffect(() => {
        if (params.patientId && sessionId) {
            dispatch(physicianFetchScreeningSessionNotes(params.patientId, sessionId))
        }
    }, [])

    if (!isCCNoteEnabled) {
        return null;
    }

    return (
        <div className="mt-4">
            {sessionId && params.patientId && <ViewSessionNotes screeningSessionId={sessionId} patientId={params.patientId} />}
        </div>
    )
};

export default SessionNotes;