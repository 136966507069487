import { textarea } from "@material-tailwind/react";
import React, { useCallback, useEffect, useRef } from "react";

interface TextAreaProps {
    value: string;
    onChange?: (value: string) => void;
    placeholder: string;
    maxHeight?: number;
    isDisabled?: boolean;
}

const TextAreaComponent: React.FC<TextAreaProps> = ({ value, onChange, placeholder, maxHeight = 300, isDisabled = false}) => {
    
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        onChange && onChange(value);
    }, [onChange]);

    const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const textarea = e.target;
        textarea.style.height = "auto";
        const newHeight = Math.min(textarea.scrollHeight, maxHeight) + "px";
        textarea.style.height = newHeight;
    };

    useEffect(() => {
        if(textareaRef.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = Math.min(textareaRef.current.scrollHeight, maxHeight) + "px";
        }
    },[value,maxHeight])

    return (
        <textarea
            disabled= {isDisabled}
            ref={textareaRef}
            className={`w-full border rounded-md p-2 resize-none overflow-y-auto h-100 min-h-[80px]`}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            onInput={handleInput}
        />
    );
};

export default TextAreaComponent;
