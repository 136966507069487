import React, {useMemo} from "react";
import {IPotentialReferralPatient, LanguageCodeDescription} from "../../../models";
import {DateTime} from "luxon";
import TablePaginationV2 from "../../generic/TablePaginationV2";
import { CallStatus } from "../../generic/CallStatus";
import {Link} from "react-router-dom";
import {Button} from "../../generic/Buttons";
import { RoutePaths} from "../../../shared/Utils";
import { YesNoPill } from "../../generic/YesNoPill";

interface PatientsProps {
    patients: IPotentialReferralPatient[],
    searchInProgress: boolean | undefined
    statusFilters: string[]
}

const PotentialReferrals : React.FC<PatientsProps> = ({patients, searchInProgress, statusFilters}) => {
    const data = patients.map((patient) => {
        const practice = patient.screenerUserPractices[0].providerPractice;
        const screeningDate = patient.screeningDate;
        const updatedAt = DateTime.fromISO(patient.updatedAt, {zone: "America/New_York"}).toFormat('MM/dd/yyyy, hh:mm a')
        const suicidalScreener = patient.userScreenerSessions?.[0]?.userScreeners
        const isPatientAtRisk = suicidalScreener?.some(userScreener => userScreener.isComplete === false || (userScreener.score?.totalScore?.yes || 0) >= 1)
        return {
            id: patient.id,
            name: `${patient.firstName} ${patient.lastName}`,
            dateOfBirth: DateTime.fromISO(patient.dob, {zone: 'utc'}).toFormat('MM/dd/yyyy'),
            screeningDate:  screeningDate ? DateTime.fromISO(screeningDate, {zone: 'utc'}).toFormat('MM/dd/yyyy') : "N/A",
            practiceName: practice.name,
            patientDetailsWithPhoneNumber: patient,
            callStatus: patient.callStatus,
            preferredLanguage: LanguageCodeDescription[patient.preferredLanguage] || "English",
            updatedAt: updatedAt,
            isPatientAtRisk: isPatientAtRisk ? "Yes" : "No"
        }
    });
    
    const columns = useMemo(
        () => [
            { header: "Name", accessorKey: "name" },
            { header: "Date of Birth", accessorKey: "dateOfBirth" },
            { header: "Screening Date", accessorKey: "screeningDate"},
            { header: "Practice", accessorKey: "practiceName" },
            { header: "Status", accessorKey: "callStatus", cell: (props: any) => <CallStatus value={props.getValue()}/> },
            { header: "Suicidal Ideation", accessorKey: "isPatientAtRisk", cell: (props:any) => <YesNoPill value={props.getValue()} /> },
            { header: "Preferred Language", accessorKey: "preferredLanguage" },
            { header: "Last Updated", accessorKey: "updatedAt" },
            {header: "", accessorKey: "id", cell: (props: any)=>  {
                   const patientId = props.getValue();
                   return <>
                        <Link to={RoutePaths.careCoordinator.potentialPatients.patientDetails.root.replace(":patientId", patientId)}>
                            <Button className={"!bg-sjOrange !text-white !ml-2"}>View Details</Button>
                        </Link>
                    </>
                }
            }
        ],
        []
    );

    return <>
        <div>
            <TablePaginationV2 columns={columns} data={data} searchInProgress={searchInProgress} possibleColumnsToHide={{callStatus: statusFilters?.length ? false : true}}/>
        </div>
    </>
}

export default PotentialReferrals
