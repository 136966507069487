import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAppointmentWithTherapist, IProspect, PatientSessionStatus, PatientSessionStatusType, Roles, SessionTypes } from "../../../models"
import { StateParams } from "../../../store/reducers";
import { AppointmentTimer } from "../../generic/AppointmentTimer";
import { PageButtonV2 } from "../../generic/Buttons";
import CalendarIconGreen from "../../../assets/images/common/calendarGreen.svg"
import CalendarIconWhite from "../../../assets/images/common/calendarWhite.svg"
import MenuIcon from "../../../assets/images/common/menu.svg"
import { Tooltip } from "react-tooltip";
import ConfirmAppointmentStatusModal from "./ConfirmAppointmentStatusModal";
import { useNavigate } from "react-router-dom";

interface Props {
    scheduledSession: IAppointmentWithTherapist
    prospect: IProspect
    setIsModalOpen: (isModalOpen: boolean) => void
}

const IntakeScheduledButton: React.FC<Props> = ({ prospect, scheduledSession, setIsModalOpen }) => {
    const navigate = useNavigate()
    const { role } = useSelector((state: StateParams) => state.practitionerAccount)
    const { session } = useSelector((state: StateParams) => state.appointments)
    const [seconds, setSeconds] = useState(0);
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0);
    const [isOpen, setIsOpen] = useState(false)
    const [canShowAppointmentConfirmationModal, setCanShowAppointmentConfirmationModal] = useState(false)

    const sessionDate = DateTime.fromISO(scheduledSession.scheduledDate, { zone: "America/New_York" }).toFormat('MM/dd/yyyy, hh:mm a')
    const isTimeLeft = (hours > 0 || minutes > 0 || seconds > 0) || role === Roles.Care_Coordinator;
    const buttonClass = isTimeLeft ? 'text-sjDarkGreen border-sjDarkGreen bg-white' : 'bg-sjDarkGreen text-white'
    const validToStartAppointment = seconds < 0;
    const modality = session && session.modality.toString().replace(/^\w/, (c) => c.toUpperCase())

    const handleButtonClick = () => {
        if (!isTimeLeft && scheduledSession.sessionType === SessionTypes.Intake) {
            setCanShowAppointmentConfirmationModal(true)
        }
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const handleOptionClick = () => {
        setIsOpen(false);
        setIsModalOpen(true)
    }

    const  renderToolTipForAppointment = () => {
        let formattedTimeLeft = "";

        if (hours > 0) {
            formattedTimeLeft += `${hours} hour${hours !== 1 ? 's' : ''}`;
        }

        if (minutes > 0) {
            if (formattedTimeLeft !== "") {
                formattedTimeLeft += ", ";
            }
            formattedTimeLeft += `${minutes} minute${minutes !== 1 ? 's' : ''}`;
        }

        if (hours==0 && minutes==0 && seconds > 0 || formattedTimeLeft === "") {
            if (formattedTimeLeft !== "") {
                formattedTimeLeft += ", ";
            }
            formattedTimeLeft += `${seconds} second${seconds !== 1 ? 's' : ''}`;
        }
        return <Tooltip style={{borderRadius: 5, zIndex: 9999}} id="appointment-tooltip" >
            <div className={"w-[200px]"}>
                <span>
                    {`Click after ${formattedTimeLeft} to Start Appointment.`}
                </span>
            </div>
        </Tooltip>
    }

    const renderCancelActionButton = () => {
        return (
            <div className="relative content-center">
                <img src={MenuIcon} width={20} height={14} className={"hover:cursor-pointer"} onClick={toggleMenu} />
                {isOpen && (
                    <ul className="absolute mt-[12px] left-[-25px] bg-white border-2 border-sjLightGray rounded-md p-[12px] hover:cursor-pointer">
                        <li className="text-sjTestPositive" onClick={() => handleOptionClick()}>Cancel</li>
                    </ul>
                )}
            </div>
        )
    }

    return (
        <>
            <PageButtonV2
                className={`${buttonClass} ml-2 px-[16px] py-[8px] h-8 text-sm border`}
                disabled={isTimeLeft}
                onClick={handleButtonClick}
                data-tooltip-id="appointment-tooltip"
            >
                <div className={"flex flex-row gap-x-2"}>
                    <span>{modality} - {sessionDate}</span>
                    <img src={isTimeLeft ? CalendarIconGreen : CalendarIconWhite} width={14} height={14} />
                </div>
            </PageButtonV2>
            {validToStartAppointment || role === Roles.Care_Coordinator
                ? null
                : renderToolTipForAppointment()
            }
            <AppointmentTimer scheduledTime={scheduledSession.scheduledDate} setHours={setHours} setMinutes={setMinutes} setSeconds={setSeconds} />
            {role === Roles.Care_Coordinator && renderCancelActionButton()}
            {session && <ConfirmAppointmentStatusModal prospect={prospect} setCanShowAppointmentConfirmationModal={setCanShowAppointmentConfirmationModal} canShowAppointmentConfirmationModal={canShowAppointmentConfirmationModal} session={session}/>}
        </>
    )
}

export default IntakeScheduledButton