
import { Calendar, momentLocalizer } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment'
import { CalendarEvent, CalendarWrapper } from '../../components/calendar/CalendarWrapper';


const calendarEvents: any[] = []
const localizer = momentLocalizer(moment)
const myEventsList: any[] = [
    {
        
        title: "Product Launch Meeting",
        type: 'catch-up',
        modality: 'phone',
        start: new Date(2025, 2, 10, 10, 0), // March 10, 2025, 10:00 AM
        end: new Date(2025, 2, 10, 11, 30),  // March 10, 2025, 11:30 AM
    },
    {
        title: "Client Demo",
        type: 'treatment',
        modality: 'video',
        start: new Date(2025, 2, 15, 14, 0), // March 15, 2025, 2:00 PM
        end: new Date(2025, 2, 15, 15, 0),   // March 15, 2025, 3:00 PM
    },
    {
        title: "Sales Team Strategy Session",
        type:'intake',
        modality: 'video',
        start: new Date(2025, 2, 22, 9, 0),  // March 22, 2025, 9:00 AM
        end: new Date(2025, 2, 22, 10, 0),  // March 22, 2025, 10:30 AM
    },
    {
        title: " Client Strategy Session",
        type:'treatment',
        modality: 'video',
        start: new Date(2025, 2, 22, 10, 0),  // March 22, 2025, 9:00 AM
        end: new Date(2025, 2, 22, 11, 30),  // March 22, 2025, 10:30 AM
    },
    {
        title: "Strategy Session",
        type:'progress',
        start: new Date(2025, 2, 22, 12, 0),  // March 22, 2025, 9:00 AM
        end: new Date(2025, 2, 22, 13, 30),  // March 22, 2025, 10:30 AM
    },
    {
        title: "Board Meeting",
        type: 'catchUp',
        modality: 'phone',
        start: new Date(2025, 3, 5, 16, 0),  // April 5, 2025, 4:00 PM
        end: new Date(2025, 3, 5, 17, 30),   // April 5, 2025, 5:30 PM
    },
    {
        title: "Annual Performance Review",
        type: 'progress',
        modality: 'video',
        start: new Date(2025, 3, 12, 13, 0), // April 12, 2025, 1:00 PM
        end: new Date(2025, 3, 12, 14, 30),  // April 12, 2025, 2:30 PM
    },
    {
        title: "Networking Event",
        type:'treatment',
        modality: 'video',
        start: new Date(2025, 3, 18, 18, 0), // April 18, 2025, 6:00 PM
        end: new Date(2025, 3, 18, 20, 0),   // April 18, 2025, 8:00 PM
    },
    {
        title: "Team Building Workshop",
        type: 'catchUp',
        modality: 'phone',
        start: new Date(2025, 3, 25, 10, 0), // April 25, 2025, 10:00 AM
        end: new Date(2025, 3, 25, 12, 30),  // April 25, 2025, 12:30 PM
    },
];


const calendarAppointmentsScreen = () => {
    return (
        <div className="w-full p-4 h-full overflow-hidden">
            <div className={"flex justify-between pb-4 items-center"}>
                <h1 className="text-xl">Calendar Appointments</h1>
            </div>
            <>
                <CalendarWrapper
                    events={myEventsList}
                    date={new Date()}
                    onSelectSlot={(slotInfo) => { 
                        console.log(slotInfo)
                    }}
                />
            </>
        </div>
    )
}

export default calendarAppointmentsScreen