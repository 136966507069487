import React, {useCallback, useEffect, useMemo} from "react";
import {IPhysicianAppointmentListItem} from "../../models";
import TablePaginationV2 from "../../components/generic/TablePaginationV2";
import {DateTime} from "luxon";
import PhysicianAppointmentPatientCallStatusComponent
    from "./components/PhysicianAppointmentPatientCallStatusComponent";
import PhysicianAppointmentPatientCallResponseComponent
    from "./components/PhysicianAppointmentPatientCallResponseComponent";
import {useDispatch, useSelector} from "react-redux";
import {
    resetUpdatePhysicianAppointment,
    updatePhysicianAppointment
} from "../../store/actions/care-coordinator/patients.action";
import {StateParams} from "../../store/reducers";
import {toast} from "react-toastify";
import PhysicianAppointmentCallPatientComponent from "./components/PhysicianAppointmentCallPatientComponent";
import PhysicianAppointmentInfoComponent from "./components/PhysicianAppointmentInfoComponent";
import PhysicianAppointmentSendSMSComponent from "./components/PhysicianAppointmentSendSMSComponent";
import {PhysicianAppointmentListItemContextMenu} from "./components/PhysicianAppointmentListItemContextMenu";
import PhysicianAppointmentScreeningStatusComponent from "./components/PhysicianAppointmentScreeningStatusComponent";

interface IPhysicianAppointmentListComponentProps {
    physicianAppointmentList: IPhysicianAppointmentListItem[];
    searchInProgress: boolean | undefined;
    refreshPhysicianAppointmentList: () => void;
}

const PhysicianAppointmentListComponent = (props: IPhysicianAppointmentListComponentProps) => {

    const dispatch = useDispatch();
    const {
        updatePhysicianAppointmentSuccess,
        updatePhysicianAppointmentError
    } = useSelector((state: StateParams) => state.coordinatorPatients);

    const {physicianAppointmentList, refreshPhysicianAppointmentList, searchInProgress} = props;

    useEffect(() => {
        if (updatePhysicianAppointmentError) {
            toast(updatePhysicianAppointmentError);
            dispatch(resetUpdatePhysicianAppointment());
        }
        if (updatePhysicianAppointmentSuccess) {
            toast.dismiss();
            toast(`Appointment updated successfully`);
            dispatch(resetUpdatePhysicianAppointment());
        }
    }, [updatePhysicianAppointmentSuccess, updatePhysicianAppointmentError, dispatch]);

    const updateAppointment = useCallback((appointmentId: string, key: string, value: string | boolean) => {
        dispatch(updatePhysicianAppointment(appointmentId, {[key]: value}));
    }, [dispatch]);

    const data = physicianAppointmentList ? physicianAppointmentList?.map((item) => {
        const dob = DateTime.fromISO(item?.patient?.dob, {zone: 'utc'}).toFormat('MM/dd/yyyy');
        return {
            id: item.id,
            name: {
                name: `${item?.patient?.firstName} ${item?.patient?.lastName}`,
                dob: dob,
            },
            practiceName: `${item?.practice?.name}`,
            appointmentTimestamp: DateTime.fromISO(item?.appointmentDate, {zone: "America/New_York"}).toFormat('MM/dd/yyyy, hh:mm a'),
            appointmentScreeningStatus: {
                appointmentId: item.id,
                appointmentScreeningStatus: item.appointmentScreeningStatus
            },
            spokenWithPatient: {
                appointmentId: item.id,
                isSpokenWithPatient: item.isSpokenWithPatient
            },
            appointmentCallResponse: {
                appointmentId: item.id,
                appointmentCallResponse: item.appointmentCallResponse
            },
            appointmentAction: {
                appointmentId: item.id,
                appointmentDate: item.appointmentDate,
                appointmentCallResponse: item.appointmentCallResponse,
                patient: item.patient,
                practice: item.practice,
                location: item.location,
                provider: item.provider,
            },
            appointmentContextMenu: {
                appointmentId: item.id,
                appointmentDate: item.appointmentDate,
                patient: item.patient,
                practice: item.practice,
                provider: item.provider,
            }
        }
    }) : [];

    const columns = useMemo(
        () => [
            {
                header: "Name", accessorKey: "name", cell: (props: any) => <div className="flex flex-col">
                    <div>{props.getValue().name}</div>
                    <div>({props.getValue().dob})</div>
                </div>
            },
            {header: "Practice", accessorKey: "practiceName"},
            {header: "Latest Appointment (EST)", accessorKey: "appointmentTimestamp"},
            {
                header: "Called Patient", accessorKey: "spokenWithPatient",
                cell: (props: any) => <PhysicianAppointmentPatientCallStatusComponent
                    updateAppointmentPatientCallStatus={(appointmentId: string, isSpokenWithPatient: boolean) => {
                        updateAppointment(appointmentId, 'isSpokenWithPatient', isSpokenWithPatient);
                    }}
                    value={props.getValue()}/>
            },
            {
                header: "Call Response", accessorKey: "appointmentCallResponse",
                cell: (props: any) => <PhysicianAppointmentPatientCallResponseComponent
                    updateAppointmentPatientCallResponse={(appointmentId: string, appointmentCallResponse: string) => {
                        updateAppointment(appointmentId, 'appointmentCallResponse', appointmentCallResponse);
                    }}
                    value={props.getValue()}/>
            },
            {
                header: "Screening Status", accessorKey: "appointmentScreeningStatus",
                cell: (props: any) => <PhysicianAppointmentScreeningStatusComponent
                    updateAppointmentPatientScreeningStatus={(appointmentId: string, appointmentScreeningStatus: string) => {
                        updateAppointment(appointmentId, 'appointmentScreeningStatus', appointmentScreeningStatus);
                    }}
                    value={props.getValue()}/>
            },
            {
                header: "", accessorKey: "appointmentAction",
                cell: (props: any) => <div className="flex gap-1">
                    <PhysicianAppointmentCallPatientComponent
                        fetchPhysicianAppointmentList={refreshPhysicianAppointmentList}
                        value={props.getValue()}/>
                    <PhysicianAppointmentSendSMSComponent
                        value={props.getValue()}/>
                    <PhysicianAppointmentInfoComponent
                        value={props.getValue()}/>
                </div>
            },
            {
                header: "", accessorKey: "appointmentContextMenu",
                cell: (props: any) => <PhysicianAppointmentListItemContextMenu
                    refreshPhysicianAppointmentList={refreshPhysicianAppointmentList}
                    value={props.getValue()}
                />
            },
        ], [updateAppointment, refreshPhysicianAppointmentList]);

    return (
        <div className='mt-4 w-full'>
            <TablePaginationV2 columns={columns} data={data} searchInProgress={searchInProgress}/>
        </div>
    )
}

export default PhysicianAppointmentListComponent;
