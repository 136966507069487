import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StateParams } from "../../../../store/reducers";
import { RoutePaths } from "../../../../shared/Utils";
import DataLabelComponent from "../../../../components/generic/DataLabel";
import { LanguageCodeDescription } from "../../../../models";

interface ReferredPatientInformationProps {

}

const ReferredPatientInformationComponent = (props: ReferredPatientInformationProps) => {
    const navigate = useNavigate();
    const { prospect } = useSelector((state: StateParams) => state.prospects)
    const physician = prospect?.referredByPhysicianFirstName + " " + prospect?.referredByPhysicianLastName;
    const patientLocation = useSelector((state: StateParams) => state.patients.patientLocation)


    const handleEvent = useCallback(() => {
        navigate(`${RoutePaths.careCoordinator.ccReferredPatients.root}?page=1`)
    }, [navigate]);

    useEffect(() => {
        window.addEventListener("popstate", handleEvent);
        return () => window.removeEventListener("popstate", handleEvent);
    });

    return (
        <>
            <div className="patient-information-wrapper">
                <div className="patient-information-card">
                    <div className="patient-information-section">
                        <div className="patient-information-section-title">Patient Information</div>
                        <div className="patient-information-section-content">
                            <div className="grid grid-cols-3 gap-6">
                                <DataLabelComponent label={"Date of Birth"}>
                                    {prospect?.patient?.dob ? DateTime.fromISO(prospect?.patient?.dob.toString(), { zone: 'utc' }).toFormat('MM/dd/yyyy') : "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Gender"}>
                                    {prospect?.patient?.gender ? <span className="capitalize"> {prospect?.patient?.gender} </span> : "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Patient Email ID"}>
                                    {prospect?.patient?.email || "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Patient Phone Number"}>
                                    {prospect?.patient?.contactPhoneNumber || "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Patient Alternate Number"}>
                                    {prospect?.patient?.alternatePhoneNumber || "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Preferred Language"}>
                                    {prospect?.patient?.preferredLanguage && LanguageCodeDescription[prospect?.patient?.preferredLanguage] || "English"}
                                </DataLabelComponent>
                                <DataLabelComponent label="Emergency Contact Name">
                                    {
                                        prospect?.patient?.guardianFirstName || prospect?.patient?.guardianLastName
                                            ? `${prospect?.patient?.guardianFirstName} ${prospect?.patient?.guardianLastName}`
                                            : "N/A"
                                    }
                                </DataLabelComponent>
                                <DataLabelComponent label="Relation">
                                    {prospect?.patient?.relationshipWithPatient || "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label="Emergency Contact Number">
                                    {prospect?.patient?.guardianPhoneNumber || "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Zip Code"}>
                                    {prospect?.patient?.zipCode || "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Address"}>
                                    <div className="break-all overflow-hidden overflow-ellipsis whitespace-pre-wrap">
                                        {prospect?.patient?.address || "N/A"}
                                    </div>
                                </DataLabelComponent>
                            </div>
                        </div>
                    </div>
                    <div className="patient-information-section">
                        <div className="patient-information-section-title">Physician Information</div>
                        <div className="patient-information-section-content">
                            <div className="grid grid-cols-3 gap-6">
                                <DataLabelComponent label={"Practice"}>
                                    {prospect?.referredByPracticeName || "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Screened Location"}>
                                    {patientLocation?.name || ""} , {patientLocation?.addressLine || ""} {patientLocation?.city || ""} {patientLocation?.state || ""}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Physician"}>
                                    {physician || "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Tax ID"}>
                                    {prospect?.taxId || "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Physician NPI"}>
                                    {prospect?.patientStatuses[0]?.provider?.npi || "N/A"}
                                </DataLabelComponent>

                                <DataLabelComponent label={"Medicaid ID"}>
                                    {prospect?.patientStatuses[0]?.provider?.medicaidId || "N/A"}
                                </DataLabelComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ReferredPatientInformationComponent;
