import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import VoipPhoneNumber from "../../../components/calls/VoipPhoneNumber";
import { InsuranceStatus, IPatientInCall, IPotentialReferralPatientBasicDetails, PatientCallStatus } from "../../../models";
import { CALL_STATUS_DISPLAY_MAP } from "../../../components/generic/CallStatus";
import { updatePatientInfo } from "../../../store/actions/counsellor/patients.action";
import EditablePhoneNumber from "../../../components/calls/EditablePhoneNumber";
import CallPatientComponent from "../../../components/calls/CallPatientComponent";
import { useParams } from "react-router-dom";
import { useFlags } from "flagsmith/react";
import InsuranceStatusAction from "../../../components/prospects/InsuranceStatusAction";
import { fetchScreeningSessionNotes, setScreeningSessionId } from "../../../store/actions/care-coordinator/patients.action";

const CoordinatorPatientProfile: React.FC<{ patient: IPotentialReferralPatientBasicDetails }> = ({ patient }) => {
    const dispatch = useDispatch();
    const isPatientInsuranceEnabled = useFlags(['cc_patient_insurance'])?.cc_patient_insurance?.enabled;
    const practice = patient.screenerUserPractices[0].providerPractice;
    const [selectedStatus, setSelectedStatus] = useState<PatientCallStatus>(patient.callStatus)
    const patientInsuranceStatus = patient.insuranceStatus
    const [insuranceStatus, setInsuranceStatus] = React.useState<InsuranceStatus>(patientInsuranceStatus as InsuranceStatus)

    const potentialPatient: IPatientInCall = {
        id: patient.id,
        sourcePatientId: patient.id,
        referredByPracticeId: patient.screenerUserPractices[0].practiceId,
        firstName: patient.firstName,
        lastName: patient.lastName,
        dob: patient.dob as unknown as Date,
        phoneNumber: patient.contactPhoneNumber,
    }

    useEffect(() => {
        if (patient?.userScreenerSessions.length > 0 && patient?.userScreenerSessions[0].id) {
            dispatch(setScreeningSessionId(patient?.userScreenerSessions[0].id || ''))
            dispatch(fetchScreeningSessionNotes(patient.id, patient?.userScreenerSessions[0].id));
        }
    }, [])

    useEffect(() => {
        if (patientInsuranceStatus) {
            setInsuranceStatus(patientInsuranceStatus as InsuranceStatus);
        }
    }, [patientInsuranceStatus])

    console.log("CoordinatorPatientProfile.tsx :: patient :: ", patient);

    const sourcePatientId = useParams().patientId || patient.id;

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const status = event.target.value as unknown as PatientCallStatus
        setSelectedStatus(status);
        dispatch(updatePatientInfo(patient.id, patient.screenerUserPractices[0].practiceId, { callStatus: status }))
    };

    return (
        <section className={"patient-details-screen"}>
            <div className="patient-basic-details-card flex flex-row items-end justify-between">
                <div className="flex items-center">
                    <div className="patient-basic-details-meta">
                        <div><EditablePhoneNumber patient={potentialPatient} /></div>
                        <div className="flex mb-[5px] mt-4 gap-8">
                            <div className="">
                                <label htmlFor="status" className="text-sjGray text-sm">Patient Status</label><br />
                                <select value={selectedStatus as unknown as keyof PatientCallStatus} onChange={handleChange}
                                    className='w-[153px] px-[8px] py-[4px] border-none rounded-[3px] h-[30px] bg-sjLightOrange text-sjOrange'>
                                    {(Object.keys(CALL_STATUS_DISPLAY_MAP) as (keyof PatientCallStatus)[]).map((status) => (
                                        <option key={status} value={status}>
                                            {CALL_STATUS_DISPLAY_MAP[status].description}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {isPatientInsuranceEnabled &&
                                <div>
                                    <label htmlFor="status" className="text-sjGray text-sm">Insurance Status</label>
                                    <InsuranceStatusAction
                                        disabled={patient?.insuranceAccounts?.length === 0}
                                        setInsuranceStatus={(status) => {
                                            setInsuranceStatus(status)
                                        }}
                                        patient={
                                            {
                                                id: patient.id,
                                                insuranceStatus: insuranceStatus as InsuranceStatus,
                                            }
                                        }
                                    />
                                </div>}
                        </div>
                    </div>
                </div>
                <div className="patient-basic-details-actions">
                    <CallPatientComponent patient={potentialPatient} sourcePatientId={sourcePatientId} />
                </div>
            </div>
        </section>
    )
}

export default CoordinatorPatientProfile
