import {Control, Controller, useFieldArray, UseFormGetValues, UseFormRegister, UseFormWatch} from "react-hook-form";
import {Radio, Textarea} from "@material-tailwind/react";
import {Inputs} from "./ProgressNotes";
import TextAreaComponent from "../../generic/TextAreaComponent";

const WeeklyGuidance: React.FC<{
    control: Control<Inputs, any>, register: UseFormRegister<Inputs>,
    setValue: any, getValues: UseFormGetValues<Inputs>, watch: UseFormWatch<Inputs>,
}> = ({ control, register, setValue, getValues, watch }) => {
    const { fields: weeklyGuidanceFields, append: weeklyGuidanceAppend, remove: weeklyGuidanceRemove } = useFieldArray({
        control,
        name: "weeklyGuidance"
    });

    const { fields: lastWeekGuidanceFields, append: lastWeekGuidanceAppend, remove: lastWeekGuidanceRemove } = useFieldArray({
        control,
        name: "lastWeekGuidance"
    });

    const handleAppend = (value: any) => {
        weeklyGuidanceAppend(value)
    };

    const handleDelete = (index: number) => {
        weeklyGuidanceRemove(index)
    }

    return (
        <div>
            {watch("lastWeekGuidance") && <div>
                <label className="block text-lg mt-3 text-sjOrange uppercase">
                    Last week GUIDANCE
                </label>
                {lastWeekGuidanceFields.map((item, index) => {
                    return (
                        <div key={item.id}>
                                <Controller
                                name={`lastWeekGuidance.${index}.weeksGuidance`}
                                control={control}
                                defaultValue=""
                                render={({field}) => (
                                    <TextAreaComponent
                                    value={field.value}
                                    onChange={field.onChange}
                                    placeholder=""
                                    />
                                )}
                                />
                            <div className="grid grid-cols-3 grid-flow-row gap-2">
                                <Radio {...register(`lastWeekGuidance.${index}.completionStatus`)} className="text-sjOrange" label="Not Completed" value="not_completed" />
                                <Radio {...register(`lastWeekGuidance.${index}.completionStatus`)} className="text-sjOrange" label="Partially Completed" value="partially_completed" />
                                <Radio {...register(`lastWeekGuidance.${index}.completionStatus`)} className="text-sjOrange" label="Completed" value="completed" />
                            </div>
                        </div>
                    )
                })}
            </div>}
            <label className="block text-lg mt-3 text-sjOrange uppercase">
                Current week GUIDANCE
            </label>
            {weeklyGuidanceFields.map((item, index) => {
                return (
                    <div key={item.id}>
                        <div className="flex justify-between mt-3">
                            <label className="block text-lg uppercase"
                                key={`label-${index}`}>Guidance {index + 1}:</label>
                            <button className={`pl-2.5 pr-2.5 pt-1 pb-1 text-sm bg-sjWhite 
                                rounded-md text-sjOrange border-2 border-solid border-sjOrange`}
                                onClick={() => handleDelete(index)}
                            >
                                Delete
                            </button>
                        </div>
                            <div className="mt-4">
                            <Controller
                                name={`weeklyGuidance.${index}.weeksGuidance`}
                                control={control}
                                defaultValue=""
                                render={({field}) => (
                                    <TextAreaComponent
                                    value={field.value}
                                    onChange={field.onChange}
                                    placeholder=""
                                    />
                                )}
                                />
                                </div>
                        <div className="grid grid-cols-3 grid-flow-row gap-2">
                            <Radio {...register(`weeklyGuidance.${index}.completionStatus`)} className="text-sjOrange" label="Not Completed" value="not_completed" />
                            <Radio {...register(`weeklyGuidance.${index}.completionStatus`)} className="text-sjOrange" label="Partially Completed" value="partially_completed" />
                            <Radio {...register(`weeklyGuidance.${index}.completionStatus`)} className="text-sjOrange" label="Completed" value="completed" />
                        </div>
                    </div>
                )
            })}
            <section>
                <button className={"p-2.5 text-sm bg-sjOrange rounded-md text-sjWhite border-solid border-l-sjOrange"}
                    onClick={() => handleAppend({ weeksGuidance: '', completionStatus: '' })}
                >
                    Add this week's guidance
                </button>
            </section>
        </div>
    )
};

export default WeeklyGuidance