import React from "react";
import {classNames} from "../../shared/Utils";
import CSS from 'csstype';
import { PatientCallStatus, ReferredPatientCallStatus } from "../../models";

export type CallMetadata = {
    description: string
    style?: CSS.Properties
}

export const CALL_STATUS_DISPLAY_MAP: Record<keyof PatientCallStatus, CallMetadata> = {
    pending: {description: "Pending", style: {backgroundColor: "bg-sjLightGray", color: "text-sjGray"}},
    no_answer: {description: "No Answer", style: {backgroundColor: "bg-sjLightOrange", color: "text-sjOrange"}},
    call_again: {description: "Call Again", style: {backgroundColor: "bg-sjLightOrange", color: "text-sjOrange"}},
    interested: {description: "Interested", style: {backgroundColor: "bg-sjLightGreen", color: "text-sjDarkGreen"}},
    not_interested: {description: "Not Interested", style: {backgroundColor: "bg-sjLightRed", color: "text-sjDarkRed"}},
}

export const REFERRED_CALL_STATUS_DISPLAY_MAP: Record<keyof ReferredPatientCallStatus, CallMetadata> = {
    pending: {description: "Pending", style: {backgroundColor: "bg-sjLightGray", color: "text-sjGray"}},
    scheduled: {description: "Scheduled", style: {backgroundColor: "bg-sjLightGreen", color: "text-sjDarkGreen"}},
    reschedule: {description: "Reschedule", style: {backgroundColor: "bg-sjLightGreen", color: "text-sjDarkGreen"}},
    call_again: {description: "Call Again", style: {backgroundColor: "bg-sjLightOrange", color: "text-sjOrange"}},
    no_answer: {description: "No Answer", style: {backgroundColor: "bg-sjLightOrange", color: "text-sjOrange"}},
    no_show: {description: "No Show", style: {backgroundColor: "bg-sjLightRed", color: "text-sjDarkRed"}},
    not_accepted: {description: "Not Accepted", style: {backgroundColor: "bg-sjLightRed", color: "text-sjDarkRed"}},
    in_progress: {description: "In Progress", style: {backgroundColor: "bg-sjLightGreen", color: "text-sjDarkGreen"}},
    not_interested: {description: "Not Interested", style: {backgroundColor: "bg-sjLightRed", color: "text-sjDarkRed"}},
}

export const CallStatus: React.FC<{value: keyof (PatientCallStatus | ReferredPatientCallStatus), isReferred?: boolean}> = ({ value, isReferred }) => {

    const statusInfo = isReferred ? REFERRED_CALL_STATUS_DISPLAY_MAP[value] : CALL_STATUS_DISPLAY_MAP[value];
    const { description, style } = statusInfo;

    return (
    <span
        className={classNames(
            `py-1 px-2 font-bold rounded-full shadow-sm ${style?.backgroundColor} ${style?.color}`
        )}
    >
      {description}
    </span>
);
}