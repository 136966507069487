import {
    Control,
    Controller,
    FieldErrorsImpl,
    useFieldArray,
    UseFormGetValues,
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch
} from "react-hook-form";
import {Inputs} from "./TreatmentPlan";
import {Textarea} from "@material-tailwind/react";
import TextAreaComponent from "../../generic/TextAreaComponent";


const screeners = [{ slug: 'fp-phq-9-above18', label: 'PHQ-9 (for Depression)' },
{ slug: 'fp-anxiety-above18', label: 'GAD-7 (for Anxiety)' },
{ slug: 'fp-depression-MFQ', label: 'MFQ - Parents (for Depression)' },
{ slug: 'fp-anxiety-SCARED', label: 'SCARED - Parents (for Anxiety)' },
{ slug: 'fp-adhd-12to18@predominantly-hyperactive-subtype@predominantly-inattentive-subtype', label: 'NICHQ Vanderbilt - Parents (for ADHD)' },
{ slug: 'fp-adhd-12to18@oppositional-defiant-disorder', label: 'NICHQ Vanderbilt - Parents (for ODD)' },
{ slug: 'fp-adhd-12to18@adhd-conduct-disorder', label: 'NICHQ Vanderbilt - Parents (for Conduct Disorder)' },
// { slug: 'cybocs', label: 'Cybocs (for OCD)' },
// { slug: 'pss', label: 'PSS (for Stress)' },
]

const TreatmentPlanGoals: React.FC<{
    control: Control<Inputs, any>, register: UseFormRegister<Inputs>,
    setValue: UseFormSetValue<Inputs>, getValues: UseFormGetValues<Inputs>, watch: UseFormWatch<Inputs>, errors: Partial<FieldErrorsImpl<Inputs>>,
    saveNotes: (key: keyof Inputs, answer: any) => void

}> = ({ control, register, setValue, getValues, watch, errors, saveNotes }) => {
    const allScreenerGoals = watch('goals')?.flatMap(goal => goal?.screeners).flatMap(x => x?.screener).filter(x => x)
    const { fields, append, remove } = useFieldArray({
        control,
        name: "goals"
    });

    const handleAppend = (value: any) => {
        append(value)
        saveNotes('goals', getValues().goals)
    };

    const handleDelete = (index: number) => {
        remove(index)
        saveNotes('goals', getValues().goals)
    }

    const handleScreenerSelection = (ev: any, goalIdx: number, screenerIdx: number) => {
        const isChecked = !!ev.target.value
        setValue(`goals.${goalIdx}.screeners.${screenerIdx}.checked`, isChecked)
    }

    const areMaxScreenersSelected = (goalIdx: number, screenerIdx: number) => {
        const screener = watch(`goals.${goalIdx}.screeners.${screenerIdx}.screener`)
        const isSelectingScreenerChecked = !!screener
        return allScreenerGoals.length >= 2 && !isSelectingScreenerChecked ? true : false
    }

    return (
        <div>
            {fields.map((item, index) => {
                return (
                    <div key={item.id}>
                        <div className="flex justify-between mt-3" key={`div1-${item.id}`}>
                            <label className="block text-lg text-sjOrange uppercase"
                                key={`label-${index}`}>Goal {index + 1}:</label>
                            <button className={`pl-2.5 pr-2.5 pt-1 pb-1 text-sm bg-sjWhite 
                                rounded-md text-sjOrange border-2 border-solid border-sjOrange`} key={`delete-${index}`}
                                onClick={() => handleDelete(index)} data-testid={`deleteGoalBtn${index}`}
                            >
                                Delete
                            </button>
                        </div>
                        <div className="my-2" key={`div2-${item.id}`}>
                                <Controller
                                    name={`goals.${index}.goal`}
                                    control={control}
                                    defaultValue=""
                                    render={({field}) =>(
                                        <TextAreaComponent
                                            value={field.value}
                                            onChange={field.onChange}
                                            placeholder=""
                        />
                        )}
                />
                        </div>
                        <span key={`span-${item.id}`}>Measurement tool (Update, select upto two screeners):</span>
                        <div className="grid grid-rows-3 grid-flow-col gap-2 mt-3" key={`screeners-${item.id}`}>
                            {screeners.map((screener, idx) =>
                                <label className="text-sm" key={`label-screener-${idx}-${item.id}`}>
                                    <input type="checkbox" {...register(`goals.${index}.screeners.${idx}.screener`, {
                                        onChange: (ev: any) => handleScreenerSelection(ev, index, idx),
                                        disabled: areMaxScreenersSelected(index, idx)
                                    })} data-testid={`goalScreener`}
                                        id={screener.slug} value={screener.slug} className={'text-sjOrange'} key={`${index}-${screener.slug}`}/>
                                    <span className="ml-1" key={`span-${index}-${item.id}`}>{screener.label}</span>
                                </label>
                            )}
                        </div>
                    </div>
                )
            })}
            {errors && errors.goals && <p className="error-msg" data-testid="screenerErrorMsg">{errors.goals?.message}</p>}
            <section>
                <button className={"p-2.5 text-sm bg-sjOrange rounded-md text-sjWhite border-solid border-l-sjOrange"}
                    onClick={() => handleAppend({ goal: '', screener: [] })} data-testid="addGoalBtn"
                >
                    Add Goal
                </button>
            </section>
        </div>
    )
};

export default TreatmentPlanGoals