import { Controller, useForm, useFieldArray } from "react-hook-form";
import { Radio, Textarea } from "@material-tailwind/react";
import icdCodes from "../../../assets/data/icd-codes.json";
import Select from "react-select";
import TreatmentPlanGoals from "./TreatmentPlanGoals";
import { useCallback, useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { clearToastNotesStatus, saveTreatmentNotes, setTreamentPlanScreeners } from "../../../store/actions/counsellor/patients.action";
import { StateParams } from "../../../store/reducers";
import _ from "lodash";
import { DateTime } from "luxon";
import { NotesStatus, SessionTypes } from "../../../models";
import ReactModal from "react-modal";
import { Button } from "../../generic/Buttons";
import { useStatusChange } from "../../../hooks/useStatusChange";
import { toast } from "react-toastify";
import CreatableSelect from "../../generic/CreatableSelect";
import { AiFillCloseCircle } from "react-icons/ai";
import medications from "../../../assets/data/medications"
import { canAccessPsychiatristNotes, canAddPsychiatristNotes, isCounsellorNotesLocked, isNotesLocked, isPsychiatrist } from "../accessControl";
import ConfirmationModal from "../ConfirmationModal";
import PsychiatristNotes from "./PsychiatristNotes";
import { clearNotesError } from "../../../store/actions/counsellor/prospects.action";
import Addendum from "../Addendum";
import { useNavigate } from "react-router-dom";
import TextAreaComponent from "../../generic/TextAreaComponent";

interface Props {
    patientId: string
    canEdit: boolean
    isModalOpen: boolean
    toggleModal: () => void
    notesId: string | null
    setShowSubmitConfirmationModal: (value: boolean) => void
    showSubmitConfirmationModal: boolean
    isAddendumAdded: boolean
    setIsAddendumAdded: (value: boolean) => void
}

interface Goals {
    goal: string
    screeners: { screener: string, checked: boolean }[]
    screener?: string[]
}

const keyMappings: { [K in keyof Partial<Inputs>]: string } = {
    newAddendum: 'newAddendum',
    goals: 'goals',
    proposedMedication: 'proposedMedication'
}
const determineKey = (name: string): keyof typeof keyMappings => {
    return keyMappings[name as keyof typeof keyMappings] as keyof typeof keyMappings || name as keyof typeof keyMappings;
}

export type Inputs = {
    reasonForUpdating: string;
    presentChiefComplaint: string
    mentalBehavioral: any
    socialDeterminants: any
    targetDate: string
    frequency: string
    interventions: string
    proposedMedication: { medicine: string, type: string, dosage: string, frequency: string, details: string}[]
    proposedPlan: string
    clinicalSessionType: string
    documentationType: string
    goals: Goals[]
    type: string
    frequencySection: string
    minutesPerSession: string
    addendums: {addendum: string, by: string, on: string  | null}[]
    newAddendum: {addendum: string, by: string, on: string | null}[]
}

const TreatmentPlan: React.FC<Props> = ({ patientId, canEdit, isModalOpen, toggleModal, notesId,
    setShowSubmitConfirmationModal, showSubmitConfirmationModal,
    isAddendumAdded, setIsAddendumAdded }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { treatmentNotes, treatmentNotesCount, notesStatus, sessionNotesId, toastNotesStatus, screenersToBeAssigned, patient } = useSelector((state: StateParams) => state.patients)
    const {statusUpdateError} = useSelector((state: StateParams) => state.prospects)
    const { firstName, lastName } = useSelector((state: StateParams) => state.account)
    const role = useSelector((state: StateParams) => state.account.role);
    const { register, control, clearErrors, watch, formState: { errors }, getValues, setValue, reset, setError } = useForm<Inputs>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [selectedScreeners, setSelectedScreeners] = useState<string[]>([])
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const dispatchNotesStatusChange = useStatusChange(SessionTypes.TreatmentPlan)

    const { fields: addendums, append, remove } = useFieldArray({
        control,
        name: "newAddendum"
    })

    const saveNotes = useCallback(
        _.debounce((key, answer) => dispatch(saveTreatmentNotes(patientId, { treatmentNotes: { [key]: answer } }, notesId)), 500), []
    )

    const mapScreeners = (goals: Goals[]) => {
        return goals ? goals.map(goal => {
            return {
                ...goal,
                screeners: goal.screener && goal.screener.length > 0 ? goal.screener.map(x => ({
                    screener: x,
                    checked: true
                })) : goal.screeners ? goal.screeners.map(screener => {
                    return {
                        ...screener
                    }
                }) : [],
                screener: [],
            }
        }) : []
    }

    useEffect(() => {
        dispatch(setTreamentPlanScreeners(selectedScreeners))
    }, [selectedScreeners])

    useEffect(() => {
        if(isAddendumAdded) {
            setIsAddendumAdded(false)
            const createdBy = `${firstName} ${lastName}`
            if(addendums.length === 0) append({ addendum: '', by: createdBy, on: DateTime.now().toISO()})
        }
    }, [isAddendumAdded])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name && type === 'change') {
                const extendedName = name.split('.').length > 0 ? name.split('.')[0] : name
                const key = determineKey(extendedName)
                if (key === 'goals') {
                    const screener = watch(name as any)
                    const allScreenerGoals = value.goals?.flatMap(goal => goal?.screeners).flatMap(x => x?.screener).filter(x => x)
                    if(screener && allScreenerGoals?.filter(x => x === screener)?.length === 2) {
                        setSelectedScreeners([])
                        setError('goals', { type: 'validate', message: 'Screener already selected for another goal' })
                        return;
                    } else if (allScreenerGoals && allScreenerGoals.length > 2) {
                        setSelectedScreeners([])
                        setError('goals', { type: 'validate', message: 'You can select up to two screeners.' })
                        return;
                    } else if (allScreenerGoals && allScreenerGoals.length <= 2) {
                        setSelectedScreeners(_.compact(allScreenerGoals))
                        clearErrors('goals')
                    }
                }
                const answer = value[key]
                saveNotes(key, answer)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    useEffect(() => {
        const notes = treatmentNotes?.treatmentNotes
        if (notes) {
            const targetDate = notes?.targetDate
            const goals = notes.goals ? notes.goals as unknown as Goals[] : null
            const screeners = goals ? goals.flatMap((goal: Goals) => goal.screeners) : null

            //BUG: when screener array is there, consider screener string array else screeners array object
            const _screeners = goals ? goals.flatMap((goal: Goals) => goal.screener) : null

            //BUG
            const historicalScreeners: (string | undefined)[] | null = _screeners && _screeners.length > 0 ? _screeners.filter(screener => screener) : null
            const selectedScreeners: (string | undefined)[] | null = !historicalScreeners?.length && screeners && screeners.length > 0 ? screeners.filter(x => x.screener).map(screener => screener.screener) : null

            const screenersToBeAssigned = _.compact(historicalScreeners || selectedScreeners)
            if (screenersToBeAssigned) {
                setSelectedScreeners(_.compact(screenersToBeAssigned))
            }
            setIsLoading(false)
            reset({
                ...notes,
                goals: mapScreeners(notes.goals as unknown as Goals[]),
                targetDate: targetDate ? DateTime.fromISO(targetDate.toString()).toFormat("MM/dd/yyyy") : undefined
            })
        }
    }, [treatmentNotes])

    const handleStatusChange = (status: NotesStatus) => {
        const id = patientId
        const documentId = notesId || sessionNotesId
        if (id && documentId) {
            batch(() => {
                dispatchNotesStatusChange(status, documentId, id, screenersToBeAssigned, patient?.sourcePatientId, patient?.referredByPracticeId)
            })
        }
    }

    useEffect(()=>{
        if(statusUpdateError){
            toast.error("Please lock the previous notes to lock current note")
         }else if (toastNotesStatus === NotesStatus.LOCKED) {
            toast("Notes has been locked successfully.")
        } else if(toastNotesStatus === NotesStatus.PENDING_PSYCH_REVIEW){
            toast("Notes has been sent for review successfully.")
        }
        dispatch(clearNotesError())
        dispatch(clearToastNotesStatus())
    }, [statusUpdateError, toastNotesStatus])

    const submitPsychiatristNotes = (canContinue: boolean = false) => {
        if(canContinue) {
            handleStatusChange(NotesStatus.LOCKED)
            setShowConfirmationModal(false)
            toggleModal()
        }
        else setShowConfirmationModal(true)
    }

    const onCounsellorSubmit = (canContinue: boolean) => {
        if (canContinue) {
            handleStatusChange(NotesStatus.PENDING_PSYCH_REVIEW)
            setShowSubmitConfirmationModal(false)
            navigate(-1)
        }
    }

    const renderSaveButton = () => <Button className={"!bg-sjOrange !text-white !ml-2"} data-testid="psychiatristSaveBtn"
        onClick={() => toast("Notes has been saved successfully.")}>Save</Button>

    const renderPsychiatristSubmitConfirmation = () => <ConfirmationModal isOpen={showConfirmationModal} onClose={() => setShowConfirmationModal(false)}
        onConfirm={() => submitPsychiatristNotes(true)}
        key={'counsellorLock'}>
        <p className="text-sm text-gray-500">The notes will become non-editable and will be submitted for billing<br /></p>
    </ConfirmationModal>

    const renderSubmitForReviewConfirmationModal = () => <ConfirmationModal isOpen={showSubmitConfirmationModal} onClose={() => setShowSubmitConfirmationModal(false)}
        onConfirm={() => onCounsellorSubmit(true)}
        key={'counsellorLock'}>
        <p className="text-sm text-gray-500">The notes will become non-editable and will be submitted for review</p>
    </ConfirmationModal>

    const renderPsychiatricNotesHeader = () => <div className='flex flex-row justify-between'>
        <div className='cursor-pointer mr-5'>
            {canAddPsychiatristNotes(notesStatus, role) &&
                <>
                    {renderSaveButton()}
                    <Button className={"!bg-sjOrange !text-white !ml-2"} onClick={() => submitPsychiatristNotes(false)} data-testid="psychiatristSubmitBtn">Submit</Button>
                </>}
        </div>
        <div onClick={toggleModal} className="cursor-pointer absolute top-0 right-0 m-1">
            <AiFillCloseCircle className="text-gray-500 hover:text-gray-700" style={{ width: '25px', height: '25px' }} />
        </div>
    </div>

    return (
        // <form>
        <>
        <section style={{ backgroundColor: '#FFFFFF' }}
            className={"w-full intake-section mt-4 p-10  min-h-[25vh] rounded-lg shadow-lg"}>
            {renderSubmitForReviewConfirmationModal()}
            <fieldset disabled={!canEdit}>
                {treatmentNotesCount && treatmentNotesCount > 1 && <div>
                    <div className="mt-3">
                        <label className="text-lg">Reason for updating treatment plan</label>
                        <Textarea {...register("reasonForUpdating")} className="mt-3 outline-none rounded-md" />
                    </div>
                </div>}
            </fieldset>
            <fieldset disabled={!canEdit}>
                <div className="mt-4">
                    <label className="text-lg uppercase">Type:</label>
                    <div className="flex justify-start">
                        <Radio id="counseling" {...register("type")} className="text-sjOrange" label="Counseling" value="counseling" />
                        <Radio id="caseManagement" {...register("type")} className="text-sjOrange" label="Case management" value="caseManagement" />
                    </div>
                </div>
                <div className="mt-4">
                    <label className="text-lg text-sjOrange uppercase" data-testid="frequencySection">frequency section:</label>
                    <div className="flex justify-start">
                        <Radio id="frequencySection0" {...register("frequencySection")} className="text-sjOrange" label="Weekly" value="weekly" />
                        <Radio id="frequencySection1" {...register("frequencySection")} className="text-sjOrange" label="Bi-weekly" value="biWeekly" />
                        <Radio id="frequencySection2" {...register("frequencySection")} className="text-sjOrange" label="Monthly" value="monthly" />
                    </div>
                </div>
                <div id="minsPerSession" className="mt-3">
                    <label className="block text-lg mt-6 text-sjOrange uppercase">minutes per session:</label>
                    <select {...register("minutesPerSession")} defaultValue="10" className="mt-3 outline-none rounded-md" >
                        <option value="10">10 minutes</option>
                    </select>
                </div>
                <div className="mt-4">
                    <label className="text-lg uppercase">LONG TERM GOAL:</label>
                    <TreatmentPlanGoals {...{ control, register, getValues, setValue, watch, errors }} saveNotes={saveNotes} />
                </div>
                <div id="target-date" className="mt-3">
                    <label className="block text-lg mt-6 uppercase">Target Date:</label>
                    <input type="text" className="mt-3 outline-none rounded-md bg-gray-300" {...register("targetDate")} disabled={true} />
                </div>
                <div id="frequency">
                    <label className="block text-lg mt-6 uppercase">Frequency:</label>
                    <Controller
                    name="frequency"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                        <TextAreaComponent
                        value={field.value}
                        onChange={field.onChange}
                        placeholder=""
                        />
                    )}
                    />
                </div>
                <div id="interventions">
                    <label className="block text-lg mt-6 uppercase">Interventions:</label>
                    <Controller
                    name="interventions"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                        <TextAreaComponent
                        value={field.value}
                        onChange={field.onChange}
                        placeholder=""
                        />
                    )}
                    />
                </div>
            </fieldset>
            <fieldset disabled={!canEdit}>
                <div className="grid grid-rows-3 grid-flow-col gap-6">
                    <div className="row-span-3">
                        <label className="block text-lg mt-6 uppercase">Clinical session time:</label>
                        <select {...register("clinicalSessionType")} defaultValue="30" className="mt-3 outline-none rounded-md" >
                            <option value="30">30 minutes</option>
                        </select>
                    </div>
                    <div id="documentationType" className="row-span-3">
                        <label className="block text-lg mt-6 uppercase">documentation time:</label>
                        <select {...register("documentationType")} defaultValue="20" className="mt-3 outline-none rounded-md" >
                            <option value="20">20 minutes</option>
                        </select>
                    </div>
                </div>
            </fieldset>
                <ReactModal
                    isOpen={isModalOpen}
                    onRequestClose={toggleModal}
                    shouldCloseOnOverlayClick={true}
                    className={"relative top-5 mx-auto p-5 border w-3/4 h-auto shadow-lg rounded-md bg-white"}
                    data-testid="psychiatristNotesModal"
                >
                    <div style={{ overflow: "auto", maxHeight: "85vh" }}>
                        {renderPsychiatristSubmitConfirmation()}

                        <PsychiatristNotes {...{ register, watch, reset, getValues, control, errors, setValue }} renderErrorMessage={() => ''} saveNotes={saveNotes} notesId={notesId} canEdit={canEdit}>
                            {renderPsychiatricNotesHeader()}
                        </PsychiatristNotes>
                    </div>
                </ReactModal>
            </section>
            {canAccessPsychiatristNotes(notesStatus, false) && <section style={{ backgroundColor: '#FFFFFF' }}
                className={`w-full intake-section mt-6 p-10  min-h-[25vh] rounded-lg shadow-lg`} data-testid="psychiatristNotesSection">
                <PsychiatristNotes {...{ register, watch, reset, getValues, control, errors, setValue }} renderErrorMessage={() => ''} saveNotes={saveNotes} notesId={notesId} canEdit={canEdit} />
            </section>}
            
            {isCounsellorNotesLocked(notesStatus, role)
            && <section style={{ backgroundColor: '#FFFFFF' }}
                className={`w-full intake-section mt-6 p-10  min-h-[25vh] rounded-lg shadow-lg`} >
                <Addendum {...{ register, control, addendums, remove, getValues }} saveNotes={saveNotes} />
            </section>}
            {isNotesLocked(notesStatus) && isPsychiatrist(role)
            && <section style={{ backgroundColor: '#FFFFFF' }}
                className={`w-full intake-section mt-6 p-10  min-h-[25vh] rounded-lg shadow-lg`} >
                <Addendum {...{ register, control, addendums, remove, getValues }} saveNotes={saveNotes} />
            </section>}
        </>
        // </form>
    )
}

export default TreatmentPlan