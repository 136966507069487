import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateParams } from "../../../store/reducers";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RoutePaths } from "../../../shared/Utils";
import { fetchPotentialReferralPatients } from "../../../store/actions/care-coordinator/patients.action";
import PotentialReferrals from "../../../components/patients/care-coordinator/PotentialReferrals";
import { IPotentialReferralPatient, PatientCallStatus } from "../../../models";
import TableFilterPaginationV2 from "../../../components/generic/TableFilterPaginationV2";
import PaginationV2 from "../../../components/generic/PaginationV2";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { fetchPractices } from "../../../store/actions/counsellor/prospects.action";
import { DateTime } from 'luxon';
import { Button } from "../../../components/generic/Buttons";
import { useFlags } from "flagsmith/react";
import { clearPatientLocation } from "../../../store/actions/counsellor/patients.action";
import { IoFilter } from "react-icons/io5";
import Modal from 'react-modal';
import { AiOutlineClose } from "react-icons/ai";
import DateRangePickerComponent from "../../../components/generic/date-range-picker/DateRangePickerComponent";


export enum CallStatus {
    ALL = 'all',
    PENDING = 'pending',
    NOANSWER = 'no_answer',
    CALLAGAIN = 'call_again',
    INTERESTED = 'interested',
    NOTINTERESTED = 'not_interested',
}

export enum InsuranceStatus {
    PENDING = 'pending',
    SEMI_VERIFIED = 'semi_verified',
    VERIFIED = 'verified',
    ELIGIBLE = 'eligible',
    NOT_ELIGIBLE = 'not_eligible',
    INVALID_OR_MISSING_SUBSCRIBER_INFORMATION = 'invalid_or_missing_subscriber_information'
}

const INSURANCE_STATUS_OPTIONS = [
    { label: "Pending", value: "pending" },
    { label: "Semi Verified", value: "semi_verified" },
    { label: "Verified", value: "verified" },
    { label: "Eligible", value: "eligible" },
    { label: "Not Eligible", value: "not_eligible" },
    { label: 'Missing Subscriber Info', value: 'invalid_or_missing_subscriber_information' },
];

const PATIENT_CALL_STATUS_OPTIONS = [
    { value: 'pending', label: 'Pending' },
    { value: 'no_answer', label: 'No Answer' },
    { value: 'call_again', label: 'Call Again' },
    { value: 'interested', label: 'Interested' },
    { value: 'not_interested', label: 'Not Interested' },
];

export type Filters = {
    practiceId: string[]
    startDate: string | null
    endDate: string | null
    insuranceStatus?: InsuranceStatus
    callStatus?: CallStatus
}

const TABS = [
    { label: "All", value: "all" },
    { label: "Pending", value: "pending" },
    { label: "No Answer", value: "no_answer" },
    { label: "Call Again", value: "call_again" },
    { label: "Interested", value: "interested" },
    { label: "Not Interested", value: "not_interested" }
]

const PotentialPatients = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const flags = useFlags(['cc_patient_insurance']);
    const flagsDate = useFlags(['cc_potential_patients_screening_date_range_filter'])
    const isPotentialPatientsDateRangeFilterEnabled = flagsDate.cc_potential_patients_screening_date_range_filter.enabled
    const isPatientInsuranceEnabled = flags.cc_patient_insurance.enabled
    const { potentialReferralPatients, fetchPotentialReferralPatientsInProgress } = useSelector((state: StateParams) => state.coordinatorPatients)
    const [patients, setPatients] = useState<IPotentialReferralPatient[]>([]);
    const recordsCount = useSelector((state: StateParams) => state.coordinatorPatients.potentialReferralPatientCount)
    const statusCount = useSelector((state: StateParams) => state.coordinatorPatients.potentialReferralStatusCount)
    const { practices } = useSelector((state: StateParams) => state.prospects)
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 1);
    const [statusFilters, setStatusFilters] = useState<string[]>(["pending"])
    const [advancedFilters, setAdvancedFilters] = useState<Filters>();
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const [showCustomDateFilter, setShowCustomDateFilter] = useState<boolean>(false);
    const [practiceId, setPracticeId] = useState<string[]>([]);
    const [callStatus, setCallStatus] = useState<string | null>(null);
    const [dateFilter, setDateFilter] = useState<{ startDate?: string, endDate?: string}>({startDate: undefined, endDate: undefined});
    const [filterCount, setFilterCount] = useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { control, watch } = useForm();
    const [associatedPractices, setAssociatedPractices] = useState<{ label: string, value: string }[]>([])
    const [startDate, setStartDate] = useState<string | null>(null)
    const [endDate, setEndDate] = useState<string | null>(null)
    const [insuranceStatus, setInsuranceStatus] = useState<InsuranceStatus>()
    
    useEffect(() => {
        dispatch(fetchPractices())
    }, [])

    const handleDateFilterChange = useCallback((startDate: string, endDate: string) => {
                setDateFilter({startDate, endDate});
                setStartDate(startDate)
                setEndDate(endDate);
            }, [])

    useEffect(() => {
        if (openFilterModal && advancedFilters) {
            // Populate modal inputs with saved filters
            setStartDate(advancedFilters.startDate ? advancedFilters.startDate : null);
            setEndDate(advancedFilters.endDate ? advancedFilters.endDate : null);
            setPracticeId(advancedFilters.practiceId || []);
            // setInsuranceStatus(advancedFilters.insuranceStatus || null);
            // setCallStatus(advancedFilters.callStatus || null);
        }
    }, [openFilterModal, advancedFilters]);

    useEffect(() => {
        if (practices) {
            if (practices && Array.isArray(practices)) {
                setAssociatedPractices(practices.map(practice => ({
                    label: `${practice.name}`,
                    value: practice.id
                })))
            }
            setIsLoading(false)
        }
    }, [practices])

    useEffect(() => {
        const subscription = watch((value: any, { name, type }) => {
            if (name && type === 'change') {
                setAdvancedFilters(value)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    const handleApply = () => {
        setAdvancedFilters({
            practiceId,
            startDate: startDate ? startDate : null,
            endDate: endDate ? endDate : null,
            callStatus: callStatus as Filters['callStatus'],
            insuranceStatus: insuranceStatus || undefined
        });
        setOpenFilterModal(false);
        setFilterCount(
            (practiceId.length > 0 ? 1 : 0) +
            (startDate && endDate ? 1 : 0) +
            (callStatus ? 1 : 0)
        );
    };

    const handleDateApply = () => {
        setCurrentPage(1);

        setAdvancedFilters((prev: any) => {
            const formattedStartDate = startDate ? DateTime.fromISO(startDate).toFormat("yyyy-MM-dd") : null;
            const formattedEndDate = endDate ? DateTime.fromISO(endDate).toFormat("yyyy-MM-dd") : DateTime.now().toFormat("yyyy-MM-dd");

            return {
                ...prev,
                startDate: formattedStartDate && formattedStartDate,
                endDate: formattedEndDate && formattedEndDate
            };
        });
    }


    const handleReset = () => {
        setPracticeId([]);
        setStartDate(null);
        setEndDate(null);
        setCallStatus(null);
        setInsuranceStatus(undefined);
        setAdvancedFilters(undefined);
        setCurrentPage(1);
    }


    useEffect(() => {
        if (currentPage || recordsPerPage) {
            dispatch(clearPatientLocation())
            dispatch(fetchPotentialReferralPatients(currentPage, recordsPerPage, searchText, { status: statusFilters.length > 0 ? statusFilters : undefined }, advancedFilters))
        }
    }, [currentPage, recordsPerPage, searchText, insuranceStatus, statusFilters, advancedFilters]);

    useEffect(() => {
        const page = Number(searchParams.get("page"));
        if (page > 0) {
            setCurrentPage(Number(searchParams.get("page")));
        }
    }, [searchParams])

    useEffect(() => {
        if (potentialReferralPatients) {
            setPatients(potentialReferralPatients)
        }
    }, [potentialReferralPatients])

    useEffect(() => {
        navigate(`${RoutePaths.careCoordinator.potentialPatients.root}?page=${currentPage}`)
    }, [currentPage]);

    const handleSelectedFilters = (status: string) => {
        setCurrentPage(1)
        if (status === "all") {
            setStatusFilters([])
            return
        }
        setStatusFilters([status])
    }

    const onChangeValue = (e: any) => {
        if (e.target.id === 'startDate') {
            if (e.target.value) {
                const isoStartDate = DateTime.fromISO(e.target.value).toLocal().toFormat('yyyy-MM-dd');
                setStartDate(isoStartDate);
            } else {
                setStartDate(null);
            }
        } else {
            if (e.target.value) {
                const isoEndDate = DateTime.fromISO(e.target.value).endOf('day').toLocal().toFormat('yyyy-MM-dd');
                setEndDate(isoEndDate);
            } else {
                setEndDate(null); // Clear end date
            }
        }
    };


    return (
        <div className="w-full p-4 h-full bg-[#F7F8FA]">
            <div className={"flex justify-between"}>
                <h1 className="text-[20px] leading-27">Potential Patients</h1>
                {!isLoading && <div className="flex justify-end items-center gap-x-2">
                    {!isPatientInsuranceEnabled && <>

                        {isPotentialPatientsDateRangeFilterEnabled &&
                            <div className='flex flex-row gap-x-2 items-center'>
                                <input
                                    id="startDate"
                                    type="date"
                                    required
                                    max={DateTime.now().toFormat('yyyy-MM-dd')}
                                    value={startDate ? DateTime.fromISO(startDate).toFormat('yyyy-MM-dd') : ""}
                                    onChange={(e) => onChangeValue(e)}
                                    className="w-full rounded-md h-9 border-gray-300 focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
                                />

                                <span>to</span>

                                <input
                                    id="endDate"
                                    type="date"
                                    required
                                    value={endDate ? DateTime.fromISO(endDate).toFormat('yyyy-MM-dd') : ""}
                                    min={startDate ? DateTime.fromISO(startDate).toFormat('yyyy-MM-dd') : ""}
                                    max={DateTime.now().toFormat('yyyy-MM-dd')} // Ensure end date can't be in the future
                                    onChange={(e) => onChangeValue(e)}
                                    className="w-full rounded-md h-9 border-gray-300 focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
                                />

                                <Button
                                    data-tooltip-id="invalid-date-selection-tooltip"
                                    className="!bg-sjOrange !text-white"
                                    onClick={handleDateApply}>Apply</Button>
                            </div>
                        }
                        <div className="w-80">
                            <Controller
                                control={control}
                                name='practiceId'
                                render={({ field }) => (
                                    <Select
                                        className="border-gray-400 rounded text-center"
                                        options={associatedPractices}
                                        value={associatedPractices.find(x => x.value === field.value)}
                                        onChange={x => field.onChange(x.map(y => y?.value))}
                                        isMulti={true}
                                        isSearchable={true}
                                        isClearable={true}
                                        placeholder={'Select Physician'}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor: 'gray',
                                                height: 22, // adjust the height to your liking
                                                minHeight: 32
                                            }),
                                            placeholder: (style) => ({
                                                ...style,
                                                paddingBottom: 8,
                                                textAlign: 'center'
                                            }),
                                            multiValue: (style) => ({
                                                ...style,
                                                marginTop: -5
                                            }),

                                        }}
                                    />
                                )}
                            />
                        </div>
                    </>
                    }

                </div>}
            </div>

            <div className={"flex justify-between pb-4 mt-4"}>
                <div className="inline-flex rounded-md shadow-sm">
                    <a href="#" aria-current="page"
                        onClick={() => handleSelectedFilters(CallStatus.ALL)}
                        className={`${statusFilters.length === 0 ? "bg-sjLightOrange text-sjOrange" : "bg-sjWhite text-black"} px-4 py-2 text-sm font-medium rounded-s-lg border border-gray-200`}>
                        All ({statusCount.all || 0})
                    </a>
                    <a href="#"
                        onClick={() => handleSelectedFilters(CallStatus.PENDING)}
                        className={`${statusFilters.includes(CallStatus.PENDING) ? "bg-sjLightOrange text-sjOrange" : "bg-sjWhite text-black"} px-4 py-2 text-sm font-medium border border-gray-200`}>
                        Pending ({statusCount.pending || 0})
                    </a>
                    <a href="#"
                        onClick={() => handleSelectedFilters(CallStatus.NOANSWER)}
                        className={`${statusFilters.includes(CallStatus.NOANSWER) ? "bg-sjLightOrange text-sjOrange" : "bg-sjWhite text-black"} px-4 py-2 text-sm font-medium border border-gray-200`}>
                        No Answer ({statusCount.no_answer || 0})
                    </a>
                    <a href="#"
                        onClick={() => handleSelectedFilters(CallStatus.CALLAGAIN)}
                        className={`${statusFilters.includes(CallStatus.CALLAGAIN) ? "bg-sjLightOrange text-sjOrange" : "bg-sjWhite text-black"} px-4 py-2 text-sm font-medium border border-gray-200`}>
                        Call Again ({statusCount.call_again || 0})
                    </a>
                    <a href="#"
                        onClick={() => handleSelectedFilters(CallStatus.INTERESTED)}
                        className={`${statusFilters.includes(CallStatus.INTERESTED) ? "bg-sjLightOrange text-sjOrange" : "bg-sjWhite text-black"} px-4 py-2 text-sm font-medium border border-gray-200`}>
                        Interested ({statusCount.interested || 0})
                    </a>
                    <a href="#"
                        onClick={() => handleSelectedFilters(CallStatus.NOTINTERESTED)}
                        className={`${statusFilters.includes(CallStatus.NOTINTERESTED) ? "bg-sjLightOrange text-sjOrange" : "bg-sjWhite text-black"} px-4 py-2 text-sm font-medium rounded-e-lg border border-gray-200`}>
                        Not Interested ({statusCount.not_interested || 0})
                    </a>
                </div>
                <div className="flex gap-x-2 justify-end items-center">
                    <TableFilterPaginationV2
                        setTableFilter={setSearchText}
                        totalRows={recordsCount || 0}
                        currentPageHandler={setCurrentPage}
                    />
                    {isPatientInsuranceEnabled &&
                        <>
                            <div className="w-1/2">
                                <Select
                                    className="placeholder: italic"
                                    options={INSURANCE_STATUS_OPTIONS}
                                    placeholder={"Insurance Status"}
                                    isClearable={true}
                                    isSearchable={false}
                                    isMulti={false}
                                    getOptionLabel={option => `${option?.label}`}
                                    getOptionValue={option => option?.value}
                                    value={INSURANCE_STATUS_OPTIONS.find(option => option.value === insuranceStatus) || null}
                                    onChange={(selectedOption) => {
                                        setAdvancedFilters((prevFilters: any) => ({
                                            ...prevFilters,
                                            insuranceStatus: selectedOption?.value
                                        }))
                                        setInsuranceStatus(selectedOption?.value as InsuranceStatus || undefined);
                                    }}
                                />
                            </div>


                            <button className="btn btn-icon relative"
                                onClick={() => setOpenFilterModal(true)}
                                aria-label="Open Advanced Filters">
                                <IoFilter className="h-7 w-7 cursor-pointer" color="#575F6E"
                                />
                                {filterCount > 0 && (
                                    <span className="absolute bottom-3 left-3 flex h-5 w-5 items-center justify-center rounded-full bg-orange-500 text-white text-xs">
                                        {filterCount}
                                    </span>
                                )}
                            </button>
                        </>
                    }
                </div>

            </div>

            <PotentialReferrals patients={patients} searchInProgress={fetchPotentialReferralPatientsInProgress} statusFilters={statusFilters} />
            
            {patients && patients.length > 0 &&
                <PaginationV2
                    totalRows={recordsCount || 0}
                    rowsPerPage={recordsPerPage}
                    recordsPerPageHandler={setRecordsPerPage}
                    currentPage={currentPage}
                    currentPageHandler={setCurrentPage}
                    showPages={false}
                />
            }
            <Modal
                isOpen={openFilterModal}
                onRequestClose={() => setOpenFilterModal(false)}
                className="fixed top-0 right-0 mx-auto p-5 border w-1/4 shadow-lg bg-white min-h-screen"
            >
                <div className="flex justify-between">
                    <h1 className="text-lg leading-27">Advance Filters</h1>
                    <AiOutlineClose onClick={() => setOpenFilterModal(false)} className="h-5 w-5 cursor-pointer" color="#575F6E" />
                </div>
                <div className="space-y-4 mt-8">

                    <div>
                        <span className="content-start">Practice</span>
                        <Select
                            className="placeholder: italic"
                            options={practices}
                            placeholder={"Practice"}
                            isClearable={true}
                            isSearchable={false}
                            isMulti={true}
                            getOptionLabel={option => `${option?.name}`}
                            getOptionValue={option => option?.id}
                            onChange={(selectedOptions) => {
                                setPracticeId(selectedOptions ? selectedOptions.map(option => option.id) : [])
                            }}
                            value={practices?.filter(practice => practiceId.includes(practice.id))}
                        />
                    </div>

                    <div className="physician-dashboard-date-filter">
                        <span className="content-start">Screening Date</span>
                        <DateRangePickerComponent
                            startDate={dateFilter.startDate}
                            endDate={dateFilter.endDate}
                            onFilterChange={handleDateFilterChange}
                            isClearable={true}
                            maxDate={new Date()}
                            placeholder="Screening Date"
                        />
                    </div>

                    {/* <div>
                        <span className="content-start">Patient Call</span>
                        <Select
                            options={PATIENT_CALL_STATUS_OPTIONS}
                            placeholder={"Patient Call"}
                            isClearable={true}
                            isSearchable={false}
                            isMulti={false}
                            getOptionLabel={option => `${option?.label}`}
                            getOptionValue={option => option?.value}
                            value={PATIENT_CALL_STATUS_OPTIONS.find(option => option.value === callStatus) || null}
                            onChange={(selectedOption) => {
                                if (!selectedOption) {
                                    setCallStatus(null)
                                    return
                                }
                                setCallStatus(selectedOption.value)
                            }}
                        />
                    </div> */}


                    <div className="flex gap-4 justify-end">
                        <Button className={"!border-sjOrange !text-sjOrange"}
                            onClick={() => {
                                handleReset()
                                setFilterCount(0)
                            }
                            }
                        >Reset</Button>
                        <Button className={"!bg-sjOrange !text-white"}
                            onClick={handleApply}
                        >Confirm</Button>
                    </div>


                </div>
            </Modal>
        </div>
    )
}

export default PotentialPatients
