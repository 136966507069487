import React, { useCallback, useEffect, useState } from "react";
import { CommentsResponse } from "../../models";
import CommentComponent from "./CommentComponent";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { StateParams } from "../../store/reducers";
import AddCommentComponent from "./AddCommentComponent";
import { Button } from "../../components/generic/Buttons";
import { fetchPatientComments } from "../../store/actions/care-coordinator/patients.action";
import NoComments from "../../assets/images/noComments.svg";
import LoadingComponent from "../generic/LoadingComponent";
import { AiOutlineClose } from "react-icons/ai";
import ConfirmationModal from "../clinical-notes/ConfirmationModal";


interface ICommentListComponentProps {
    patientId: string;
    onClose?: () => void;
    source?: string;
    commentsTitleHelperText?: string;
}

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_CURRENT_PAGE = 1;

const CommentListComponent: React.FC<ICommentListComponentProps> = (props: ICommentListComponentProps) => {
    const { patientId, commentsTitleHelperText, onClose, source } = props;
    const [currentPage, setCurrentPage] = useState(DEFAULT_CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const dispatch = useDispatch();
    const { patientComments, addCommentInProgress, patientCommentsCount, PatientCommentsInProgress } = useSelector((state: StateParams) => state.coordinatorPatients);
    const [showCommentBlock, setShowCommentBlock] = useState(false);
    const [allComments, setAllComments] = useState<CommentsResponse[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const [hasComment, setHasComment] = useState(false);
    const [isDiscardModalOpen, setIsDiscardModalOpen] = useState<boolean>(false);
    const [initialLoad, setInitialLoad] = useState(false);

    useEffect(() => {
        dispatch(fetchPatientComments(patientId, currentPage, pageSize));
    }, [dispatch, currentPage, patientId, pageSize]);

    useEffect(() => {
        if (patientComments) {
            setAllComments((prevComments) => {
                const newComments = patientComments.filter(
                    (newComment) => !prevComments.some((comment) => comment.id === newComment.id)
                );
                return currentPage === DEFAULT_CURRENT_PAGE ? patientComments : [...prevComments, ...newComments];
            });
        }
    }, [patientComments, currentPage]);

    useEffect(() => {
        if (patientCommentsCount) {
            setHasMore(allComments.length < patientCommentsCount);
        }
    }, [allComments, patientCommentsCount]);

    useEffect(() => {
        if (currentPage === DEFAULT_CURRENT_PAGE && PatientCommentsInProgress) {
            setInitialLoad(true);
        } else {
            setInitialLoad(false);
        }
    }, [PatientCommentsInProgress, currentPage]);

    const fetchMoreData = () => {
        if (hasMore && !PatientCommentsInProgress) {
            setCurrentPage((prev) => prev + 1);
        }
    };

    const openAddCommentBlock = () => {
        setShowCommentBlock(true);
    }

    const renderDiscardCommentConfirmationModal = () => {
        return (
            <ConfirmationModal isOpen={isDiscardModalOpen}
                onClose={() => setIsDiscardModalOpen(!isDiscardModalOpen)}
                key={'discardCommentConfirmationModal'}
                modalClassNames={'!w-1/2'}
                continueButtonName={'Discard'}
                actions={
                    <div className="flex justify-end gap-4">
                        <Button className="border-sjOrange text-sjOrange" onClick={
                            () => setIsDiscardModalOpen(false)
                        }>Cancel</Button>
                        <Button className="!bg-sjOrange text-white" onClick={onClose}>Close</Button>
                    </div>
                }
            >
                <p className="text-sm text-medium text-left">Discard Comment?</p>
                <p className="text-sjGray text-sm font-base text-left pt-4">
                    Are you sure you want to close the window without submitting your comment? Any unsaved comments will be lost.
                </p>
            </ConfirmationModal>
        )
    }

    const handleCloseDrawer = () => {
        if (hasComment) {
            setIsDiscardModalOpen(true)
        } else {
            onClose && onClose();
        }
    }

    const onCommentSubmitHandler = useCallback(() => {
        setCurrentPage(DEFAULT_CURRENT_PAGE);
        dispatch(fetchPatientComments(patientId, DEFAULT_CURRENT_PAGE, pageSize));
    }, [dispatch, patientId, pageSize]);


    return (
        <>
            <div>
                <div className="flex justify-content-between">
                    <div className="text-xl font-medium text-[#242731] flex-1 mb-2">Comments</div>
                    {onClose && <div
                        onClick={handleCloseDrawer}
                        className="cursor-pointer grow-0"
                    >
                        <AiOutlineClose
                            className="text-gray-500 hover:text-gray-600"
                            style={{ width: '25px', height: '25px' }}
                        />
                    </div>}
                </div>
                <div
                    className={"text-xs text-[#575F6E] font-extralight"}>
                    {
                        (commentsTitleHelperText ? commentsTitleHelperText : 'Enter or browse through previous comments' )
                    }
                </div>
                {
                    showCommentBlock &&
                    <AddCommentComponent
                        patientId={patientId}
                        source={source}
                        onCommentSubmit={onCommentSubmitHandler}
                        checkCommentExist={
                            (hasText: boolean) => {
                                setHasComment(hasText);
                            }
                        }
                        onClose={
                            () => {
                                setShowCommentBlock(false);
                            }
                        } />
                }
                <LoadingComponent isLoading={initialLoad || addCommentInProgress} />
                {
                    allComments && allComments.length > 0 ?
                        <>
                            {!showCommentBlock &&
                                <div className="flex justify-end my-8">
                                    <Button className="!bg-sjOrange !text-white ml-auto" onClick={openAddCommentBlock}>
                                        Add Comment +
                                    </Button>
                                </div>
                            }

                            <div className="flex items-center mb-8">
                                <div className="mr-4 text-gray-500 font-thin">Past Comments</div>
                                <div className="flex-1">
                                    <hr className="border-t border-sjGrey-100" />
                                </div>
                            </div>

                            {allComments &&
                                <div className="h-full">
                                    <div className={`overflow-y-auto ${showCommentBlock ? 'max-h-[calc(100vh-350px)]' : 'max-h-[calc(100vh-250px)]'} `} id="scrollableDiv">
                                        <InfiniteScroll
                                            dataLength={allComments.length}
                                            next={fetchMoreData}
                                            hasMore={hasMore}
                                            loader={hasMore &&
                                                <div className="flex justify-center items-center h-20">
                                                    <div className="infinite-loading"></div>
                                                </div>
                                            }
                                            scrollThreshold={0.9}
                                            scrollableTarget="scrollableDiv"
                                        >
                                            {allComments.map((comment, index) => (
                                                <CommentComponent key={index} commentObject={comment} />
                                            ))}
                                        </InfiniteScroll>

                                    </div>
                                </div>
                            }
                        </>

                        : <>
                            {(!showCommentBlock && !PatientCommentsInProgress) &&
                                <div className="flex flex-col items-center justify-center mt-12 gap-12">
                                    <img src={NoComments} alt="No Comments" />
                                    <Button className={"!bg-sjOrange !text-white !ml-2"}
                                        onClick={openAddCommentBlock}>
                                        Add Comment +
                                    </Button>
                                </div>
                            }
                        </>
                }

            </div >
            {renderDiscardCommentConfirmationModal()}
        </>
    );
}

export default CommentListComponent;
