import { useDispatch, useSelector } from "react-redux";
import { StateParams } from "../../../store/reducers";
import CommentBox from "../../../components/generic/CommentBox";
import ProfileIcon from "../../../assets/images/common/profile.svg";
import { addScreeningSessionNotes } from "../../../store/actions/care-coordinator/patients.action";
import { useEffect, useState } from "react";
import { ScreeningSessionNotesResponse } from "../../../models";

interface Props {
    screeningSessionId: string;
    patientId: string;
    setShowAddNote: (showAddNote: boolean) => void;
    screeningSessionNotes: ScreeningSessionNotesResponse | undefined
}

const AddSessionNotes = ({ patientId, screeningSessionId, setShowAddNote, screeningSessionNotes }: Props) => {
    const dispatch = useDispatch();
    const [note, setNote] = useState<string>(screeningSessionNotes?.note || '');
    const [hasChanged, setHasChanged] = useState<boolean>(false);

    const submitNote = () => {
        if (screeningSessionId && patientId) {
            dispatch(addScreeningSessionNotes({
                note,
                patientId,
                sessionId: screeningSessionId
            }));
            return;
        }
        console.log('PatientId/ScreeningSessionId is missing');
    }

    return (
        <div>
            <div className="flex justify-content-between">
                <div className="text-xl font-medium text-[#242731] flex-1 mb-2">Add note for this screening visit</div>
            </div>
            <div className={"text-xs text-[#575F6E] font-extralight"}>Your note should be based on your conversation with the patient.</div>
            <div className="flex gap-4 m-5">
                <img src={ProfileIcon} alt="Profile Icon" className="w-7 h-7" />
                <CommentBox
                    placeholder="Add a note"
                    onSubmit={submitNote}
                    onDiscard={() => {
                        setShowAddNote(false);
                    }}
                    onChange={setHasChanged}
                    comment={note}
                    setComment={setNote}
                />
            </div>
        </div>
    )
};

export default AddSessionNotes;