import React, { useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { StateParams } from '../../store/reducers'
import { LocationIndexItem } from '../../models'
import Locations from '../../components/locations/Locations'
import { fetchLocations } from '../../store/actions/physician/admin-operations.action'
import { Button } from '../../components/generic/Buttons'
import TableFilterPaginationV2 from '../../components/generic/TableFilterPaginationV2'
import LoadingComponent from '../../components/generic/LoadingComponent'

const LocationsScreen = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    const { locations: fetchedLocations, locationsCount: recordsCount, fetchLocationsInProgress } = useSelector((state: StateParams) => state.physicianAdminOperations)
    const [locations, setLocations] = useState<LocationIndexItem[]>([]);
    const [recordsPerPage, setRecordsPerPage] = useState(100)
    const [searchText, setSearchText] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageNumber, setPageNumber] = useState(Number(searchParams.get("page")) || 1);
    const [status, setStatus] = useState<boolean>()
    const [showAddLocationForm, setShowAddLocationForm] = useState<boolean>(false)

    useEffect(() => {
        if (searchParams) {
            setPageNumber(Number(searchParams.get("page")))
        }
    }, [searchParams])

    useEffect(() => {
        if (fetchedLocations) {
            console.log('fetchedLocations', fetchedLocations)
            setLocations(fetchedLocations)
        }
    }, [fetchedLocations])

    useEffect(() => {
        if (pageNumber || recordsPerPage || searchText) {
            dispatch(fetchLocations({ pageNumber, recordsPerPage, searchText, status }))
        }
    }, [pageNumber, recordsPerPage, searchText, status]);

    useEffect(() => {
        if ((pageNumber || recordsPerPage || searchText) && !showAddLocationForm) {
            dispatch(fetchLocations({ pageNumber, recordsPerPage, searchText }))
        }
    }, [showAddLocationForm]);

    useEffect(() => {
        if (location.pathname.includes("create") || location.pathname.includes("edit")) {
            setShowAddLocationForm(true)
        } else {
            setShowAddLocationForm(false)
        }
    }, [location.pathname])

    const renderRightSidebar = () => {
        return (
            <div className={"absolute top-0 right-0 w-1/2 bg-white z-[2000]"}>
                <Outlet />
            </div>
        )
    }

    const renderFilters = () => {
        return <div className='flex flex-row'>
            <TableFilterPaginationV2
                setTableFilter={setSearchText}
                totalRows={recordsCount || 0}
                currentPageHandler={setPageNumber}
            />
        </div>
    }

    const renderLocations = () => {
        return <>
            <div className={showAddLocationForm ? "foreground" : ""} />
            <div className={showAddLocationForm ? "uneditable" : ""}>
                <div className="flex flex-row justify-between">
                    <div className='flex'>
                        <h1 className="text-xl"> Location Master </h1>
                    </div>
                    <div className='flex flex-row gap-x-2 items-center'>
                        {renderFilters()}
                        <Link to={"create"}>
                            <Button className={"!bg-sjOrange !text-white !ml-2"}>Add Location +</Button>
                        </Link>
                    </div>
                </div>
                <LoadingComponent  isLoading={fetchLocationsInProgress} />
                <div className="mt-4 relative">
                    <Locations searchInProgress={fetchLocationsInProgress} locationsIndex={locations} currentPageHandler={setPageNumber} totalRows={recordsCount || 0} />
                </div>
            </div>
        </>;
    }
    return (
        <div className={"w-full p-4 h-full bg-[#F7F8FA]"}>
            {renderRightSidebar()}
            {renderLocations()}
        </div>
    )
}

export default LocationsScreen
