import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import Select from "react-select";
import {DateTime} from "luxon";
import {
    fetchPhysicianAppointmentsDayWiseMetrics,
} from "../../../store/actions/care-coordinator/patients.action";
import {
    IPracticeLocation
} from "../../../models";
import {BiSolidErrorCircle} from "react-icons/bi";
import DateRangePickerComponent from "../../../components/generic/date-range-picker/DateRangePickerComponent";
import TablePaginationV2 from "../../../components/generic/TablePaginationV2";
import RefreshComponent from "../../../components/generic/RefreshComponent";

const PhysicianAppointmentsDayWiseMetricsComponent = () => {

    const initialStartDate = DateTime.local().minus({days: 6}).toFormat('yyyy-MM-dd');
    const initialEndDate = DateTime.local().toFormat('yyyy-MM-dd');
    const [dateFilter, setDateFilter] = useState<{
        startDate: string,
        endDate: string
    }>({startDate: initialStartDate, endDate: initialEndDate});
    const dispatch = useDispatch();
    const {practiceMaster} = useSelector((state: StateParams) => state.careCoordinatorMasterData);
    const [locations, setLocations] = useState<IPracticeLocation[]>([]);
    const {
        physicianAppointmentsDayWiseMetrics,
        physicianAppointmentsDayWiseMetricsInProgress,
        physicianAppointmentsDayWiseMetricsError,
    } = useSelector((state: StateParams) => state.coordinatorPatients);
    const [selectedPracticeId, setSelectedPracticeId] = useState<string | null>(null);
    const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>([]);

    const getPhysicianAppointmentsDayWiseMetrics = useCallback(() => {
        dispatch(fetchPhysicianAppointmentsDayWiseMetrics({
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
            practiceIds: selectedPracticeId,
            locationIds: (selectedLocationIds && selectedLocationIds.length > 0) ? selectedLocationIds.join(',') : null
        }));
    }, [selectedPracticeId, selectedLocationIds, dateFilter, dispatch]);

    useEffect(() => {
        getPhysicianAppointmentsDayWiseMetrics();
    }, [selectedPracticeId, selectedLocationIds, dateFilter, getPhysicianAppointmentsDayWiseMetrics]);

    const handleDateFilterChange = useCallback((startDate: string, endDate: string) => {
        console.log(startDate, endDate);
        setDateFilter({startDate, endDate});
    }, []);

    const renderFilters = useCallback(() => {
        return <div className="flex gap-5 lg:gap-2 flex-wrap">
            <Select
                placeholder={"Practice"}
                className="min-w-[250px] max-w-[250px]"
                classNamePrefix={"sj-react-select"}
                options={practiceMaster}
                getOptionLabel={option => `${option?.name}`}
                getOptionValue={option => option?.id}
                value={practiceMaster.find(x => x.id === selectedPracticeId) || null}
                isClearable={true}
                onChange={x => {
                    if (x) {
                        if (!x?.id || x?.id === selectedPracticeId) return;
                        setSelectedPracticeId(x?.id);
                        setSelectedLocationIds([]);
                        setLocations(x?.locations || []);
                    } else {
                        setSelectedPracticeId(null);
                        setSelectedLocationIds([]);
                        setLocations([]);
                    }
                }}
                defaultValue={undefined}
                isSearchable={practiceMaster.length > 5}
            />
            <Select
                placeholder={"Location"}
                isDisabled={!selectedPracticeId}
                className="min-w-[250px] max-w-[250px]"
                classNamePrefix={"sj-react-select"}
                getOptionLabel={option => `${option?.name}`}
                getOptionValue={option => option?.id}
                value={locations.filter(x => selectedLocationIds.includes(x.id))}
                options={locations}
                isClearable={true}
                isMulti={true}
                onChange={x => {
                    if (x?.length) {
                        setSelectedLocationIds(x.map((i) => i.id));
                    } else {
                        setSelectedLocationIds([]);
                    }
                }}
            />
            <DateRangePickerComponent
                placeholder="Appointment Date"
                startDate={dateFilter.startDate}
                endDate={dateFilter.endDate}
                onFilterChange={handleDateFilterChange}
                defaultStartDate={initialStartDate}
                defaultEndDate={initialEndDate}
                shouldSetDefaultDatesOnClearAndApply={true}
            />
        </div>
    }, [practiceMaster, selectedLocationIds, selectedPracticeId, dateFilter, handleDateFilterChange, locations, initialStartDate, initialEndDate]);

    const columns = useMemo(
        () => [
            {
                header: "Date",
                accessorKey: "date",
                cell: (props: any) => <span>{DateTime.fromISO(props?.getValue()).toFormat('MM-dd-yyyy')}</span>,
                footer: "Total"
            },
            {header: "Total Appointments", accessorKey: "totalAppointments", footer: `${physicianAppointmentsDayWiseMetrics?.reduce((acc, val) => acc + val?.totalAppointments, 0)}`},
            {header: "Called Patients", accessorKey: "calledAppointments", footer: `${physicianAppointmentsDayWiseMetrics?.reduce((acc, val) => acc + val?.calledAppointments, 0)}`},
            {header: "SMS Failed", accessorKey: "smsFailedAppointments", footer: `${physicianAppointmentsDayWiseMetrics?.reduce((acc, val) => acc + val?.smsFailedAppointments, 0)}`},
            {header: "SMS Delivered", accessorKey: "smsDeliveredAppointments", footer: `${physicianAppointmentsDayWiseMetrics?.reduce((acc, val) => acc + val?.smsDeliveredAppointments, 0)}`},
            {header: "Patients Refused To Fill", accessorKey: "patientRefusedToFillAppointments", footer: `${physicianAppointmentsDayWiseMetrics?.reduce((acc, val) => acc + val?.patientRefusedToFillAppointments, 0)}`},
            {header: "Screening Done", accessorKey: "screeningDoneAppointments", footer: `${physicianAppointmentsDayWiseMetrics?.reduce((acc, val) => acc + val?.screeningDoneAppointments, 0)}`},
            {
                header: "Screening Percentage", accessorKey: "screeningPercentage", cell: (props: any) => {
                    const screeningPercentage = props?.getValue();
                    return <span>{screeningPercentage % 1 === 0 ? screeningPercentage : parseFloat(screeningPercentage.toFixed(2))}%</span>
                }, footer: ()=> {
                    const totalScreeningDone = physicianAppointmentsDayWiseMetrics?.reduce((acc, val) => acc + val?.screeningDoneAppointments, 0);
                    const totalAppointments = physicianAppointmentsDayWiseMetrics?.reduce((acc, val) => acc + val?.totalAppointments, 0);
                    const totalScreeningPercentage = (totalScreeningDone && totalAppointments) && totalScreeningDone / totalAppointments * 100;
                    const totalScreeningPercentageFormatted = totalScreeningPercentage ? (totalScreeningPercentage % 1 === 0 ? totalScreeningPercentage : parseFloat(totalScreeningPercentage?.toFixed(2))) : 0;
                    return <>
                        {totalScreeningPercentageFormatted }%
                    </>
                }
            }
        ], [physicianAppointmentsDayWiseMetrics]);

    const renderMetrics = useCallback(() => {
        return <div className="w-full min-h-[350px] relative">
            {physicianAppointmentsDayWiseMetricsInProgress && <div className="card-loading"/>}
            {physicianAppointmentsDayWiseMetrics &&
                <TablePaginationV2 columns={columns} data={physicianAppointmentsDayWiseMetrics}
                                   hasFooter={true}
                                   searchInProgress={physicianAppointmentsDayWiseMetricsInProgress}/>}
        </div>
    }, [columns, physicianAppointmentsDayWiseMetrics, physicianAppointmentsDayWiseMetricsInProgress]);

    return (
        <div className="rounded-md bg-white p-5 border-[1px]">
            <div className="flex flex-col gap-1 lg:flex-row lg:justify-between align-items-center flex-wrap mb-2">
                <h1 className="text-lg"> Day Wise Metrics </h1>
                <div className="flex gap-5 lg:gap-1 flex-wrap align-items-flex-start">
                    {renderFilters()}
                    <RefreshComponent onRefresh={getPhysicianAppointmentsDayWiseMetrics}/>
                </div>
            </div>
            <div className="mt-5">
                {
                    physicianAppointmentsDayWiseMetricsError &&
                    <div className="flex gap-2 align-items-center text-sjDarkRed mt-2">
                        <BiSolidErrorCircle/> {physicianAppointmentsDayWiseMetricsError}
                    </div>
                }
                <div className="flex justify-between">
                    {renderMetrics()}
                </div>
            </div>
        </div>
    )
}

export default PhysicianAppointmentsDayWiseMetricsComponent;
