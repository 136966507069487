import React from "react";
import {toast} from 'react-toastify';
import {Button} from "./Buttons";
import {IoIosCopy} from "react-icons/io";

interface CopyButtonProps {
    content: string;
}

const CopyButton = (props: CopyButtonProps) => {

    const {content} = props;

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(content);
            toast.dismiss();
            toast(`Content copied to clipboard, Please paste it in the desired location.`, {
                type: 'success'
            });
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    return (
        <Button className={"border-sjOrange text-sjOrange"}
                onClick={copyToClipboard}
        >
            Copy Section&nbsp; <IoIosCopy/>
        </Button>
    );
}

export default CopyButton;
