import React, { useCallback, useState, useEffect } from "react";
import { Button } from "../generic/Buttons";
import TextAreaComponent from "../generic/TextAreaComponent";
import ConfirmationModal from "../clinical-notes/ConfirmationModal";

interface CommentBoxProps {
    value?: string;
    placeholder?: string;
    onSubmit: (comment: string) => void;
    comment: string;
    setComment: (comment: string) => void;
    onDiscard?: () => void;
    onChange?: (hasText: boolean) => void;
}

const CommentBox: React.FC<CommentBoxProps> = ({
    value = "",
    placeholder = "Type comment...",
    onSubmit,
    onDiscard,
    onChange,
    comment,
    setComment,
}) => {
    const [isDiscardModalOpen, setIsDiscardModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (onChange) {
            onChange(!!comment.trim());
        }
    }, [comment, onChange]);

    const handleSubmit = useCallback(() => {
        if (comment.trim()) {
            onSubmit(comment);
            setComment("");
        }
    }, [comment, onSubmit]);

    const handleDiscard = () => {
        setComment("");
        setIsDiscardModalOpen(false);
        onDiscard && onDiscard();
    };

    return (
        <div className="border border-gray-200 rounded-md p-4 my-4 w-full">
            <TextAreaComponent
                value={comment}
                onChange={(value) => setComment(value)}
                placeholder={placeholder}
            />

            <div className="flex justify-end gap-4 mt-4">
                <Button className="border-sjOrange text-sjOrange" onClick={() => setIsDiscardModalOpen(true)}>
                    Discard
                </Button>
                <Button
                    className={`!bg-sjOrange text-white ${!comment.trim() ? 'cursor-not-allowed opacity-50' : ''}`}
                    disabled={!comment.trim()}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </div>

            <ConfirmationModal
                isOpen={isDiscardModalOpen}
                onClose={() => setIsDiscardModalOpen(false)}
                key="discardCommentConfirmationModal"
                modalClassNames="!w-1/2"
                continueButtonName="Discard"
                actions={
                    <div className="flex justify-end gap-4">
                        <Button className="border-sjOrange text-sjOrange" onClick={() => setIsDiscardModalOpen(false)}>
                            Cancel
                        </Button>
                        <Button className="!bg-sjOrange text-white" onClick={handleDiscard}>
                            Discard
                        </Button>
                    </div>
                }
            >
                <p className="text-sm text-medium text-left">Discard Note?</p>
                <p className="text-sjGray text-sm font-base text-left pt-4">
                    Are you sure you want to leave this section without submitting your note? Any unsaved changes will be lost.
                </p>
            </ConfirmationModal>
        </div>
    );
};

export default CommentBox;
