import {ITherapistPatient} from "../../models";
import React, {useMemo} from "react";
import TablePaginationV2 from "../generic/TablePaginationV2";
import {PatientsDataView} from "./common/PatientsDataView";

interface PatientTableProps {
    dataView: PatientsDataView
    patients: ITherapistPatient[]
    searchInProgress: boolean | undefined
}

const PatientTable: React.FC<PatientTableProps> = ({dataView, patients, searchInProgress}) => {
    const columns = useMemo( dataView.getColumns(), [] );
    dataView.setPatients(patients)
    const data = dataView.getData()

    return <div>
        <TablePaginationV2 columns={columns} data={data} searchInProgress={searchInProgress} possibleColumnsToHide={{session: false}}/>
    </div>
}

export default PatientTable
