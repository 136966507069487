import React, {useEffect, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import DatePicker from 'react-datepicker';
import Select from "react-select";
import {useDispatch, useSelector} from 'react-redux';
import {StateParams} from '../../store/reducers';
import {DateTime} from 'luxon';
import TableFilterPaginationV2 from '../../components/generic/TableFilterPaginationV2';
import {fetchLocations} from '../../store/actions/physician/admin-operations.action';

interface SoapNoteFilterProps {
    onSubmit: (data: any) => void;
    setCurrentPage: Function
    setSearchText: Function
}

const SOAPNoteFilters: React.FC<SoapNoteFilterProps> = ({onSubmit, setCurrentPage, setSearchText}) => {

    const dispatch = useDispatch()
    const {control, watch} = useForm();
    const {locations, fetchLocationsInProgress} = useSelector((state: StateParams) => state.physicianAdminOperations)

    useEffect(() => {
        dispatch(fetchLocations({}));
    }, [dispatch]);

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (name && type === 'change') {
                if (value.startDate) {
                    value.startDate = DateTime.fromJSDate(value.startDate).startOf('day').toFormat('yyyy-MM-dd')
                }
                if (value.endDate) {
                    value.endDate = DateTime.fromJSDate(value.endDate).endOf('day').toFormat('yyyy-MM-dd')
                }


                onSubmit(value)
            }
        })
        return () => subscription.unsubscribe()
    }, [onSubmit, watch])

    return (
        <div className="flex justify-end items-center gap-x-2">
            <>
                <div className="custom-date-filter-wrapper">
                    <Controller
                        name="startDate"
                        control={control}
                        render={({field}) => (
                            <DatePicker
                                className={`border border-gray-300 rounded h-10  text-center`}
                                dateFormat="MM-dd-yyyy"
                                maxDate={watch('endDate') ? new Date(watch('endDate')) : new Date()}
                                todayButton="Today"
                                dropdownMode="select"
                                shouldCloseOnSelect
                                onChange={(date) => field.onChange(date)}
                                selected={field.value}
                                timeIntervals={10}
                                placeholderText="Start Date"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                isClearable
                            />
                        )}
                    />
                </div>
                To
                <div className="custom-date-filter-wrapper">
                    <Controller
                        name="endDate"
                        control={control}
                        render={({field}) => (
                            <DatePicker
                                className={`border border-gray-300 rounded h-10 text-center`}
                                dateFormat="MM-dd-yyyy"
                                minDate={watch('startDate') && new Date(watch('startDate'))}
                                maxDate={new Date()}
                                todayButton="Today"
                                dropdownMode="select"
                                shouldCloseOnSelect
                                onChange={(date) => field.onChange(date)}
                                selected={field.value}
                                timeIntervals={10}
                                placeholderText="End Date"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                isClearable
                            />
                        )}
                    />
                </div>
                {locations?.length > 1 && <div className="w-60">
                    <Controller
                        control={control}
                        name='locationId'
                        defaultValue=""
                        render={({field}) => (
                            <Select
                                className="border-gray-300 rounded"
                                options={locations}
                                value={locations && locations.find((x: any) => x.id === field.value)}
                                isLoading={fetchLocationsInProgress}
                                onChange={(x: any) => {
                                    if (x?.id) {
                                        field.onChange(x.id)
                                    } else {
                                        field.onChange('')
                                    }
                                }
                                }
                                getOptionLabel={option => option?.name}
                                getOptionValue={option => option?.id}
                                isSearchable={false}
                                isClearable={true}
                                placeholder={'Select Location'}

                            />
                        )}
                    />
                </div>}
            </>
            <TableFilterPaginationV2
                searchClassNames={'w-60'}
                placeholder={'Search by Patient Name'}
                setTableFilter={setSearchText}
                totalRows={0}
                currentPageHandler={setCurrentPage}
            />
        </div>
    )
}

export default SOAPNoteFilters;
