import { DateTime } from "luxon";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { IAppointmentWithTherapist, IEditAppointment, IProspect, PatientSessionStatus, ReferredPatientCallStatus, Roles, SessionTypes, patient_status, PatientSessionTypes, InsuranceStatusTypes, InsuranceStatus } from "../../models";
import { StateParams } from "../../store/reducers";
import EditablePhoneNumber from "../calls/EditablePhoneNumber";
import VoipPhoneNumber from "../calls/VoipPhoneNumber";
import Select from 'react-select'
import statusBucket from "../../assets/data/statusBucket";
import { fetchSourcePatientDetails, SEARCH_DISCHARGED_PATIENTS, updatePatientInfo, UPDATE_PATIENT_STATUS_FOR_DISCHARGE } from "../../store/actions/counsellor/patients.action";
import DischargePatient from "./DischargePatient";
import { hasPermission } from "../../store";
import { fetchSession } from "../../store/actions/counsellor/appointments.action";
import PatientInformation from "./PatientInformation";
import IntakeScheduledButton from "../clinical-notes/intake/IntakeScheduledButton";
import IntakeCancelModal from "../clinical-notes/intake/IntakeCancelModal";
import ReferredCallStatus from "../clinical-notes/intake/ReferredCallStatus";
import ScheduleAppointmentButton from "../clinical-notes/ScheduleAppointmentButton";
import ViewNotesButton from "../clinical-notes/ViewNotesButton";
import { RoutePaths } from "../../shared/Utils";
import InsuranceStatusAction from "./InsuranceStatusAction";
import { useFlags } from "flagsmith/react";
import { isCareCoordinator } from "../clinical-notes/accessControl";

const statusComponent = {
    [patient_status.discharge_from_cocm]: DischargePatient
}

const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        background: '#fff',
        borderColor: '#9e9e9e',
        minHeight: '30px',
        fontSize: '14px',
    }),

    valueContainer: (provided: any, state: any) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
    }),

    input: (provided: any, state: any) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    indicatorsContainer: (provided: any) => ({
        ...provided,
        height: '30px',
    })
}

const SHOW_DROPDOWN_FOR_STATUSES = [
    patient_status.discharge_from_cocm, patient_status.accepted_to_cocm
]

const DICHARGE_STATUSES = [
    patient_status.discharge_reason_patient_in_need_of_specialized_care,
    patient_status.discharge_reason_patient_got_better,
    patient_status.discharge_reason_patient_inactive,
    patient_status.discharge_reason_patient_ineligible
]

const CAN_VIEW_NOTES_ON_SESSION_STATUS = [PatientSessionStatus.inProgressUnderscored, PatientSessionStatus.inProgress, PatientSessionStatus.finished]
const CAN_START_SESSION_STATUSES = [PatientSessionStatus.scheduled, PatientSessionStatus.rescheduled]

const ProspectProfile: React.FC<{ prospect: IProspect, isReferredPatient?: boolean, showPatientInfo?: boolean }> = ({ prospect, isReferredPatient, showPatientInfo = true }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isPatientInsuranceEnabled = useFlags(['cc_patient_insurance'])?.cc_patient_insurance?.enabled;
    const { session } = useSelector((state: StateParams) => state.appointments)
    const sourcePatient = useSelector((state: StateParams) => state.patients.sourcePatient)
    const physicianAccount = useSelector((state: StateParams) => state.physicianAccount)
    const patient = prospect.therapistProspects && prospect.therapistProspects?.length > 0 && prospect.therapistProspects[0]
    const patientCreatedOn = patient && patient.createdAt && DateTime.fromISO(patient.createdAt.toString(), { zone: 'utc' }).toFormat('MM/dd/yyyy')
    const referredPatientCallStatus = prospect.patient && prospect.patient.referredCallStatus
    const patientInsuranceStatus = prospect.insuranceStatus
    const paths = useLocation().pathname.split('/')
    const options = Object.entries(statusBucket.discharge_patient).map(([key, value]) => ({ value: key, label: value }))
    const rejectedStatuses = Object.entries(statusBucket.rejected_patient).map(([key, value]) => ({ value: key, label: value }))
    const pathIncludesJourney = paths.includes('journey')
    const ccReferredPatients = paths.includes('cc-referred-patients')
    const pathIncludesDischargeSummary = paths.includes('discharge-summary')
    const [patientStatus, setPatientStatus] = React.useState<string | undefined>(prospect.status)
    const [selectedStatus, setSelectedStatus] = React.useState<ReferredPatientCallStatus | undefined>(referredPatientCallStatus)
    const [insuranceStatus, setInsuranceStatus] = React.useState<InsuranceStatus>(patientInsuranceStatus as InsuranceStatus)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const params = useParams()
    const paramsSourcePatientId = physicianAccount.id && pathIncludesJourney ? params.patientId : null
    const prospectWithSourcePatient = { ...prospect, phoneNumber: sourcePatient?.contactPhoneNumber || prospect?.patient?.phoneNumber || prospect?.phoneNumber }
    const role = useSelector((state: StateParams) => state.account.role)



    useLayoutEffect(() => {
        batch(() => {
            dispatch(fetchSession(prospect.id, SessionTypes.Intake))
            dispatch(fetchSourcePatientDetails(paramsSourcePatientId || prospect.sourcePatientId))
        })
    }, [])

    useEffect(() => {
        if (referredPatientCallStatus) {
            setSelectedStatus(referredPatientCallStatus)
        }
    }, [referredPatientCallStatus])

    useEffect(() => {
        if (patientInsuranceStatus) {
            setInsuranceStatus(patientInsuranceStatus as InsuranceStatus);
        }
    }, [patientInsuranceStatus])

    const handleStatusChange = (selected: any) => {
        setPatientStatus(selected.value)
    }

    const renderStatusOptions = (canDisable: boolean, options: { value: string; label: string | undefined; }[]) => {
        return (
            hasPermission(UPDATE_PATIENT_STATUS_FOR_DISCHARGE) && (pathIncludesJourney || pathIncludesDischargeSummary) &&
            prospect.status && SHOW_DROPDOWN_FOR_STATUSES.includes(prospect.status) &&
            <div className="ml-2">
                <Select isDisabled={canDisable} options={options} isSearchable={false} value={options.find(option => option.value === patientStatus)}
                    onChange={selected => handleStatusChange(selected)}
                    styles={customStyles} />
            </div>
        )
    }

    const renderStatus = () => {
        return prospect.status === patient_status.rejected_from_cocm ? renderStatusOptions(true, rejectedStatuses) : renderStatusOptions(pathIncludesDischargeSummary, options)
    }

    const renderStatusComponent = () => {
        if (patientStatus && statusComponent[patientStatus] && hasPermission(UPDATE_PATIENT_STATUS_FOR_DISCHARGE)) {
            const StatusComponent = statusComponent[patientStatus]
            return <StatusComponent prospect={prospect} patientStatus={patientStatus} setPatientStatus={setPatientStatus} />
        }
        return null
    }

    const calculateEnrolledToCoCMOn = () => {
        if (patientCreatedOn) {
            return patientCreatedOn + ' | ' + Math.ceil(
                DateTime.now().diff(DateTime.fromFormat(patientCreatedOn, 'MM/dd/yyyy'), 'days').days
            ) + ' Day(s)'
        }
        return ''
    }

    const calculateCocmDuration = () => {
        if (patientCreatedOn && prospect.dischargeDate && patient.createdAt) {
            const dischargedDate = DateTime.fromISO(prospect.dischargeDate, { zone: 'utc' })
            const enrolledToCoCM = DateTime.fromISO(patient.createdAt.toString())
            const duration = Math.ceil(dischargedDate.diff(enrolledToCoCM, 'days').days)
            return `${patientCreatedOn} - ${dischargedDate.toFormat('MM/dd/yyyy')} | ${duration > 0 ? duration : 0} Day(s)`
        }
    }

    const renderStatusContainer = () => {
        return (
            hasPermission(SEARCH_DISCHARGED_PATIENTS) && (pathIncludesJourney || pathIncludesDischargeSummary) &&
            <div className="w-auto h-1/4 bg-sjAnxiety border-sjLink rounded-full border">
                <span className="text-sjLink text-xs font-bold px-2 leading-4 tracking-wide">
                    {prospect.status && (DICHARGE_STATUSES.includes(prospect.status) || pathIncludesDischargeSummary) ? 'Discharge Patient' :
                        prospect.status === patient_status.accepted_to_cocm ? 'Active Patient' :
                            prospect.status === patient_status.rejected_from_cocm ? 'Exited CoCM' : ''
                    }
                </span>
            </div>
        )
    }

    const renderDurationContainer = () => {
        return (
            !isReferredPatient && prospect.status && !DICHARGE_STATUSES.includes(prospect.status) && patientCreatedOn ?
                <div className="max-w-max h-1/4 bg-sjAnxiety border-sjLink rounded-md border">
                    <span className="text-sjLink text-xs font-bold px-2 leading-4 tracking-wide">
                        Enrolled to CoCM on - {calculateEnrolledToCoCMOn()}</span>
                </div> : !isReferredPatient && (pathIncludesJourney || pathIncludesDischargeSummary) && patientCreatedOn && prospect.dischargeDate &&
                <div className="max-w-max h-1/4 bg-sjAnxiety border-sjLink rounded-md border">
                    <span className="text-sjLink text-xs font-bold px-2 leading-4 tracking-wide">
                        CoCM Duration {calculateCocmDuration()} </span>
                </div>
        )
    }

    const renderScheduleOrViewAppointment = () => {
        if (!isReferredPatient) {
            return null
        }
        if (!session) {
            return <ScheduleAppointmentButton prospect={prospect} insuranceStatus={insuranceStatus}
                url={showPatientInfo ? `${RoutePaths.careCoordinator.referredPatient.summary.appointments.replace(':prospectId', prospect.id)}` :
                    `${RoutePaths.careCoordinator.ccReferredPatients.patientDetails.appointments}?practiceId=${prospect.referredByPracticeId}&sourcePatientId=${prospect.sourcePatientId}`}
            />
        }
        if (session && session.status === 'no_show') {
            return null
        }
        if (session && CAN_VIEW_NOTES_ON_SESSION_STATUS.includes(session.status)) {
            return <ViewNotesButton prospect={prospect} />
        }
        return (
            session && CAN_START_SESSION_STATUSES.includes(session.status) ? <IntakeScheduledButton prospect={prospect} scheduledSession={session} setIsModalOpen={setIsModalOpen} /> :
                null
        )
    }

    return (
        <>
            <section id={"profile"} className={"w-full bg-sjWhite pl-8 py-4 rounded-lg"}>
                {renderStatusComponent()}
                <div id={"profile-name-phone"} className={"w-full flex flex-row items-center justify-between pr-20"}>
                    <div className={"flex flex-row items-center"}>
                        <div className={"w-auto flex flex-col justify-center"}>
                            <EditablePhoneNumber patient={prospectWithSourcePatient} />
                            <div className="pt-2.5 mb-2 flex gap-6 flex-row justify-start items-center">
                                {renderStatusContainer()}
                                <div>
                                    <ReferredCallStatus
                                        prospect={prospect}
                                        isReferredPatient={isReferredPatient}
                                        selectedStatus={selectedStatus}
                                        setSelectedStatus={setSelectedStatus}
                                    />
                                </div>

                                {(isCareCoordinator(role) && isPatientInsuranceEnabled && ccReferredPatients) &&
                                    <div>
                                        <label htmlFor="status" className="text-sjGray text-sm ml-1">Insurance Status</label>
                                        <div>
                                            <InsuranceStatusAction
                                                disabled={prospect?.insuranceAccounts?.length === 0}
                                                setInsuranceStatus={(status) => {
                                                    setInsuranceStatus(status)
                                                }}
                                                patient={
                                                    {
                                                        id: params.patientId || prospect.patient?.id || "",
                                                        insuranceStatus: insuranceStatus as InsuranceStatus,
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            {prospect.status !== patient_status.rejected_from_cocm &&
                                renderDurationContainer()
                            }
                        </div>
                    </div>
                    <div className="flex flex-row mt-10">
                        {hasPermission(UPDATE_PATIENT_STATUS_FOR_DISCHARGE) && (pathIncludesJourney && prospect.status === patient_status.accepted_to_cocm) && renderStatus()}
                        <VoipPhoneNumber patient={prospect} />
                        {renderScheduleOrViewAppointment()}
                    </div>
                </div>
                {showPatientInfo && <div className={`grid grid-flow-col auto-cols-max divide-x space-x-3 gap-x-3 items-stretch m-auto mt-6`}>
                    <PatientInformation prospect={prospect} />
                </div>}
            </section>
            <IntakeCancelModal isModalOpen={isModalOpen} prospect={prospect} setIsModalOpen={setIsModalOpen} />
        </>
    )
}

export default ProspectProfile
