import { AnyAction } from "redux";

import {
    IPatient,
    IPatientMaster,
    IPatientMasters,
    IPatientWithSummary,
    IPhysicianAppointmentCreateResponse,
    IPhysicianAppointmentEditForm,
    IPhysicianAppointmentForm,
    IPhysicianAppointmentListResponse,
    IPhysicianAppointmentUpdateResponse,
    IPotentialReferralPatient,
    IPotentialReferralPatientBasicDetails,
    IScreenerUserForScreeningLink,
    PatientMasterIndexRequest,
    TAllPatientsForScreenerLinkListRequest,
    TPhysicianAppointmentListRequest,
    IInsuranceEligibilityChecks,
    IInsuranceEligibilityCallStatusRequest,
    ReferredPatientsAdvancedFilters,
    IReferredPatientsDetails,
    IInsurancesResponse,
    IUserScreenerSession,
    IPhysicianAppointmentDeleteResponse,
    IPhysicianAppointmentDeleteForm,
    ICommentRequest,
    CommentsResponse,
    ICommentListResponse,
    IPhysicianAppointmentsOverAllMetricsPayload,
    IPhysicianAppointmentsOverAllMetricsResponse,
    IPhysicianAppointmentsOverAllMetrics,
    IPhysicianAppointmentsDayWiseMetricsPayload,
    IPhysicianAppointmentsDayWiseMetricsResponse, IPhysicianAppointmentsDayWiseMetrics,
    ScreeningSessionNotesForm,
    ScreeningSessionNotesResponse,
    IPhysicianAppointmentListItem
} from "../../../models";
import { Filters } from "../../../screens/prospects/care-coordinator/PotentialPatientsScreen";
import exp from "constants";

export const FETCH_POTENTIAL_REFERRAL_PATIENTS = "FETCH_POTENTIAL_REFERRAL_PATIENTS"
export const FETCH_POTENTIAL_REFERRAL_PATIENTS_IN_PROGRESS = "FETCH_POTENTIAL_REFERRAL_PATIENTS_IN_PROGRESS"
export const FETCH_POTENTIAL_REFERRAL_PATIENTS_SUCCESS = "FETCH_POTENTIAL_REFERRAL_PATIENTS_SUCCESS"
export const FETCH_POTENTIAL_REFERRAL_PATIENTS_FAILED = "FETCH_POTENTIAL_REFERRAL_PATIENTS_FAILED"
export const UPDATE_POTENTIAL_REFERRAL_PATIENT = "UPDATE_POTENTIAL_REFERRAL_PATIENT"
export const UPDATE_PATIENT_BASIC_INFO = "UPDATE_PATIENT_BASIC_INFO"

export const FETCH_PATIENT_LATEST_SCREENING_SUMMARY = "FETCH_PATIENT_LATEST_SCREENING_SUMMARY"
export const FETCH_PATIENT_LATEST_SCREENING_SUMMARY_SUCCESS = "FETCH_PATIENT_LATEST_SCREENING_SUMMARY_SUCCESS"
export const FETCH_PATIENT_LATEST_SCREENING_SUMMARY_FAILED = "FETCH_PATIENT_LATEST_SCREENING_SUMMARY_FAILED"
export const FETCH_PATIENT_LATEST_SCREENING_SUMMARY_SUCCESS_BY_SESSION_ID = "FETCH_PATIENT_LATEST_SCREENING_SUMMARY_SUCCESS_BY_SESSION_ID"

export const FETCH_PATIENT_BASIC_INFORMATION = "FETCH_PATIENT_BASIC_INFORMATION"

export const FETCH_PATIENT_BASIC_INFORMATION_SUCCESS = "FETCH_PATIENT_BASIC_INFORMATION_SUCCESS"
export const FETCH_PATIENT_BASIC_INFORMATION_FAILED = "FETCH_PATIENT_BASIC_INFORMATION_FAILED"

export const FETCH_PATIENT_MASTER_LIST = "FETCH_PATIENT_MASTER_LIST"
export const FETCH_PATIENT_MASTER_LIST_SUCCESS = "FETCH_PATIENT_MASTER_LIST_SUCCESS"
export const FETCH_PATIENT_MASTER_LIST_FAILED = "FETCH_PATIENT_MASTER_LIST_FAILED"

export const TRANSFER_INTAKE = "TRANSFER_INTAKE"
export const TRANSFER_INTAKE_SUCCESS = "TRANSFER_INTAKE_SUCCESS"
export const TRANSFER_INTAKE_FAILED = "TRANSFER_INTAKE_FAILED"
export const TRANSFER_INTAKE_INPROGRESS = "TRANSFER_INTAKE_INPROGRESS"

export const FETCH_ALL_PATIENTS_FOR_SCREENER_LINK = "FETCH_ALL_PATIENTS_FOR_SCREENER_LINK"
export const FETCH_ALL_PATIENTS_FOR_SCREENER_LINK_SUCCESS = "FETCH_ALL_PATIENTS_FOR_SCREENER_LINK_SUCCESS"
export const FETCH_ALL_PATIENTS_FOR_SCREENER_LINK_FAILED = "FETCH_ALL_PATIENTS_FOR_SCREENER_LINK_FAILED"
export const RESET_FETCH_ALL_PATIENTS_FOR_SCREENER_LINK = "RESET_FETCH_ALL_PATIENTS_FOR_SCREENER_LINK"

export const SEND_SCREENER_LINK_TO_PATIENT = "SEND_SCREENER_LINK_TO_PATIENT"
export const SEND_SCREENER_LINK_TO_PATIENT_SUCCESS = "SEND_SCREENER_LINK_TO_PATIENT_SUCCESS"
export const SEND_SCREENER_LINK_TO_PATIENT_FAILED = "SEND_SCREENER_LINK_TO_PATIENT_FAILED"
export const RESET_SCREENER_LINK_TO_PATIENT = "RESET_SCREENER_LINK_TO_PATIENT"

export const FETCH_INSURANCE_LIST = "FETCH_INSURANCE_LIST"
export const FETCH_INSURANCE_LIST_IN_PROGRESS = "FETCH_INSURANCE_LIST_IN_PROGRESS"
export const FETCH_INSURANCE_LIST_SUCCESS = "FETCH_INSURANCE_LIST_SUCCESS"
export const FETCH_INSURANCE_LIST_FAILED = "FETCH_INSURANCE_LIST_FAILED"

export const CHECK_INSURANCE_ELIGIBILITY = "CHECK_INSURANCE_ELIGIBILITY"
export const CHECK_INSURANCE_ELIGIBILITY_IN_PROGRESS = "CHECK_INSURANCE_ELIGIBILITY_IN_PROGRESS"
export const CHECK_INSURANCE_ELIGIBILITY_SUCCESS = "CHECK_INSURANCE_ELIGIBILITY_SUCCESS"
export const CHECK_INSURANCE_ELIGIBILITY_FAILED = "CHECK_INSURANCE_ELIGIBILITY_FAILED"
export const RESET_CHECKED_INSURANCE_ELIGIBILITY_SUCCESS = "RESET_CHECKED_INSURANCE_ELIGIBILITY_SUCCESS"

export const UPDATE_INSURANCE_STATUS = "UPDATE_INSURANCE_STATUS"
export const UPDATE_INSURANCE_STATUS_SUCCESS = "UPDATE_INSURANCE_STATUS_SUCCESS"
export const UPDATE_INSURANCE_STATUS_FAILED = "UPDATE_INSURANCE_STATUS_FAILED"
export const UPDATE_INSURANCE_STATUS_IN_PROGRESS = "UPDATE_INSURANCE_STATUS_IN_PROGRESS"
export const RESET_INSURANCE_STATUS = "RESET_INSURANCE_STATUS"

export const UPDATE_ELIGIBILITY_INSURANCE_STATUS = "UPDATE_ELIGIBILITY_INSURANCE_STATUS"
export const UPDATE_ELIGIBILITY_INSURANCE_STATUS_SUCCESS = "UPDATE_ELIGIBILITY_INSURANCE_STATUS_SUCCESS"
export const RESET_ELIGIBILITY_INSURANCE_STATUS_SUCCESS = "RESET_ELIGIBILITY_INSURANCE_STATUS_SUCCESS"
export const UPDATE_ELIGIBILITY_INSURANCE_STATUS_FAILED = "UPDATE_ELIGIBILITY_INSURANCE_STATUS_FAILED"
export const UPDATE_ELIGIBILITY_INSURANCE_STATUS_IN_PROGRESS = "UPDATE_ELIGIBILITY_INSURANCE_STATUS_IN_PROGRESS"

export const FETCH_REFERRED_PATIENTS = "FETCH_REFERRED_PATIENTS"
export const FETCH_REFERRED_PATIENTS_SUCCESS = "FETCH_REFERRED_PATIENTS_SUCCESS"
export const FETCH_REFERRED_PATIENTS_FAILED = "FETCH_REFERRED_PATIENTS_FAILED"
export const FETCH_REFERRED_IN_PROGRESS = "FETCH_REFERRED_IN_PROGRESS"

export const FETCH_PHYSICIAN_APPOINTMENTS = "FETCH_PHYSICIAN_APPOINTMENTS";
export const FETCH_PHYSICIAN_APPOINTMENTS_SUCCESS = "FETCH_PHYSICIAN_APPOINTMENTS_SUCCESS";
export const FETCH_PHYSICIAN_APPOINTMENTS_FAILED = "FETCH_PHYSICIAN_APPOINTMENTS_FAILED";

export const ADD_PHYSICIAN_APPOINTMENT = "ADD_PHYSICIAN_APPOINTMENT";
export const ADD_PHYSICIAN_APPOINTMENT_SUCCESS = "ADD_PHYSICIAN_APPOINTMENT_SUCCESS";
export const ADD_PHYSICIAN_APPOINTMENT_FAILED = "ADD_PHYSICIAN_APPOINTMENT_FAILED";
export const RESET_ADD_PHYSICIAN_APPOINTMENT = "RESET_ADD_PHYSICIAN_APPOINTMENT";

export const ADD_SCREENING_SESSION_NOTES = "ADD_SCREENING_SESSION_NOTES";
export const ADD_SCREENING_SESSION_NOTES_SUCCESS = "ADD_SCREENING_SESSION_NOTES_SUCCESS";
export const ADD_SCREENING_SESSION_NOTES_FAILED = "ADD_SCREENING_SESSION_NOTES_FAILED";
export const RESET_ADD_SCREENING_SESSION_NOTES = "RESET_ADD_SCREENING_SESSION_NOTES";
export const ADD_SCREENING_SESSION_NOTES_IN_PROGRESS = "ADD_SCREENING_SESSION_NOTES_IN_PROGRESS";

export const FETCH_SCREENING_SESSION_NOTES = "FETCH_SCREENING_SESSION_NOTES";
export const FETCH_SCREENING_SESSION_NOTES_SUCCESS = "FETCH_SCREENING_SESSION_NOTES_SUCCESS";
export const FETCH_SCREENING_SESSION_NOTES_FAILED = "FETCH_SCREENING_SESSION_NOTES_FAILED";
export const FETCH_SCREENING_SESSION_NOTES_IN_PROGRESS = "FETCH_SCREENING_SESSION_NOTES_IN_PROGRESS";

export const UPDATE_PHYSICIAN_APPOINTMENT = "UPDATE_PHYSICIAN_APPOINTMENT";
export const UPDATE_PHYSICIAN_APPOINTMENT_SUCCESS = "UPDATE_PHYSICIAN_APPOINTMENT_SUCCESS";
export const UPDATE_PHYSICIAN_APPOINTMENT_FAILED = "UPDATE_PHYSICIAN_APPOINTMENT_FAILED";
export const RESET_UPDATE_PHYSICIAN_APPOINTMENT = "RESET_UPDATE_PHYSICIAN_APPOINTMENT";

export const DELETE_PHYSICIAN_APPOINTMENT = "DELETE_PHYSICIAN_APPOINTMENT";
export const DELETE_PHYSICIAN_APPOINTMENT_SUCCESS = "DELETE_PHYSICIAN_APPOINTMENT_SUCCESS";
export const DELETE_PHYSICIAN_APPOINTMENT_FAILED = "DELETE_PHYSICIAN_APPOINTMENT_FAILED";
export const RESET_DELETE_PHYSICIAN_APPOINTMENT = "RESET_DELETE_PHYSICIAN_APPOINTMENT";

export const SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT = "SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT";
export const SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT_SUCCESS = "SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT_SUCCESS";
export const SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT_FAILED = "SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT_FAILED";
export const RESET_SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT = "RESET_SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT";

export const FETCH_PATIENT_ALL_SCREENING_SESSIONS = "FETCH_PATIENT_ALL_SCREENING_SESSIONS";
export const FETCH_PATIENT_ALL_SCREENING_SESSIONS_SUCCESS = "FETCH_PATIENT_ALL_SCREENING_SESSIONS_SUCCESS";
export const FETCH_PATIENT_ALL_SCREENING_SESSIONS_FAILED = "FETCH_PATIENT_ALL_SCREENING_SESSIONS_FAILED";

export const FETCH_PHYSICIAN_DND_APPOINTMENTS = "FETCH_PHYSICIAN_DND_APPOINTMENTS";
export const FETCH_PHYSICIAN_DND_APPOINTMENTS_SUCCESS = "FETCH_PHYSICIAN_DND_APPOINTMENTS_SUCCESS";
export const FETCH_PHYSICIAN_DND_APPOINTMENTS_FAILED = "FETCH_PHYSICIAN_DND_APPOINTMENTS_FAILED";
export const FETCH_PHYSICIAN_DND_APPOINTMENTS_IN_PROGRESS = "FETCH_PHYSICIAN_DND_APPOINTMENTS_IN_PROGRESS";
export const RESET_FETCH_PHYSICIAN_DND_APPOINTMENTS = "RESET_FETCH_PHYSICIAN_DND_APPOINTMENTS";

export const ADD_COMMENT_TO_PATIENT = "ADD_COMMENT_TO_PATIENT";
export const ADD_COMMENT_TO_PATIENT_SUCCESS = "ADD_COMMENT_TO_PATIENT_SUCCESS";
export const ADD_COMMENT_TO_PATIENT_FAILED = "ADD_COMMENT_TO_PATIENT_FAILED";
export const RESET_COMMENT_TO_PATIENT = "RESET_COMMENT_TO_PATIENT";

export const FETCH_PATIENT_COMMENTS = "FETCH_PATIENT_COMMENTS";
export const FETCH_PATIENT_COMMENTS_SUCCESS = "FETCH_PATIENT_COMMENTS_SUCCESS";
export const FETCH_PATIENT_COMMENTS_FAILED = "FETCH_PATIENT_COMMENTS_FAILED";

export const PATIENT_COMMENTS_SIDEBAR_ACTIVE = 'PATIENT_COMMENTS_SIDEBAR_ACTIVE'
export const PATIENT_COMMENTS_SIDEBAR_IN_ACTIVE = 'PATIENT_COMMENTS_SIDEBAR_IN_ACTIVE'

export const FETCH_PHYSICIAN_APPOINTMENTS_OVER_All_METRICS = "FETCH_PHYSICIAN_APPOINTMENTS_OVER_All_METRICS";

export const FETCH_PHYSICIAN_APPOINTMENTS_OVER_All_METRICS_SUCCESS = "FETCH_PHYSICIAN_APPOINTMENTS_OVER_All_METRICS_SUCCESS";

export const FETCH_PHYSICIAN_APPOINTMENTS_OVER_All_METRICS_FAILED = "FETCH_PHYSICIAN_APPOINTMENTS_OVER_All_METRICS_FAILED";

export const FETCH_PHYSICIAN_APPOINTMENTS_DAY_WISE_METRICS = "FETCH_PHYSICIAN_APPOINTMENTS_DAY_WISE_METRICS";

export const FETCH_PHYSICIAN_APPOINTMENTS_DAY_WISE_METRICS_SUCCESS = "FETCH_PHYSICIAN_APPOINTMENTS_DAY_WISE_METRICS_SUCCESS";

export const FETCH_PHYSICIAN_APPOINTMENTS_DAY_WISE_METRICS_FAILED = "FETCH_PHYSICIAN_APPOINTMENTS_DAY_WISE_METRICS_FAILED";

export const SET_SCREENING_SESSION_ID = "SET_SCREENING_SESSION_ID";

export const fetchPotentialReferralPatientsInProgress = (): AnyAction => {
    return { type: FETCH_POTENTIAL_REFERRAL_PATIENTS_IN_PROGRESS }
}
export const fetchPotentialReferralPatients = (currentPage: number, recordsPerPage: number, searchText: string, filters?: { status: string[] | undefined }, advancedFilters?: Filters): AnyAction => {
    const searchFilters = filters && filters.status ? filters : undefined
    return { type: FETCH_POTENTIAL_REFERRAL_PATIENTS, payload: { currentPage, recordsPerPage, searchText, filters: searchFilters, advancedFilters } }
}
export const fetchingPotentialReferralPatientsFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_POTENTIAL_REFERRAL_PATIENTS_FAILED, payload }
}
export const fetchedPotentialReferralPatients = (patients: IPotentialReferralPatient[], count: number, statusCount: Record<string, number>): AnyAction => {
    return { type: FETCH_POTENTIAL_REFERRAL_PATIENTS_SUCCESS, payload: { patients, count, statusCount } }
}

export const fetchingPatientLatestScreeningSummaryFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_PATIENT_LATEST_SCREENING_SUMMARY_FAILED, payload }
}
export const fetchedPatientLatestScreeningSummary = (screeningSummary: IPatientWithSummary): AnyAction => {
    return { type: FETCH_PATIENT_LATEST_SCREENING_SUMMARY_SUCCESS, payload: { screeningSummary } }
}
export const fetchedPatientLatestScreeningSummaryBySessionId = (screeningSummary: IPatientWithSummary): AnyAction => {
    return { type: FETCH_PATIENT_LATEST_SCREENING_SUMMARY_SUCCESS_BY_SESSION_ID, payload: { screeningSummary } }
}
export const fetchPatientLatestScreeningSummary = (patientId: string, practiceId: string, sessionId?: string): AnyAction => {
    return { type: FETCH_PATIENT_LATEST_SCREENING_SUMMARY, payload: { patientId, practiceId, sessionId } }
}

export const updatePotentialRefferalPatient = (patient: IPotentialReferralPatient): AnyAction => {
    return { type: UPDATE_POTENTIAL_REFERRAL_PATIENT, payload: { patient } }
}

export const updatePatientBasicInfo = (contactPhoneNumber: string | null | undefined): AnyAction => {
    return { type: UPDATE_PATIENT_BASIC_INFO, payload: { contactPhoneNumber } }
}

export const fetchingPatientBasicInformationFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_PATIENT_BASIC_INFORMATION_FAILED, payload }
}

export const fetchedPatientBasicInformation = (patient: IPotentialReferralPatientBasicDetails): AnyAction => {
    return { type: FETCH_PATIENT_BASIC_INFORMATION_SUCCESS, payload: { patient } }
}

export const fetchPatientBasicInformation = (patientId: string): AnyAction => {
    return { type: FETCH_PATIENT_BASIC_INFORMATION, payload: { patientId } }
}

export const fetchingPatientMasterFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_PATIENT_MASTER_LIST_FAILED, payload }
}

export const fetchedPatientMasterList = (patients: IPatientMaster[], count: number): AnyAction => {
    return { type: FETCH_PATIENT_MASTER_LIST_SUCCESS, payload: { patients, count } }
}

export const fetchPatientMasterList = (request: PatientMasterIndexRequest): AnyAction => {
    return { type: FETCH_PATIENT_MASTER_LIST, payload: { request } }
}
export const transferIntake = (sourcePatientId: string, therapistId: string | null, patientStatus: string): AnyAction => {
    return { type: TRANSFER_INTAKE, payload: { sourcePatientId, therapistId, patientStatus } }
}
export const transferingIntakeFailed = (payload: { error: string }): AnyAction => {
    return { type: TRANSFER_INTAKE_FAILED, payload }
}
export const transferedIntake = (noteId: string): AnyAction => {
    return { type: TRANSFER_INTAKE_SUCCESS, payload: { noteId } }
}

export const transferingIntakeInProgress = (): AnyAction => {
    return { type: TRANSFER_INTAKE_INPROGRESS }
}

export const fetchAllPatientsForScreenerLink = (requestPayload: TAllPatientsForScreenerLinkListRequest): AnyAction => {
    return { type: FETCH_ALL_PATIENTS_FOR_SCREENER_LINK, payload: requestPayload }
}

export const fetchingAllPatientsForScreenerLinkFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_ALL_PATIENTS_FOR_SCREENER_LINK_FAILED, payload }
}

export const fetchedAllPatientsForScreenerLink = (patients: IScreenerUserForScreeningLink[]): AnyAction => {
    return { type: FETCH_ALL_PATIENTS_FOR_SCREENER_LINK_SUCCESS, payload: { patients } }
}

export const resetFetchedAllPatientsForScreenerLink = (): AnyAction => {
    return { type: RESET_FETCH_ALL_PATIENTS_FOR_SCREENER_LINK }
}

export const sendScreenerLinkToPatient = (patientId: string, practiceId: string): AnyAction => {
    return { type: SEND_SCREENER_LINK_TO_PATIENT, payload: { patientId, practiceId } }
}

export const sendingScreenerLinkToPatientFailed = (payload: { error: string }): AnyAction => {
    return { type: SEND_SCREENER_LINK_TO_PATIENT_FAILED, payload }
}

export const sentScreenerLinkToPatient = (): AnyAction => {
    return { type: SEND_SCREENER_LINK_TO_PATIENT_SUCCESS }
}

export const resetScreenerLinkToPatient = (): AnyAction => {
    return { type: RESET_SCREENER_LINK_TO_PATIENT }
}

export const fetchInsuranceList = (patientId: string): AnyAction => {
    return { type: FETCH_INSURANCE_LIST, payload: { patientId } }
}
export const fetchInsuranceListInProgress = (): AnyAction => {
    return { type: FETCH_INSURANCE_LIST_IN_PROGRESS }
}
export const fetchInsuranceListFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_INSURANCE_LIST_FAILED, payload }
}
export const fetchInsuranceListSuccess = (insurances: IInsurancesResponse[]): AnyAction => {
    return { type: FETCH_INSURANCE_LIST_SUCCESS, payload: insurances }
}

export const checkInsuranceEligibility = (insuranceId: string, eligibility: IInsuranceEligibilityChecks): AnyAction => {
    return { type: CHECK_INSURANCE_ELIGIBILITY, payload: { insuranceId, eligibility } }
}

export const checkingInsuranceEligibilityFailed = (payload: { error: string }): AnyAction => {
    return { type: CHECK_INSURANCE_ELIGIBILITY_FAILED, payload }
}

export const checkedInsuranceEligibility = (eligibleId: string): AnyAction => {
    return { type: CHECK_INSURANCE_ELIGIBILITY_SUCCESS, payload: { eligibleId } }
}

export const checkingInsuranceEligibilityInProgress = (): AnyAction => {
    return { type: CHECK_INSURANCE_ELIGIBILITY_IN_PROGRESS }
}
export const resetCheckedInsuranceEligibility = (): AnyAction => {
    return { type: RESET_CHECKED_INSURANCE_ELIGIBILITY_SUCCESS }
}

export const updateEligibilityInsuranceStatus = (eligibilityId: string, status: IInsuranceEligibilityCallStatusRequest): AnyAction => {
    return { type: UPDATE_ELIGIBILITY_INSURANCE_STATUS, payload: { eligibilityId, status } }
}

export const updatingEligibilityInsuranceStatusFailed = (payload: { error: string }): AnyAction => {
    return { type: UPDATE_ELIGIBILITY_INSURANCE_STATUS_FAILED, payload }
}

export const updatedEligibilityInsuranceStatus = (eligibilityId: string): AnyAction => {
    return { type: UPDATE_ELIGIBILITY_INSURANCE_STATUS_SUCCESS, payload: { eligibilityId } }
}

export const resetEligibilityInsuranceStatus = (): AnyAction => {
    return { type: RESET_ELIGIBILITY_INSURANCE_STATUS_SUCCESS }
}

export const updatedEligibilityInsuranceStatusInProgress = (): AnyAction => {
    return {
        type: UPDATE_ELIGIBILITY_INSURANCE_STATUS_IN_PROGRESS

    }
}

export const updateInsuranceStatus = (patientId: string, status: string): AnyAction => {
    return { type: UPDATE_INSURANCE_STATUS, payload: { patientId, status } }
}

export const updateInsuranceStatusFailed = (payload: { error: string }): AnyAction => {
    return { type: UPDATE_INSURANCE_STATUS_FAILED, payload }
}

export const updatedInsuranceStatus = (patientId: string, insuranceStatus: string): AnyAction => {
    return { type: UPDATE_INSURANCE_STATUS_SUCCESS, payload: { patientId, insuranceStatus } }
}

export const updateInsuranceStatusInProgress = (): AnyAction => {
    return { type: UPDATE_INSURANCE_STATUS_IN_PROGRESS }
}

export const resetInsuranceStatus = (): AnyAction => {
    return { type: RESET_INSURANCE_STATUS }
}

export const fetchReferredPatients = (currentPage: number, recordsPerPage: number, activeTab: string, filters?: ReferredPatientsAdvancedFilters, searchText?: string): AnyAction => {
    return { type: FETCH_REFERRED_PATIENTS, payload: { currentPage, recordsPerPage, filters, searchText, activeTab } }
}

export const fetchingReferredPatientsFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_REFERRED_PATIENTS_FAILED, payload }
}

export const fetchedReferredPatients = (prospects: IReferredPatientsDetails[], prospectsCount: number, tabCount: Record<string, number>): AnyAction => {
    console.log('fetchedReferredPatients', prospects, prospectsCount, tabCount)
    return { type: FETCH_REFERRED_PATIENTS_SUCCESS, payload: { prospects, prospectsCount, tabCount } }
  }

export const fetchPhysicianAppointments = (requestPayload: TPhysicianAppointmentListRequest): AnyAction => {
    return { type: FETCH_PHYSICIAN_APPOINTMENTS, payload: requestPayload }
}

export const fetchingPhysicianAppointmentsFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_PHYSICIAN_APPOINTMENTS_FAILED, payload }
}

export const fetchedPhysicianAppointments = (response: IPhysicianAppointmentListResponse): AnyAction => {
    return { type: FETCH_PHYSICIAN_APPOINTMENTS_SUCCESS, payload: response }
}

export const addPhysicianAppointment = (requestPayload: IPhysicianAppointmentForm): AnyAction => {
    return { type: ADD_PHYSICIAN_APPOINTMENT, payload: requestPayload }
}

export const addingPhysicianAppointmentFailed = (payload: { error: string }): AnyAction => {
    return { type: ADD_PHYSICIAN_APPOINTMENT_FAILED, payload }
}

export const addedPhysicianAppointment = (payload: IPhysicianAppointmentCreateResponse): AnyAction => {
    return { type: ADD_PHYSICIAN_APPOINTMENT_SUCCESS, payload }
}

export const resetAddPhysicianAppointment = (): AnyAction => {
    return { type: RESET_ADD_PHYSICIAN_APPOINTMENT }
}

export const updatePhysicianAppointment = (appointmentId: string, requestPayload: IPhysicianAppointmentEditForm): AnyAction => {
    return { type: UPDATE_PHYSICIAN_APPOINTMENT, payload: {appointmentId, requestPayload} }
}

export const updatingPhysicianAppointmentFailed = (payload: { error: string }): AnyAction => {
    return { type: UPDATE_PHYSICIAN_APPOINTMENT_FAILED, payload }
}

export const updatedPhysicianAppointment = (payload: IPhysicianAppointmentUpdateResponse): AnyAction => {
    return { type: UPDATE_PHYSICIAN_APPOINTMENT_SUCCESS, payload }
}

export const resetUpdatePhysicianAppointment = (): AnyAction => {
    return { type: RESET_UPDATE_PHYSICIAN_APPOINTMENT }
}

export const deletePhysicianAppointment = (appointmentId: string, requestPayload: IPhysicianAppointmentDeleteForm): AnyAction => {
    return { type: DELETE_PHYSICIAN_APPOINTMENT, payload: {appointmentId, requestPayload} }
}

export const deletingPhysicianAppointmentFailed = (payload: { error: string }): AnyAction => {
    return { type: DELETE_PHYSICIAN_APPOINTMENT_FAILED, payload }
}

export const deletedPhysicianAppointment = (payload: IPhysicianAppointmentDeleteResponse): AnyAction => {
    return { type: DELETE_PHYSICIAN_APPOINTMENT_SUCCESS, payload }
}

export const resetDeletePhysicianAppointment = (): AnyAction => {
    return { type: RESET_DELETE_PHYSICIAN_APPOINTMENT }
}

export const sendPhysicianAppointmentReminderToPatient = (appointmentId: string): AnyAction => {
    return { type: SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT, payload: {appointmentId} }
}

export const sendingPhysicianAppointmentReminderToPatientFailed = (payload: { error: string }): AnyAction => {
    return { type: SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT_FAILED, payload }
}

export const sentPhysicianAppointmentReminderToPatient = (): AnyAction => {
    return { type: SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT_SUCCESS }
}

export const resetSendPhysicianAppointmentReminderToPatient = (): AnyAction => {
    return { type: RESET_SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT }
}

export const fetchPatientAllScreeningSessions = (patientId: string, practiceId: string): AnyAction => {
    return { type: FETCH_PATIENT_ALL_SCREENING_SESSIONS, payload: { patientId, practiceId } }
}

export const fetchingPatientAllScreeningSessionsFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_PATIENT_ALL_SCREENING_SESSIONS_FAILED, payload }
}

export const fetchedPatientAllScreeningSessions = (sessions: IUserScreenerSession[]): AnyAction => {
    return { type: FETCH_PATIENT_ALL_SCREENING_SESSIONS_SUCCESS, payload: { sessions } }
}

export const addCommentToPatient = (patientId: string, commentreq: ICommentRequest): AnyAction => {
    return { type: ADD_COMMENT_TO_PATIENT, payload: { patientId, commentreq } }
}

export const addingCommentToPatientFailed = (payload: { error: string }): AnyAction => {
    return { type: ADD_COMMENT_TO_PATIENT_FAILED, payload }
}

export const resetCommentToPatient = (): AnyAction => {
    return { type: RESET_COMMENT_TO_PATIENT }
}

export const addedCommentToPatient = (payload: CommentsResponse): AnyAction => {
    return { type: ADD_COMMENT_TO_PATIENT_SUCCESS, payload }
}

export const fetchPatientComments = (patientId: string, currentPage: number,
    recordsPerPage: number): AnyAction => {
    return { type: FETCH_PATIENT_COMMENTS, payload: { patientId, currentPage, recordsPerPage } }
}

export const fetchingPatientCommentsFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_PATIENT_COMMENTS_FAILED, payload }
}

export const fetchedPatientComments = (comments: ICommentListResponse): AnyAction => {
    return { type: FETCH_PATIENT_COMMENTS_SUCCESS, payload: { comments } }
}

export const patientCommentsSidebarActive = (): AnyAction => {
    return { type: PATIENT_COMMENTS_SIDEBAR_ACTIVE }
}

export const patientCommentsSidebarInActive = (): AnyAction => {
    return { type: PATIENT_COMMENTS_SIDEBAR_IN_ACTIVE }
}

export const fetchPhysicianAppointmentsOverAllMetrics = (requestPayload: IPhysicianAppointmentsOverAllMetricsPayload): AnyAction => {
    return { type: FETCH_PHYSICIAN_APPOINTMENTS_OVER_All_METRICS, payload: requestPayload }
}

export const fetchingPhysicianAppointmentsOverAllMetricsFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_PHYSICIAN_APPOINTMENTS_OVER_All_METRICS_FAILED, payload }
}

export const fetchedPhysicianAppointmentsOverAllMetrics = (response: IPhysicianAppointmentsOverAllMetrics): AnyAction => {
    return { type: FETCH_PHYSICIAN_APPOINTMENTS_OVER_All_METRICS_SUCCESS, payload: { response } }
}

export const fetchPhysicianAppointmentsDayWiseMetrics = (requestPayload: IPhysicianAppointmentsDayWiseMetricsPayload): AnyAction => {
    return { type: FETCH_PHYSICIAN_APPOINTMENTS_DAY_WISE_METRICS, payload: requestPayload }
}

export const fetchingPhysicianAppointmentsDayWiseMetricsFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_PHYSICIAN_APPOINTMENTS_DAY_WISE_METRICS_FAILED, payload }
}

export const fetchedPhysicianAppointmentsDayWiseMetrics = (response: IPhysicianAppointmentsDayWiseMetrics): AnyAction => {
    return { type: FETCH_PHYSICIAN_APPOINTMENTS_DAY_WISE_METRICS_SUCCESS, payload: { response } }
}

export const addScreeningSessionNotes = (requestPayload: ScreeningSessionNotesForm): AnyAction => {
    return { type: ADD_SCREENING_SESSION_NOTES, payload: requestPayload }
}
export const addingScreeningSessionNotes = (): AnyAction => {
    return { type: ADD_SCREENING_SESSION_NOTES_IN_PROGRESS }
}
export const addScreeningSessionNotesFailed = (payload: { error: string }): AnyAction => {
    return { type: ADD_SCREENING_SESSION_NOTES_FAILED, payload }
}
export const addedScreeningSessionNotes = (notes: ScreeningSessionNotesResponse): AnyAction => {
    return { type: ADD_SCREENING_SESSION_NOTES_SUCCESS, payload: { notes } }
}
export const resetAddScreeningSessionNotes = (): AnyAction => {
    return { type: RESET_ADD_SCREENING_SESSION_NOTES }
}

export const fetchScreeningSessionNotes = (patientId: string, screeningSessionId: string): AnyAction => {
    return { type: FETCH_SCREENING_SESSION_NOTES, payload: { patientId, screeningSessionId } }
}
export const fetchingScreeningSessionNotes = (payload: { error: string }): AnyAction => {
    return { type: FETCH_SCREENING_SESSION_NOTES_FAILED, payload }
}
export const fetchedScreeningSessionNotes = (notes: ScreeningSessionNotesResponse): AnyAction => {
    return { type: FETCH_SCREENING_SESSION_NOTES_SUCCESS, payload: { notes } }
}

export const setScreeningSessionId = (sessionId: string): AnyAction => {
    return { type: SET_SCREENING_SESSION_ID, payload: { sessionId } }
}

export const fetchPhysicianDndAppointments = (patientId: string, handleCloseFn: () => void): AnyAction => {
    return { type: FETCH_PHYSICIAN_DND_APPOINTMENTS, payload: { patientId, handleCloseFn } }
}
export const fetchingPhysicianDndAppointmentsFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_PHYSICIAN_DND_APPOINTMENTS_FAILED, payload }
}
export const fetchedPhysicianDndAppointments = (appointment: IPhysicianAppointmentListItem): AnyAction => {
    return { type: FETCH_PHYSICIAN_DND_APPOINTMENTS_SUCCESS, payload: { appointment } }
}
export const fetchingPhysicianDndAppointmentsInProgress = (): AnyAction => {
    return { type: FETCH_PHYSICIAN_DND_APPOINTMENTS_IN_PROGRESS }
}
export const resetFetchPhysicianDndAppointments = (): AnyAction => {
    return { type: RESET_FETCH_PHYSICIAN_DND_APPOINTMENTS }
}